import { gql } from "@apollo/client";

export const getContactAttributes = gql`
    query getContactAttributes {
        getContactAttributes {
            id
            displayName
            attributeKey
            dataType
            inputType
            options
            editable
            createdBy
            createdAt
            createdBy
            updatedAt
            updatedBy
            attributeIndex
            teams {
                id
                name
            }
        }
    }
`;
