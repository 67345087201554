import { useCallback } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";

import { useFlags } from "Services/FeatureFlagService";
import { renderErrorMessage } from "Services/LoggingService";

import {
    createTransferPhoneNumber as createTransferPhoneNumberGQL,
    updateTransferPhoneNumber as updateTransferPhoneNumberGQL,
    removeTransferPhoneNumber as removeTransferPhoneNumberGQL,
} from "../mutations";
import { getTransferPhoneNumbers, getTransferPhoneNumbersForTeams } from "../queries";

function stripNumber(number: string): string | undefined {
    const match = number.replace(/[^\d]/g, "").match(/1?(\d{10})/);
    return match ? match[1] : undefined;
}

const refetchQueries = ["GetTransferPhoneNumbers"];

export const useExternalTransferPhoneNumbers = ({ isManagerContext = true } = {}) => {
    const { hasExternalTransferPhonebook } = useFlags();
    const [createTransferPhoneNumberMutation, { loading: isCreating }] = useMutation(createTransferPhoneNumberGQL, {
        refetchQueries,
    });
    const [updateTransferPhoneNumberMutation, { loading: isUpdating }] = useMutation(updateTransferPhoneNumberGQL, {
        refetchQueries,
    });
    const [removeTransferPhoneMutation, { loading: isDeleting }] = useMutation(removeTransferPhoneNumberGQL, {
        refetchQueries,
    });

    const query = isManagerContext ? getTransferPhoneNumbers : getTransferPhoneNumbersForTeams;

    const { data, loading: isFetching } = useQuery(query, {
        fetchPolicy: "network-only",
        skip: !hasExternalTransferPhonebook,
    });

    const createTransferPhoneNumber = useCallback(
        async (formData: any) => {
            try {
                const { name, phoneNumber, teamIds } = formData;

                const createData = {
                    name,
                    phoneNumber: `+1${stripNumber(phoneNumber)}`,
                    shouldLoadTeams: true,
                    teamIds,
                };

                const { data } = await createTransferPhoneNumberMutation({
                    variables: createData,
                });
                message.success("External transfer phone number successfully created!");
                return data?.createTransferPhoneNumber;
            } catch (error: any) {
                renderErrorMessage({
                    content: `Error creating external ${formData?.name} - ${error.message || error}.`,
                    error,
                });
            }
        },
        [createTransferPhoneNumberMutation]
    );

    const updateTransferPhoneNumber = useCallback(
        async (id: string, formData: any) => {
            try {
                const { name, phoneNumber, teamIds } = formData;
                const phoneNumberWithPrefix = `+1${stripNumber(phoneNumber)}`;

                const updatedData = {
                    id: id,
                    name,
                    phoneNumber: phoneNumberWithPrefix,
                    shouldLoadTeams: true,
                    teamIds,
                };

                const { data } = await updateTransferPhoneNumberMutation({
                    variables: updatedData,
                });
                message.success("External transfer phone number successfully updated!");
                return data?.updateTransferPhoneNumber;
            } catch (error: any) {
                renderErrorMessage({
                    content: `Error updating external ${formData?.name} - ${error.message || error}.`,
                    error,
                });
            }
        },
        [updateTransferPhoneNumberMutation]
    );

    const deleteTransferPhoneNumber = useCallback(
        async (id: string) => {
            await removeTransferPhoneMutation({ variables: { id } });
            message.success(`Successfully deleted the external transfer phone number.`);
        },
        [removeTransferPhoneMutation]
    );

    return {
        phoneNumbersData: data?.getTransferPhoneNumbers || [],
        createTransferPhoneNumber,
        updateTransferPhoneNumber,
        deleteTransferPhoneNumber,
        removeTransferPhone: removeTransferPhoneMutation,
        isFetching,
        isLoading: isCreating || isUpdating || isDeleting,
    };
};
