import { useMemo, useContext } from "react";

import { UserContext } from "App/contexts";
import { formatPhoneNumber } from "Services/CommunicationService";

import type { RVTask } from "@regal-voice/shared-types";
import type { CommunicationSource } from "Types/CommunicationSource";

export type UseLineInfoOptions = {
    task?: RVTask;
};

export type UseLineInfoResult = {
    source?: CommunicationSource;
    number: string;
};

export const useLineInfo = ({ task }: UseLineInfoOptions = {}): UseLineInfoResult | undefined => {
    const { brand } = useContext(UserContext);
    return useMemo(() => {
        if (!task?.attributes) {
            return;
        }

        return {
            source: brand?.communicationSources?.find(({ source }) => source == task.attributes.regalVoicePhone),
            number: formatPhoneNumber(task.attributes.regalVoicePhone),
        };
    }, [brand, task]);
};
