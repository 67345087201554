import { createSelector } from "@reduxjs/toolkit";

import { selectActiveTask } from "Services/state/tasks/Selectors";

import { RootReducerShape } from "../Storage";
import { ConversationState as RootConversationsState } from "./IndexProvider";

export const selectRootConversationsState = (state: RootReducerShape): RootConversationsState => state.conversations;
export const selectActiveConversationsState = createSelector(
    selectRootConversationsState,
    (conversationsState) => conversationsState.activeConversations
);
export const selectUnreadConversationsState = createSelector(
    selectRootConversationsState,
    (conversationsState) => conversationsState.unreadConversations
);

/**
 * Active Conversations
 */
export const selectEntries = createSelector(
    selectActiveConversationsState,
    (conversationsState) => conversationsState.entries
);

export const selectLastReviewedStringDiff = createSelector(selectEntries, (entries) =>
    entries.map((entry) => entry.lastReviewedAt).join(",")
);

export const selectReservationMessages = createSelector(
    selectActiveConversationsState,
    (conversationsState) => conversationsState.reservationMessages
);

export const selectConversationMessages = createSelector(
    selectActiveConversationsState,
    (conversationsState) => conversationsState.conversationMessages
);

export const selectNotifiedMessages = createSelector(
    selectActiveConversationsState,
    (conversationsState) => conversationsState.notififiedMessages
);

export const selectNotYetNotifiedMessages = createSelector(
    selectConversationMessages,
    selectNotifiedMessages,
    (conversationMessages, notifiedMessages) => {
        const notNotifiedMessages: { [sid: string]: any[] } = {};
        for (const key in conversationMessages) {
            if (!notifiedMessages[key]) {
                notNotifiedMessages[key] = conversationMessages[key];
            } else {
                const notifiedMessageSids = notifiedMessages[key];
                const conversationMessagesSids = conversationMessages[key].map((message: any) => message.smsMessageSid);
                const notNotifiedMessageSids = conversationMessagesSids.filter(
                    (sid) => !notifiedMessageSids.includes(sid)
                );

                notNotifiedMessages[key] = conversationMessages[key].filter((message: any) =>
                    notNotifiedMessageSids.includes(message.smsMessageSid)
                );
            }
        }
        return notNotifiedMessages;
    }
);

export const selectTwilioConversationMessages = createSelector(
    selectEntries,
    selectConversationMessages,
    selectReservationMessages,
    (entries, conversationMessages, reservationMessages) => {
        const messages: { [sid: string]: any[] } = {};

        for (let i = 0; i < entries.length; i++) {
            const sid = entries[i].sid;
            messages[sid] = [];

            if (reservationMessages[sid]) {
                messages[sid].push(...reservationMessages[sid]);
            }

            if (conversationMessages[sid]) {
                messages[sid].push(...conversationMessages[sid]);
            }
        }
        return messages;
    }
);

export const selectActiveMessages = createSelector(
    selectTwilioConversationMessages,
    selectActiveTask,
    (twilioMessages, task) => {
        const activeSid = task?.attributes?.conversationSid;
        const messages = twilioMessages[activeSid] || [];
        return messages;
    }
);

/**
 * Unred Conversations
 */
export const selectUnreadSms = createSelector(
    selectUnreadConversationsState,
    (unreadConversationsState) => unreadConversationsState.sms
);
