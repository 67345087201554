import { useCallback, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { TASK_TITLES } from "@regal-voice/shared-types";
import { message } from "antd";
import { useSelector } from "react-redux";

import { useDefaultFromNumber } from "Hooks/useDefaultFromNumber";
import { useEnsureAvailability } from "Hooks/useEnsureAvailability/useEnsureAvailability";
import { useLookerDashboards } from "Hooks/useLookerDashboards";
import { useServerSentEvent } from "Hooks/useServerSentEvent";
import { useTwilioDevice, useTwilioDeviceListeners } from "Hooks/useTwilioDevice/useTwilioDevice";
import { logger as authLogger, useBrandSlug } from "Services/AuthService";
import { isPhoneNumberValid } from "Services/CommunicationService";
import { createManualOutboundEmailTask } from "Services/ConversationsApiService";
import {
    EmbedParentListeners,
    postMessageToParentWithStatusIfNeeded,
    setupParentListenersIfNeeded,
} from "Services/embed/CrossDomainMessenger";
import { useFlags } from "Services/FeatureFlagService";
import { removeClientPrefixFromString } from "Services/HelpersService";
import { getLogger, renderErrorMessage } from "Services/LoggingService";
import { updateAgent } from "Services/marketing-api/agents/mutations";
import { getAuthenticatedUser } from "Services/marketing-api/agents/queries";
import { getCurrentBrand } from "Services/marketing-api/brands/queries";
import {
    selectAgentActivity,
    selectAgentEmail,
    selectAgentInformation,
    setAgentData,
} from "Services/state/agent/AgentInformationSlice";
import { selectBrand, selectBrandAvailableStatuses, updateBrand } from "Services/state/brand";
import { subscribeToConferenceSSE } from "Services/state/conferences/SSESubscription";
import { setContactAttributes } from "Services/state/contact-attributes";
import { subscribeToContactUpdates } from "Services/state/contacts/SSESubscription";
import { useRVDispatch } from "Services/state/Storage";
import { subscribeToTaskSSE } from "Services/state/tasks/SSESubscription";
import { handleOutboundCallTaskCreationThunk, handleOutboundSmsTaskCreationThunk } from "Services/state/tasks/Thunks";
import { useTabDetection } from "Services/TabDetectionService";
import { validateEmail } from "Services/validation/EmailValidationService";
import { ContactAttribute } from "Types/ContactAttribute";

import { getContactAttributes } from "../../contexts/queries";
import { brandMapper } from "../Utils/BrandMapper";

const logger = getLogger("App Setup Hooks");
export const embeddedLogger = getLogger("Embedded Messaging");

/**
 * PROVIVDER HOOKS
 */
const useProvideContactAtrributes = () => {
    const brandSlug = useBrandSlug();
    const dispatch = useRVDispatch();

    useQuery<{ getContactAttributes: Array<ContactAttribute> }>(getContactAttributes, {
        skip: !brandSlug,
        errorPolicy: "all",
        onCompleted: (data) => {
            dispatch(setContactAttributes(data.getContactAttributes));
        },
        onError(error) {
            renderErrorMessage({
                content: "Couldn't retrieve data",
                error,
                loggerContext: "Query getContactAttributes",
            });
        },
    });
};

const useProvideAgentInfo = () => {
    const agentEmail = useSelector(selectAgentEmail);
    const agentStatus = useSelector(selectAgentActivity);
    const dispatch = useRVDispatch();
    const { refetch } = useQuery(getAuthenticatedUser, {
        onError(error) {
            logger.warn("Couldn't retrieve agent data", { error });
        },

        onCompleted({ getAuthenticatedUser }) {
            const {
                email,
                status,
                statusChangedAt,
                twilioAttributes,
                twilioContactUri,
                twilioSid,
                name,
                preferences,
                gmailIntegration,
            } = getAuthenticatedUser;
            dispatch(
                setAgentData({
                    phoneNumber: twilioAttributes?.agentRegalVoicePhone,
                    email,
                    name,
                    workerSid: twilioSid,
                    workerUri: twilioContactUri ? removeClientPrefixFromString(twilioContactUri) : undefined,
                    status: status,
                    statusChangedAt: statusChangedAt,
                    attributes: twilioAttributes,
                    preferences,
                    gmailIntegration,
                })
            );
        },
    });

    useServerSentEvent("sse", "connection.open", async () => {
        await refetch();
    });

    useServerSentEvent("sse", "user.updated", (event) => {
        if (event.twilioEmail !== agentEmail) {
            return;
        }

        if (agentStatus.name !== event.twilioStatus) {
            postMessageToParentWithStatusIfNeeded(event.twilioStatus as string);
        }

        dispatch(
            setAgentData({
                phoneNumber: event.twilioAttributes?.agentRegalVoicePhone,
                email: event.twilioEmail,
                workerSid: event.twilioWorkerSid,
                workerUri: event.twilioContactUri ? removeClientPrefixFromString(event.twilioContactUri) : undefined,
                status: event.twilioStatus,
                statusChangedAt: String(event.twilioStatusChangedAt),
                attributes: event.twilioAttributes,
                conferenceStatus: event.conferenceStatus,
            })
        );
    });
};

const useUserProviderSetup = () => {
    useProvideContactAtrributes();
    useProvideAgentInfo();
    useLookerDashboards(); // prefetch looker dashboards
    useTabDetection();
    useTwilioDeviceListeners();
};

/**
 * SSE HOOKS
 */
const useSSESubscriptions = () => {
    const { multipleCalls } = useFlags();
    const { device, fetchNewAuthToken } = useTwilioDevice();
    useEffect(() => {
        const unsubscribeFromContactUpdates = subscribeToContactUpdates();
        const unsubscribeFromTaskSSE = subscribeToTaskSSE({
            multipleCalls,
            device,
            fetchNewDeviceToken: fetchNewAuthToken,
        });
        const unsubscribeFromConferenceSSE = subscribeToConferenceSSE();
        return () => {
            unsubscribeFromContactUpdates();
            unsubscribeFromTaskSSE();
            unsubscribeFromConferenceSSE();
        };
    }, [multipleCalls, device, fetchNewAuthToken]);
};

/**
 * BRAND HOOKS
 */
const useProvideBrandInfo = () => {
    const brandSlug = useBrandSlug();
    const brand = useSelector(selectBrand);
    const dispatch = useRVDispatch();

    const { refetch } = useQuery(getCurrentBrand, {
        skip: !brandSlug,
        errorPolicy: "all",
        notifyOnNetworkStatusChange: true, // necessary for onCompleted to run after refetch
        onError: (error) => {
            renderErrorMessage({
                content: error,
                error,
                duration: 10,
            });
        },
        onCompleted: (data) => {
            const mappedBrand = brandMapper(data.getBrand);
            authLogger.log("Fetched and now updating brand in AuthenticatedUserContext because accessToken changed", {
                brand: mappedBrand,
            });
            // getCurrentBrand graphql query returns different data than authenticateBrand()
            dispatch(updateBrand(mappedBrand));
        },
    });

    return { brand, refetch };
};

const EMAIL_REQUIRED_MESSAGE = "Email is required.";
const getEmailInvalidMessage = (email: string) => `Email "${email}" is invalid.`;
const PHONE_REQUIRED_MESSAGE = "Phone number is required.";
const getPhoneInvalidMessage = (phone: string) => `Phone number "${phone}" is invalid.`;

/**
 * Sets up communication with parent containers if the app is embedded
 */
export const useEmbeddedMessaging = () => {
    const { workerSid, email } = useSelector(selectAgentInformation);
    const availableStatuses = useSelector(selectBrandAvailableStatuses);
    const agentActivity = useSelector(selectAgentActivity);
    const { ensureAgentIsAvailable } = useEnsureAvailability();
    const { getDefaultFromNumberForContactPhone } = useDefaultFromNumber({});
    const [updateAgentMutation] = useMutation(updateAgent);
    const dispatch = useRVDispatch();

    const changeStatusCallback = useCallback<EmbedParentListeners["changeStatusCallback"]>(
        (data) => {
            const { status } = data;
            if (!status) {
                embeddedLogger.warn("Missing status in payload to change agent status", data);
                return;
            }
            if (agentActivity.name === status) {
                return;
            }

            if (!availableStatuses.find((item) => item.name === status)) {
                embeddedLogger.warn("Status in payload to change agent status does not match any brand statuses", data);
                message.error(`The status "${status}" does not exist.`);
                return;
            }

            updateAgentMutation({
                mutation: updateAgent,
                variables: {
                    updateUserData: {
                        status,
                        twilioSid: workerSid,
                    },
                },
            });
        },
        [workerSid, agentActivity, availableStatuses, updateAgentMutation]
    );

    const createTaskCallback = useCallback<EmbedParentListeners["createTaskCallback"]>(
        async (data) => {
            const { type: taskType, phoneNumber: contactPhone } = data;
            ensureAgentIsAvailable();
            switch (taskType) {
                case TASK_TITLES.MANUAL_OUTBOUND_CALL:
                    if (!contactPhone || !isPhoneNumberValid(contactPhone)) {
                        embeddedLogger.warn(
                            "Missing or invalid phone number to create Manual Outbound Call task",
                            data
                        );
                        message.error(!contactPhone ? PHONE_REQUIRED_MESSAGE : getPhoneInvalidMessage(contactPhone));
                        return;
                    }
                    dispatch(
                        handleOutboundCallTaskCreationThunk({
                            contactPhone,
                            triggeredFromDialpad: true,
                            regalVoicePhone: await getDefaultFromNumberForContactPhone(contactPhone),
                        })
                    );
                    break;
                case TASK_TITLES.OUTBOUND_SMS:
                    if (!contactPhone || !isPhoneNumberValid(contactPhone)) {
                        embeddedLogger.warn("Missing or invalid phone number to create Outbound SMS task", data);
                        message.error(!contactPhone ? PHONE_REQUIRED_MESSAGE : getPhoneInvalidMessage(contactPhone));
                        return;
                    }
                    dispatch(
                        handleOutboundSmsTaskCreationThunk({
                            contactPhone,
                            regalVoicePhone: await getDefaultFromNumberForContactPhone(contactPhone),
                        })
                    );
                    break;
                case TASK_TITLES.MANUAL_OUTBOUND_EMAIL:
                    const { email: contactEmail } = data;
                    if (!contactEmail || !validateEmail(contactEmail)) {
                        embeddedLogger.warn(
                            "Missing or invalid email address to create Manual Outbound Email task",
                            data
                        );
                        message.error(!contactEmail ? EMAIL_REQUIRED_MESSAGE : getEmailInvalidMessage(contactEmail));
                        return;
                    }
                    try {
                        await createManualOutboundEmailTask({
                            contactEmail,
                            agentEmail: email as string,
                        });
                    } catch (e) {
                        embeddedLogger.error("Failed to create Outbound Email task", { data, error: e });
                        message.error("Email does not match a contact in Regal.");
                    }

                    break;
                default:
                    embeddedLogger.warn("Unhandled task type from parent", data);
                    break;
            }
        },
        [dispatch, email, ensureAgentIsAvailable, getDefaultFromNumberForContactPhone]
    );

    useEffect(() => {
        const clearParentListeners = setupParentListenersIfNeeded({ changeStatusCallback, createTaskCallback });
        return clearParentListeners;
    }, [changeStatusCallback, createTaskCallback]);

    // just for testing purposes
    return { changeStatusCallback, createTaskCallback };
};

/**
 * HOOKS EXPORT
 */
export const useAppSetupHooks = () => {
    useEmbeddedMessaging();
    useUserProviderSetup();
    useSSESubscriptions();
    return useProvideBrandInfo();
};
