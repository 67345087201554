import { useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { selectBrandAppNotificationsConfig } from "Services/state/brand";
import { addNotifiedMessage } from "Services/state/conversations/ConversationsStateProvider";
import { selectNotYetNotifiedMessages } from "Services/state/conversations/Selectors";
import { conversations } from "Services/TwilioConversations.service";

import { useVisualNotification } from "../useVisualNotification";

import type { playTaskAudioFunc } from "Components/elements/UserNotifier/hooks/useTaskSpecificAudio";
interface MessageAttributes {
    contactPhone: string;
    regalVoicePhone: string;
    taskSid: string;
}

/**
 * @todo move all of this code into twilio conversations service
 * AX-273
 * @todo we should make sure to memoize playTaskAudio
 */
export function useMonitorActiveConversations({ playTaskAudio }: { playTaskAudio: playTaskAudioFunc }): void {
    const messagingConfig = useSelector(selectBrandAppNotificationsConfig);
    const configsEnabled = messagingConfig.enabled;
    const { showVisualNotification } = useVisualNotification();

    const toNotify = useSelector(selectNotYetNotifiedMessages);

    const dispatch = useDispatch();

    const handleNotification = useCallback(
        (m: any, key: string) => {
            let shouldNotify = true;
            const messageAttributes = conversations.get(key)?.attributes as MessageAttributes;
            if (!configsEnabled) {
                shouldNotify = false;
            }

            if (m.direction?.toLowerCase() === "outbound") {
                shouldNotify = false;
            }

            if (shouldNotify) {
                playTaskAudio("sms.received", messageAttributes?.taskSid, key, {
                    message: key,
                    m,
                });
                showVisualNotification("You have a new message in your active chat", key);
            }
            return m;
        },
        [configsEnabled, playTaskAudio, showVisualNotification]
    );

    useEffect(() => {
        for (const key in toNotify) {
            const messages = toNotify[key] as unknown as any[];

            messages.forEach((message: any) => {
                handleNotification(message, key);
                dispatch(addNotifiedMessage({ sid: key, messageSid: message.smsMessageSid }));
            });
        }
    }, [toNotify, handleNotification, messagingConfig, dispatch]);
}
