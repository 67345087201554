import Icon, { IconSizes } from "Components/elements/Icon";

import styles from "./UnstableNetworkBanner.module.scss";

const HELP_CENTER_ARTICLE_URL =
    "https://support.regalvoice.com/hc/en-us/articles/6744950315035-Hardware-and-network-requirements";

export function UnstableNetworkBanner(): JSX.Element {
    return (
        <a
            className={styles.container}
            href={HELP_CENTER_ARTICLE_URL}
            target="_blank"
            rel="noreferrer"
            data-testid="unstable-network-banner"
        >
            <span className={styles.iconContainer}>
                <Icon className={styles.icon} size={IconSizes.SMALL} icon="exclamation-circle" color="white" />
            </span>
            <span className={styles.text}>
                We&apos;ve detected an unstable network connection which can lead to low call quality or dropped calls.
                Please visit our <span className={styles.link}>Help Center</span> for support.
            </span>
        </a>
    );
}
