import { gql } from "@apollo/client";

export const cancelTasks = gql`
    mutation ($ids: [String!], $phoneNumber: String, $cancelAttributes: CanceledTaskInput!) {
        cancelTasks(ids: $ids, phoneNumber: $phoneNumber, cancelAttributes: $cancelAttributes)
    }
`;

export const completeTasks = gql`
    mutation ($ids: [String!]!) {
        completeTasks(ids: $ids) {
            sid
        }
    }
`;
