import { useEffect, useMemo, useState, type PropsWithChildren } from "react";

import { RVTask } from "@regal-voice/shared-types";
import { Dropdown, Menu, Tooltip, type DropdownProps, type TooltipProps } from "antd";

import { useTaskReject, CancelType } from "./useTaskReject";

import styles from "./RejectTaskOptions.module.scss";

const DROPDOWN_TRIGGER: DropdownProps["trigger"] = ["click"];

type RejectTaskOptionsProps = PropsWithChildren<{
    onLoadStateChange?: (loading: boolean) => void;
    task?: RVTask;
    dropdownPlacement?: DropdownProps["placement"];
    tooltipPlacement?: TooltipProps["placement"];
}>;

export default function RejectTaskOptions({
    children,
    onLoadStateChange,
    task,
    dropdownPlacement = "bottomLeft",
    tooltipPlacement,
}: RejectTaskOptionsProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const { handleCancelTask, loading } = useTaskReject({ task });

    useEffect(() => {
        onLoadStateChange?.(loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this effect when loading changes
    }, [loading]);

    const renderOptions = useMemo(() => {
        const cancelThisTask = () => handleCancelTask(CancelType.Task);
        const cancelCampaign = () => handleCancelTask(CancelType.CampaignTasks);
        return function renderDropdownOptions() {
            return (
                <Menu className={styles.menu}>
                    <Menu.Item className={styles.cancelItem} disabled={true}>
                        <b>Cancel for this contact…</b>
                    </Menu.Item>
                    <Menu.Item className={styles.cancelItem} onClick={cancelThisTask}>
                        This task only
                    </Menu.Item>
                    <Menu.Item className={styles.cancelItem} onClick={cancelCampaign}>
                        This task &amp; End campaigns
                    </Menu.Item>
                </Menu>
            );
        };
    }, [handleCancelTask]);

    const childrenNode = isOpen ? (
        children
    ) : (
        <Tooltip title="Cancel" placement={tooltipPlacement}>
            {children}
        </Tooltip>
    );

    return (
        <Dropdown
            dropdownRender={renderOptions}
            placement={dropdownPlacement}
            trigger={DROPDOWN_TRIGGER}
            onOpenChange={setIsOpen}
        >
            {childrenNode}
        </Dropdown>
    );
}
