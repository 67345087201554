import { NullOr } from "@regal-voice/shared-types";
import { pick } from "lodash";

import { ConnectionStats } from "Types/ConnectionStats";

import { AggregateConnectionQualityStatistics, ConnectionQualityStateEntry } from "./ConnectionQualityStateSlice";

/**
 * There are two kinds of stats for call quality:
 * 1) "Epehmeral" numbers that we then aggregate into min, max, and averages over time (like jitter)
 * 2) "Total" numbers (like packetsLost) that we sum over time
 *
 * This method accepts the newRawStatistics, which is a WebRTC sample object that a Twilio Device emits every second,
 * and currentConnectionQualityData, which is the current aggregated and summed up call quality stats for the full call.
 *
 * It returns a new version of currentConnectionQualityData that is updated to reflect the newRawStatistics which just came in.
 */
export function recomputeConnectionQualityState(
    newRawStatistics: ConnectionStats,
    currentConnectionQualityData?: ConnectionQualityStateEntry
): ConnectionQualityStateEntry {
    const ephemeralStatKeys = ["audioInputLevel", "audioOutputLevel", "jitter", "mos", "rtt"];

    const newAggregateStats: AggregateConnectionQualityStatistics = Object.entries(
        pick(newRawStatistics, ephemeralStatKeys)
    ).reduce((acc, statKeyAndVal) => {
        const statKey = statKeyAndVal[0] as keyof AggregateConnectionQualityStatistics;
        const statVal = statKeyAndVal[1] as NullOr<number>;
        // Should only happen when we receive the first sample
        if (!currentConnectionQualityData) {
            if (typeof statVal !== "number") {
                return {
                    ...acc,
                    [statKey]: {
                        min: Number.POSITIVE_INFINITY,
                        max: Number.NEGATIVE_INFINITY,
                        average: 0,
                        sampleCount: 0,
                    },
                };
            }

            return {
                ...acc,
                [statKey]: {
                    min: statVal,
                    max: statVal,
                    average: statVal,
                    sampleCount: 1,
                },
            };
        }

        // By this point, these 3 values should always exist. The fallbacks are just failsafes to prevent errors
        const currentMin = currentConnectionQualityData[statKey]?.min ?? Number.POSITIVE_INFINITY;
        const currentMax = currentConnectionQualityData[statKey]?.max ?? Number.NEGATIVE_INFINITY;
        const currentSampleCount = currentConnectionQualityData[statKey]?.sampleCount ?? 0;
        const currentAverage = currentConnectionQualityData[statKey]?.average ?? statVal ?? 0;

        if (typeof statVal !== "number") {
            return {
                ...acc,
                [statKey]: {
                    min: currentMin,
                    max: currentMax,
                    average: currentAverage,
                    sampleCount: currentSampleCount,
                },
            };
        }

        const newMin = Math.min(currentMin, statVal);
        const newMax = Math.max(currentMax, statVal);
        const newSampleCount = currentSampleCount + 1;
        const newAverage = (currentAverage * currentSampleCount + statVal) / newSampleCount;

        return {
            ...acc,
            [statKey]: {
                min: newMin,
                max: newMax,
                average: newAverage,
                sampleCount: newSampleCount,
            },
        };
    }, {} as AggregateConnectionQualityStatistics);

    return {
        codecName: newRawStatistics.codecName,
        ...newRawStatistics.totals,
        ...newAggregateStats,
    };
}
