import { LDClient } from "launchdarkly-js-client-sdk";
import { kebabCase } from "lodash";
import { BehaviorSubject } from "rxjs";

import { ReadonlyBehaviorSubject, readOnly } from "Utils/ReadonlyBehaviorSubject";

import { Flag, FlagTypes, FLAG_DEFAULTS } from "./config";

class FlagNameError extends Error {
    constructor(flagName: string) {
        super(`Could not find flag '${flagName}'`);
    }
}

type FlagSubjects = {
    [K in keyof FlagTypes]: ReadonlyBehaviorSubject<FlagTypes[K]>;
};

let ldClient: LDClient | undefined;
export const setLDClient = (client: LDClient) => {
    ldClient = client;
};

const flagSubjects$: FlagSubjects = {} as any;

/**
 * @param flagName camelCase'd flag name to lookup/subscribe to value of
 */
export function flag$<T extends Flag>(flagName: T): ReadonlyBehaviorSubject<FlagTypes[T]> {
    if (!ldClient) {
        throw new Error("LD Client not set");
    }

    if (flagName in flagSubjects$) {
        return flagSubjects$[flagName] as ReadonlyBehaviorSubject<FlagTypes[T]>;
    }

    const lookupName: keyof typeof FLAG_DEFAULTS = (flagName in FLAG_DEFAULTS
        ? flagName
        : kebabCase(flagName)) as unknown as keyof typeof FLAG_DEFAULTS;

    if (!(lookupName in FLAG_DEFAULTS)) {
        throw new FlagNameError(flagName);
    }

    const flagListener$ = new BehaviorSubject<FlagTypes[T]>(ldClient.variation(lookupName, FLAG_DEFAULTS[lookupName]));

    flagSubjects$[flagName] = readOnly(flagListener$) as any;

    ldClient.on(`change:${lookupName}`, (newVal: FlagTypes[T]) => flagListener$.next(newVal));

    // remove this listener on error/completed
    flagListener$.subscribe(
        null,
        (err: any) => {
            // eslint-disable-next-line no-console
            console.debug(`Error encounted with flag ${flagName}`, err);
            delete flagSubjects$[flagName];
        },
        () => {
            delete flagSubjects$[flagName];
        }
    );

    return flagSubjects$[flagName] as ReadonlyBehaviorSubject<FlagTypes[T]>;
}
