import { useCallback } from "react";

import { clsx } from "clsx";

import { RVButton } from "Components/elements/RVButton/RVButton";

import styles from "./Dialpad.module.scss";

const DIAL_BUTTONS = [
    { name: "1", text: "" },
    { name: "2", text: "ABC" },
    { name: "3", text: "DEF" },
    { name: "4", text: "GHI" },
    { name: "5", text: "JKL" },
    { name: "6", text: "MNO" },
    { name: "7", text: "PQRS" },
    { name: "8", text: "TUV" },
    { name: "9", text: "WXYZ" },
    { name: "*", text: "" },
    { name: "0", text: "" },
    { name: "#", text: "" },
];

export type DialpadOnDigitPress = (digit: string) => void;

export type DialpadProps = {
    onDigitPress: DialpadOnDigitPress;
};

export function Dialpad({ onDigitPress }: DialpadProps): JSX.Element {
    return (
        <div className={styles.digitsContainer} data-testid="dialpad">
            {DIAL_BUTTONS.map(({ name, text }) => {
                return <DialpadButton key={name} name={name} text={text} onClick={onDigitPress} />;
            })}
        </div>
    );
}

type DialpadButtonProps = {
    onClick: DialpadOnDigitPress;
    name: string;
    text: string;
};

const DialpadButton = ({ name, text, onClick }: DialpadButtonProps) => {
    const handleClick = useCallback(() => {
        onClick(name);
    }, [onClick, name]);

    return (
        <div className={clsx("flex-column align-center", styles.buttonWrapper)}>
            <RVButton size="large" type="text" onClick={handleClick}>
                <span className={styles.buttonDigit}>{name}</span>
            </RVButton>
            <span className={styles.buttonText}>{text}</span>
        </div>
    );
};
