import { useState, useCallback } from "react";

import { RVPopoverWithTracking } from "Components/elements/RVPopoverWithTracking/RVPopoverWithTracking";
import { useTwilioDevice } from "Hooks/useTwilioDevice/useTwilioDevice";

import { dialpadLogger } from "../../CallBar.utils";
import { DialpadWithPreview, type DialpadOnDigitPress } from "./DialpadWithPreview";

export type DialpadPopoverButtonRenderer = (open: boolean) => JSX.Element;

export type DialpadPopoverProps = {
    renderButton: DialpadPopoverButtonRenderer;
};

export function DialpadPopover({ renderButton }: DialpadPopoverProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const { activeCall } = useTwilioDevice();
    const handleDialPadDigitPress = useCallback<DialpadOnDigitPress>(
        (digits) => {
            if (!activeCall) {
                dialpadLogger.warn(`sendDigits failed: no active call`, { digits });
                return;
            }

            activeCall.sendDigits(digits);
            dialpadLogger.log(`sendDigits ${digits}`);
        },
        [activeCall]
    );

    return (
        <RVPopoverWithTracking
            id="dialpad-popover"
            open={open}
            onOpenChange={setOpen}
            trigger="click"
            placement="top"
            content={<DialpadWithPreview onDigitPress={handleDialPadDigitPress} />}
        >
            {renderButton(open)}
        </RVPopoverWithTracking>
    );
}
