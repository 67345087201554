import { useQuery, DocumentNode, QueryHookOptions, OperationVariables, NoInfer } from "@apollo/client";

export function withApolloQuery<
    P extends Record<string, unknown>,
    Output,
    TData = any,
    TVariables extends OperationVariables = OperationVariables
>(
    Component: React.ComponentType<P & { data?: Output | TData; loading: boolean; error?: Error }>,
    query: DocumentNode,
    options?: QueryHookOptions<TData, NoInfer<TVariables>>,
    transformData?: (input?: TData) => Output
) {
    return function WithQueryHOC(props: P): JSX.Element {
        const { loading, data, error } = useQuery(query, options);
        const transformedData = transformData ? transformData(data) : data;
        return <Component {...props} data={transformedData} loading={loading} error={error} />;
    };
}

export function withApolloQueryOptionsFromProps<
    P extends Record<string, unknown>,
    Output,
    TData = any,
    TVariables extends OperationVariables = OperationVariables
>(
    Component: React.ComponentType<P & { data?: Output | TData; loading: boolean; error?: Error }>,
    query: DocumentNode,
    transformData?: (input?: TData) => Output
) {
    return function WithQueryHOC(
        props: P & { options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> }
    ): JSX.Element {
        const { loading, data, error } = useQuery(query, props.options);
        const transformedData = transformData ? transformData(data) : data;
        return <Component {...props} data={transformedData} loading={loading} error={error} />;
    };
}
