import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Tracker } from "@regal-voice/shared-types";

export type TranscriptionSharedDataState = {
    trackers: Tracker[];
};

export const initialState: TranscriptionSharedDataState = {
    trackers: [],
};

const TranscriptionSharedDataStateSlice = createSlice({
    name: "transcriptionsSharedData",
    initialState,
    reducers: {
        setSharedData(
            state,
            action: PayloadAction<{
                trackers: Tracker[];
            }>
        ) {
            state.trackers = action.payload.trackers;
        },
        clearSharedData() {
            return { ...initialState };
        },
    },
});

export const { setSharedData, clearSharedData } = TranscriptionSharedDataStateSlice.actions;
export default TranscriptionSharedDataStateSlice.reducer;
