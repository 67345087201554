import { useQuery } from "@apollo/client";

import { getDashboards } from "Pages/reporting/queries/queries";

export function useLookerDashboards() {
    const { data: { dashboards } = { dashboards: [] }, refetch: refetchDashboards } = useQuery(getDashboards, {
        fetchPolicy: "cache-and-network",
    });
    return { dashboards, refetchDashboards };
}
