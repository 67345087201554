import { MessageType } from "@regal-voice/shared-types";

import { addEventEmitterListener } from "Services/server-sent-events/EventEmitter";

import { reduxStore } from "../Storage";
import { updateContact } from "./Thunks";

import type { Profile } from "Types/Profile";

export function dispatchContactUpdated(data: MessageType["sse"]["contact.updated"]): void {
    reduxStore.dispatch(updateContact(data as Partial<Profile>));
}

export function subscribeToContactUpdates(): () => void {
    return addEventEmitterListener("sse", "contact.updated", dispatchContactUpdated);
}
