import { EventEmitter } from "events";
import { noop } from "lodash";

import { getLogger } from "Services/LoggingService";
import { reduxStore } from "Services/state/Storage";
import { selectTasks } from "Services/state/tasks/Selectors";

import { isInteractionRequiredError, showInteractionRequiredMessage } from "./errors.helpers";
const audioController = new EventEmitter();
const logger = getLogger("Audio player");

export const audioElements: Map<
    string,
    { audio: HTMLAudioElement; stop?: () => void; playing?: boolean; shouldPlaythrough?: boolean }
> = new Map();
const audioErrors: Map<string, number> = new Map();

export function initializeAudioElement(url: string, loop: boolean, logContext = {}, shouldPlaythrough = false) {
    if (audioElements.get(url)) {
        logger.debug("audioElements already contains entry for url, skipping initialization", {
            existingEntry: audioElements.get(url),
        });
        return;
    }

    logger.log(`${Date.now()}: Initializing audio`, { url, loop, logContext });
    // Initializing the Audio will start downloading the file. The preload line down below was to add more guarantee around the file getting cached.
    // See this conversation: https://github.com/Regal-Voice/regal-voice-ui/pull/2593#discussion_r903689725
    const audio = new Audio(url);
    audio.preload = "auto";
    // Adding to the DOM to ensure audio events are emitted: https://stackoverflow.com/a/23549577/12447086
    document.body.appendChild(audio);
    audio.crossOrigin = "anonymous";

    audio.addEventListener("error", () => {
        audioErrors.set(url, audio.error?.code || -1);
        logger.warn("Audio load error", { url, errorCode: audio.error?.code, logContext });
    });

    const stop = (stopContext: Record<string, any> = {}) => {
        logger.log(`${Date.now()}: Stopping audio`, {
            url,
            stopContext,
            // bug bash clean up, should handle this better
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            alreadyPlaying: !audioElements.get(url)!.playing,
        });
        audio.pause();
        audioElements.set(url, {
            audio,
            stop,
            playing: false,
            shouldPlaythrough,
        });
    };

    audioController.on("stop", (onStopContext) => {
        logger.log(`${Date.now()}: Stop event received`, {
            url,
            logContext: onStopContext,
        });
        stop(onStopContext);
    });
    audioElements.set(url, { audio, stop, shouldPlaythrough });
}

export async function play(
    url: string,
    {
        loop,
        logContext,
        onComplete,
        taskSid,
    }: {
        loop?: boolean;
        logContext?: Record<string, any>;
        onComplete?: () => void | Promise<void>;
        taskSid: string;
    }
): Promise<() => void> {
    function canPlayAudio() {
        // Should be moved to a `canContinuePlaying` callback option to make play non-task dependent
        const tasks = selectTasks(reduxStore.getState());
        return Object.values(tasks)
            .map(({ taskSid }) => taskSid)
            .includes(taskSid);
    }

    if (audioErrors.has(url)) {
        logger.warn("Attempting to play audio with noted load error", {
            url,
            previousErrorCode: audioErrors.get(url),
            logContext,
        });
    }

    if (!audioElements.has(url)) {
        logger.warn(
            "Attempting to play pre-initialized audio. This should never happen, particularly after the beginning of a user session"
        );
        initializeAudioElement(url, !!loop);
    }
    // bug bash clean up, should handle this better
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { audio, stop, playing, shouldPlaythrough } = audioElements.get(url)!;

    audio.loop = !!loop;
    audio.onended =
        !playing && !loop && onComplete
            ? () => {
                  logger.log(`${Date.now()}: Audio completed`, { url, logContext });
                  onComplete();
              }
            : null;

    try {
        playing && logger.log(`${Date.now()}: Audio already playing`, { url, logContext });
        if (!playing) {
            audio.load();
            const audioLoadStart = Date.now();
            logger.log(`${audioLoadStart}: Attempting to play audio`, { url, loop, logContext });
            await audio.play();
            const audioLoadStop = Date.now();
            logger.log(`${audioLoadStop}: Playing audio`, {
                url,
                loop,
                logContext,
                loadTimeMs: audioLoadStop - audioLoadStart,
            });
            audioElements.set(url, {
                audio,
                stop,
                playing: loop,
                shouldPlaythrough,
            });
        }
    } catch (e: any) {
        if (isInteractionRequiredError(e) && canPlayAudio()) {
            showInteractionRequiredMessage("Attempting to play an audio alert. Click here to re-enable.", () => {
                if (canPlayAudio()) {
                    play(url, { loop, logContext, taskSid });
                }
            });
        } else {
            logger.error(e, { ...logContext, url });
        }
    }

    return audioElements.get(url)?.stop || noop;
}

export function stopAll(logContext?: Record<string, any>): void {
    audioController.emit("stop", logContext);
}
