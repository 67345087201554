import { useState, useCallback, useMemo } from "react";

import { Dropdown, Form, Menu, message, type FormProps } from "antd";
import { clsx } from "clsx";

import Icon, { IconSizes } from "Components/elements/Icon";
import { RVButton } from "Components/elements/RVButton/RVButton";
import { isPhoneNumberValid } from "Services/CommunicationService";
import { addParticipantToConference, agentInternalTransfer } from "Services/ConversationsApiService";
import { useFlags } from "Services/FeatureFlagService";
import { renderErrorMessage, normalizeError } from "Services/LoggingService";

import { ExternalTransfer } from "./ExternalTransfer/ExternalTransfer";
import { InternalTransfer } from "./InternalTransfer/InternalTransfer";

import styles from "./TransfersPanel.module.scss";

export const TRANSFER_TYPE = {
    INTERNALLY: "Internally",
    EXTERNALLY: "Externally",
} as const;

type TransferType = (typeof TRANSFER_TYPE)[keyof typeof TRANSFER_TYPE];

type CreateTransferPayload = {
    externalPhoneNumber: string;
    targetWorkerUri?: string;
    queueSid?: string;
    queueName?: string;
};

type InternalTransferPayload = {
    targetWorkerUri?: string;
    queueSid?: string;
    queueName?: string;
};

const LAYOUT = {
    wrapperCol: { span: 24 },
} satisfies Partial<FormProps>;

type TransferPanelHeaderRendererOptions = {
    goBack: () => void;
    transferType?: TransferType;
    setTransferType: (type: TransferType) => void;
};

export type TransferPanelHeaderRenderer = (options: TransferPanelHeaderRendererOptions) => JSX.Element;

export type TrasnfersPanelProps = {
    currentWorkerUri: string;
    conferenceFriendlyId: string;
    regalVoicePhone: string;
    taskSid?: string;
    onCloseRequest: () => void;
    closeOnTransfer?: boolean;
    asPopover?: boolean;
    renderHeader?: TransferPanelHeaderRenderer;
};

export function TransfersPanel({
    currentWorkerUri,
    conferenceFriendlyId,
    regalVoicePhone,
    onCloseRequest,
    taskSid,
    closeOnTransfer = true,
    asPopover = false,
    renderHeader,
}: TrasnfersPanelProps): JSX.Element {
    const { ckExternalTransfers, ckInternalTransfers, hasExternalTransferPhonebook } = useFlags();
    const [transferType, setTransferType] = useState<TransferType | undefined>();
    const [transferring, setTransferring] = useState<boolean>();
    const [form] = Form.useForm();

    const handleExternalTransfer = useCallback(
        async (externalPhoneNumber: string) => {
            if (!isPhoneNumberValid(externalPhoneNumber)) {
                form.setFields([
                    {
                        name: hasExternalTransferPhonebook ? "phoneSelect" : "externalPhoneNumber",
                        errors: ["Invalid external transfer phone number."],
                    },
                ]);
                return;
            }
            setTransferring(true);
            try {
                await addParticipantToConference({
                    conferenceFriendlyId,
                    regalVoicePhone,
                    externalPhoneNumber: externalPhoneNumber,
                    taskSid: taskSid as string,
                });

                if (closeOnTransfer) {
                    onCloseRequest();
                } else {
                    setTransferType(undefined);
                }
                message.success("Transfer placed succesfully. Connecting...");
            } catch (error) {
                renderErrorMessage({ content: "Transfer failed.", error: normalizeError(error) });
            } finally {
                setTransferring(false);
                form.resetFields();
            }
        },
        [form, conferenceFriendlyId, regalVoicePhone, taskSid, onCloseRequest, closeOnTransfer]
    );

    const handleAgentInternalTransfer = useCallback(
        async ({ targetWorkerUri, queueSid, queueName }: InternalTransferPayload) => {
            setTransferring(true);
            try {
                await agentInternalTransfer({
                    taskSid: taskSid as string,
                    sourceWorkerUri: `client:${currentWorkerUri}`,
                    targetWorkerUri,
                    queueSid,
                    queueName,
                });

                if (closeOnTransfer) {
                    onCloseRequest();
                } else {
                    setTransferType(undefined);
                }
                message.success("Transfer placed succesfully. Connecting...");
            } catch (error) {
                renderErrorMessage({ content: "Transfer failed.", error: normalizeError(error) });
            } finally {
                setTransferring(false);
                form.resetFields();
            }
        },
        [form, currentWorkerUri, taskSid, onCloseRequest, closeOnTransfer]
    );

    const createTransfer = useCallback(
        async ({ externalPhoneNumber, targetWorkerUri, queueSid, queueName }: CreateTransferPayload) => {
            if (!taskSid || !conferenceFriendlyId || !regalVoicePhone) {
                renderErrorMessage({ content: "Can not initiate the transfer. Data is missing." });
                return;
            }
            // temporary until back end is usable also
            if (externalPhoneNumber) {
                await handleExternalTransfer(externalPhoneNumber);
            } else if (targetWorkerUri) {
                await handleAgentInternalTransfer({ targetWorkerUri });
            } else if (queueSid) {
                await handleAgentInternalTransfer({ queueSid, queueName });
            }
        },
        [taskSid, conferenceFriendlyId, regalVoicePhone, handleExternalTransfer, handleAgentInternalTransfer]
    );

    const goBack = useCallback(() => {
        if (!transferType) {
            onCloseRequest();
        } else {
            setTransferType(undefined);
        }
    }, [onCloseRequest, transferType]);

    const setInternalTransfer = useCallback(() => setTransferType(TRANSFER_TYPE.INTERNALLY), []);
    const setExternalTransfer = useCallback(() => setTransferType(TRANSFER_TYPE.EXTERNALLY), []);
    const submitForm = useCallback(() => form.submit(), [form]);
    const transferOverlay = useMemo(() => {
        if (!transferType || !ckExternalTransfers || !ckInternalTransfers) {
            return undefined;
        }

        return function renderMenu() {
            return (
                <Menu>
                    <Menu.Item
                        className={clsx({
                            [styles.selectedTransfer]: transferType == TRANSFER_TYPE.INTERNALLY,
                        })}
                        key="internal-transfer"
                        onClick={setInternalTransfer}
                    >
                        Internally
                    </Menu.Item>
                    <Menu.Item
                        className={clsx({
                            [styles.selectedTransfer]: transferType == TRANSFER_TYPE.EXTERNALLY,
                        })}
                        key="external-transfer"
                        onClick={setExternalTransfer}
                    >
                        Externally
                    </Menu.Item>
                </Menu>
            );
        };
    }, [transferType, ckExternalTransfers, ckInternalTransfers, setInternalTransfer, setExternalTransfer]);

    return (
        <div
            data-testid="transfers-panel"
            className={clsx(styles.transfersContainer, "flex-column", {
                [styles.popover]: asPopover,
            })}
        >
            {renderHeader ? (
                renderHeader({ goBack, transferType, setTransferType })
            ) : (
                <div className={styles.backToControls}>
                    <span onClick={goBack}>
                        <Icon className={styles.icon} icon="arrow-right" size={IconSizes.XSMALL} color="purple-5" />
                        <span>
                            <b>Transfer Call</b>
                        </span>
                    </span>
                    {transferOverlay && (
                        <Dropdown
                            className={styles.transferTypeContainer}
                            placement="bottomRight"
                            dropdownRender={transferOverlay}
                        >
                            <span className={styles.transferType}>{transferType}</span>
                        </Dropdown>
                    )}
                </div>
            )}
            {!transferType && (
                <div className={clsx(styles.transfersButtons, "flex-row")}>
                    {ckInternalTransfers && (
                        <RVButton size="middle" type="primary" ghost onClick={setInternalTransfer}>
                            Internally
                        </RVButton>
                    )}
                    {ckExternalTransfers && (
                        <RVButton size="middle" type="primary" ghost onClick={setExternalTransfer}>
                            Externally
                        </RVButton>
                    )}
                </div>
            )}
            {transferType && (
                <Form
                    {...LAYOUT}
                    form={form}
                    className={clsx(styles.formAlignment, "sm-form-item-form no-margin-bottom-last-item")}
                    onFinish={createTransfer}
                >
                    {transferType == TRANSFER_TYPE.INTERNALLY && <InternalTransfer />}
                    {transferType == TRANSFER_TYPE.EXTERNALLY && <ExternalTransfer />}
                    <Form.Item className={clsx(styles.actionButton, "flex-row justify-end")}>
                        <RVButton
                            disabled={transferring}
                            loading={transferring}
                            onClick={submitForm}
                            size="middle"
                            type="primary"
                            data-testid="transfer-button"
                        >
                            Transfer
                        </RVButton>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
}
