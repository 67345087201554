import { useState, useEffect, useRef, LegacyRef } from "react";

import { useQuery } from "@apollo/client";
import { RVTask } from "@regal-voice/shared-types";
import { Form, Select, Radio, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { AgentsDropdown } from "Components/shared/AgentsDropdown/AgentsDropdown";
import { useTaskReassign } from "Pages/agentDesktop/agentInterface/shared/RejectTaskOptions/useTaskReassign";
import {
    CancelType,
    getCancelSource,
    useTaskReject,
} from "Pages/agentDesktop/agentInterface/shared/RejectTaskOptions/useTaskReject";
import { getTaskOutcomes } from "Pages/agentDesktop/agentInterface/TaskOutcomes/Queries";
import { TaskActionModal } from "Pages/agentDesktop/agentInterface/TaskOutcomes/TaskActionModal";
import { useFlags } from "Services/FeatureFlagService";
import { alphabeticallySortByProperty, getColor } from "Services/HelpersService";
import { renderErrorMessage } from "Services/LoggingService";
import { selectReassignReasons } from "Services/state/brand";
import { TaskOutcome } from "Types/TaskOutcome";

import styles from "./TaskCancelation.module.scss";

const tailLayout = {
    wrapperCol: { span: 24 },
};

const RemoveType = {
    Cancel: "Cancel",
    Reassign: "Reassign",
};

export type RemoveTaskFormData = {
    removeType: keyof typeof RemoveType;
    cancelType: CancelType;
    text: string;
    notes: string;
    reassignAgent: string;
    reassignReason: string;
    reassignNotes: string;
};

export function TaskCancelation({
    task,
    asPopup,
    renderTargetClass,
}: {
    task: RVTask | undefined;
    asPopup?: boolean;
    renderTargetClass?: string;
}): JSX.Element {
    const { enableAgentToReassignTask } = useFlags();
    const [form] = Form.useForm();
    const [showWindow, setShowWindow] = useState<boolean>(false);
    const { handleCancelTask, loading } = useTaskReject({ task });
    const { reassignTask, loading: isUpdating } = useTaskReassign({ task });
    const [taskOutcomes, setTaskOutcomes] = useState<Array<TaskOutcome>>([]);
    const { refetch } = useQuery(getTaskOutcomes, {
        variables: { outcomeType: "cancelation_reason" },
        skip: !showWindow,
        fetchPolicy: "cache-and-network",
        onCompleted({ getTaskOutcomes }) {
            // That was needed to avoid editing readOnly values
            const sortedTaskOutcomes = [...getTaskOutcomes].sort(alphabeticallySortByProperty("text"));
            setTaskOutcomes(sortedTaskOutcomes);
        },
        onError(error) {
            renderErrorMessage({
                error,
                content: "Unable to fetch task cancellation reasons",
                loggerContext: "Unable to fetch task cancellation reasons",
            });
        },
    });
    const reassignReasons = useSelector(selectReassignReasons);

    const removeType = Form.useWatch(["removeType"], form);
    const title = enableAgentToReassignTask ? "Remove Task" : "Cancel Task";

    useEffect(() => {
        showWindow && refetch();
    }, [showWindow]);

    function onFinish(formValues: RemoveTaskFormData) {
        if (formValues.removeType === RemoveType.Reassign) {
            reassignTask(formValues.reassignAgent, formValues.reassignReason, formValues.reassignNotes);
        } else {
            handleCancelTask(formValues.cancelType, {
                source: getCancelSource(formValues.cancelType),
                reason: formValues.text,
                notes: formValues.notes,
            });
        }
    }
    const formRef = useRef<HTMLElement>();

    return (
        <div ref={formRef as LegacyRef<HTMLDivElement>}>
            <TaskActionModal
                form={form}
                showWindow={showWindow}
                setShowWindow={setShowWindow}
                modalTitle={title}
                targetTooltipTitle={title}
                renderTargetIcon="close"
                renderTargetClass={renderTargetClass}
                onFinish={onFinish}
                formRefs={[formRef]}
                initialValues={{
                    removeType: RemoveType.Cancel,
                    cancelType: CancelType.Task,
                }}
                asPopup={asPopup}
            >
                <>
                    {enableAgentToReassignTask && (
                        <Form.Item
                            label="Remove Task"
                            name="removeType"
                            rules={[{ required: true, message: "Remove type is required." }]}
                        >
                            <Radio.Group className="selection-radio" size="small">
                                <Radio
                                    className={styles.radio}
                                    value={RemoveType.Cancel}
                                    data-testid="remove-type-cancel"
                                >
                                    Cancel Task
                                </Radio>
                                <Radio
                                    className={styles.radio}
                                    value={RemoveType.Reassign}
                                    data-testid="remove-type-reassign"
                                >
                                    Reassign Task to Agent
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}

                    {!enableAgentToReassignTask || removeType === RemoveType.Cancel ? (
                        <>
                            <Form.Item
                                label="Cancel for this contact..."
                                name="cancelType"
                                rules={[{ required: true, message: "Cancel type is required." }]}
                            >
                                <Radio.Group className="selection-radio" size="small">
                                    <Radio
                                        className={styles.radio}
                                        value={CancelType.Task}
                                        data-testid="cancel-type-task"
                                    >
                                        This Task Only
                                    </Radio>
                                    <Radio
                                        className={styles.radio}
                                        value={CancelType.CampaignTasks}
                                        data-testid="cancel-type-task-and-campaigns"
                                    >
                                        This Task &amp; End Campaigns
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name="text" label="Cancelation Reason">
                                <Select
                                    showSearch
                                    placeholder="Select a Reason"
                                    allowClear
                                    size="large"
                                    getPopupContainer={(trigger: any) => trigger.parentNode}
                                    data-testid="select-cancel-reason"
                                >
                                    {taskOutcomes
                                        .filter(({ isTag }) => !isTag)
                                        .map(({ text, description }, idx) => (
                                            <Select.Option key={idx} value={text}>
                                                <Tooltip
                                                    title={
                                                        description || (
                                                            <i style={{ color: getColor("grey4") }}>No description</i>
                                                        )
                                                    }
                                                    placement={"top"}
                                                >
                                                    {text}
                                                </Tooltip>
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="notes" label="Notes">
                                <TextArea
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                    placeholder="Add any notes"
                                    data-testid="cancel-notes"
                                ></TextArea>
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            {/* We've been asked to add a bit of white space in the form*/}
                            {/* so that when switching between Cancel and Reassign */}
                            {/* contents don't jump around so much */}
                            <div className={styles.visualSpaceBalance}></div>
                            <AgentsDropdown data-testid="reassign-agent" task={task} />

                            <Form.Item
                                name="reassignReason"
                                label="Reassign Reason"
                                required={true}
                                rules={[{ required: true, message: "Reassign Reason is required." }]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Select a Reason"
                                    size="large"
                                    getPopupContainer={(trigger: any) => trigger.parentNode}
                                    data-testid="reassign-reason"
                                >
                                    {reassignReasons.map(({ id, reason }) => (
                                        <Select.Option key={id} value={reason}>
                                            <Tooltip
                                                title={
                                                    reason || <i style={{ color: getColor("grey4") }}>No description</i>
                                                }
                                                placement={"top"}
                                            >
                                                {reason}
                                            </Tooltip>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="reassignNotes" label="Notes">
                                <TextArea
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                    placeholder="Add any notes"
                                    data-testid="reassign-notes"
                                ></TextArea>
                            </Form.Item>
                        </>
                    )}
                    <Form.Item {...tailLayout}>
                        <RVButton
                            disabled={loading || isUpdating}
                            loading={loading || isUpdating}
                            block={true}
                            htmlType="submit"
                            size="large"
                            type="primary"
                            data-testid="remove-task-btn"
                            data-dd-action-name={removeType === RemoveType.Reassign ? "Reassign Task" : "Cancel Task"}
                        >
                            {removeType === RemoveType.Reassign ? "Remove Task" : "Cancel Task"}
                        </RVButton>
                    </Form.Item>
                </>
            </TaskActionModal>
        </div>
    );
}
