import { RVTask } from "@regal-voice/shared-types";

import { PROGRESSIVE_DIALER_AGENT_STATUS_NAME } from "Pages/agentDesktop/agentInterface/CallPanel/TransfersPanel/InternalTransfer/AgentTransfer/AgentTransfer";
import { TASK_LIST_EVALUATE_CALL_CHANNELS } from "Pages/agentDesktop/agentInterface/constants";
import { Agent } from "Types/Agent";

/**
 * @todo these should go some place global
 */
const availableStatus = "available";

export const AgentAvailability = {
    Available: "Available",
    Busy: "Busy",
    Unavailable: "Unavailable",
} as const;

export type AgentAvailabilityType = keyof typeof AgentAvailability;

export function isCallTask({ task }: { task?: RVTask }): boolean {
    return !!(task?.channelType && TASK_LIST_EVALUATE_CALL_CHANNELS.includes(task?.channelType));
}

export const getAgentAvailabilityForTask = (
    { isAvailable, conferenceStatus, status }: Agent,
    task: RVTask | undefined,
    enableTransferToBusyAgents: boolean
): AgentAvailabilityType => {
    if (!task) {
        return AgentAvailability.Unavailable;
    }

    // non call tasks
    // agent availability is the only parameter that matters here
    if (!isCallTask({ task })) {
        return isAvailable ? AgentAvailability.Available : AgentAvailability.Unavailable;
    }

    // calls
    // agent availability is computed like this:
    //    (Available): isAvailable && conferenceStatus === availableStatus && !progressiveDialerMode
    //    (Busy) :isAvailable &&  (conferenceStatus !== availableStatus || progressiveDialerMode)
    //    (Unavailable): !isAvailable
    // special case for inbound calls when `enableTransferToBusyAgents` flag is on (conferenceStatus is ignored)
    //    (Available): isAvailable && !progressiveDialerMode
    //    (Busy) :isAvailable && progressiveDialerMode)
    //    (Unavailable): !isAvailable
    if (!isAvailable) {
        return AgentAvailability.Unavailable;
    }
    // true when "conferenceStatus" is "available"
    // or when "conferenceStatus" is overwritten by the feature flag
    const isConferenceAvailable =
        conferenceStatus === availableStatus || (enableTransferToBusyAgents && conferenceStatus !== availableStatus);
    const isProgressiveDialerMode = status === PROGRESSIVE_DIALER_AGENT_STATUS_NAME;

    return isConferenceAvailable && !isProgressiveDialerMode ? AgentAvailability.Available : AgentAvailability.Busy;
};

/*

*/
