import { initializeAudioElement } from "Components/elements/UserNotifier/helpers/audio.helpers";
import { initSSE } from "Services/server-sent-events";

import { setBrandData } from "./BrandProvider";
import { selectBrand, selectBrandMessagingConfig } from "./selectors";

import type { RootState, ThunkType, RVDispatch } from "../Storage";
import type { Brand } from "Types/Brand";

function initializeAudioNotificationFiles(state: RootState) {
    const audioNotificationsByEvent = selectBrandMessagingConfig(state)?.appNotifications?.events;
    if (typeof audioNotificationsByEvent === "object") {
        (Object.keys(audioNotificationsByEvent) as Array<keyof typeof audioNotificationsByEvent>).forEach((event) => {
            const audioNotificationsByTaskTitle = audioNotificationsByEvent[event];
            if (typeof audioNotificationsByTaskTitle === "object") {
                Object.entries(audioNotificationsByTaskTitle).forEach(([taskTitle, audio]) => {
                    const { url, loop, attribute } = audio;
                    if (url) {
                        initializeAudioElement(
                            url,
                            !!loop,
                            {
                                reason: "Messaging config set in redux, so initializing audio notification URL",
                                taskTitle,
                            },
                            attribute === "autoAnswer" // autoAnswer tasks should play their audio through so it's not cut short
                        );
                    }
                });
            }
        });
    }
}

export function updateBrand(brand: Brand): ThunkType {
    return function thunk(dispatch: RVDispatch, getState: () => RootState): void {
        // getCurrentBrand graphql query returns different data than authenticateBrand()
        const mergedBrand = { ...selectBrand(getState()), ...brand };
        dispatch(setBrandData(mergedBrand));

        initSSE();

        initializeAudioNotificationFiles(getState());
    };
}
