/* eslint-disable no-console */
import { ReactNode } from "react";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { message } from "antd";
import { ConfigOnClose } from "antd/lib/message";
import { isEmpty, isString } from "lodash";

export type LoggerFn = (message: string, extraData?: Record<string, any>) => void;
type ErrorFn = (message: string | Error, extraData?: Record<string, any>) => void;
export type LogLevel = "debug" | "log" | "warn" | "error" | "action";
export type ErrorLogger = Record<"error", ErrorFn>;

export function getLogger(context: string): Record<LogLevel, LoggerFn> & { error: ErrorFn } {
    function getConsoleParams(
        message: string | Error,
        extraData: Record<string, any>
    ): [string | Error, Record<string, any>] | [string | Error] {
        if (isEmpty(extraData)) {
            return [message];
        }

        return [message, extraData];
    }

    return {
        debug(message: string, extraData: Record<string, any> = {}) {
            console.debug(...getConsoleParams(message, extraData));
            datadogLogs.logger.debug(message, { ...extraData, context });
        },
        log(message: string, extraData: Record<string, any> = {}) {
            console.log(...getConsoleParams(message, extraData));
            datadogLogs.logger.log(message, { ...extraData, context });
        },
        warn(message: string, extraData: Record<string, any> = {}) {
            console.warn(...getConsoleParams(message, extraData));
            datadogRum.addAction(message, { ...extraData, context, level: "warn" });
            datadogLogs.logger.warn(message, { ...extraData, context });
        },
        action(message: string, extraData: Record<string, any> = {}) {
            datadogRum.addAction(message, { ...extraData, context });
        },
        error(msg: string | Error, extraData: Record<string, any> = {}) {
            console.error(...getConsoleParams(msg, extraData));

            if (isString(msg)) {
                const err = new Error(msg);
                Error.captureStackTrace(err, this.error);
                datadogRum.addError(err, { ...extraData, context });
                // error.toString() returns 'error.name: error.message'.
                // add error to context
                datadogLogs.logger.error(err.toString(), { ...extraData, context, error: err });
            } else {
                datadogRum.addError(msg, { ...extraData, context });
                datadogLogs.logger.error(msg.toString(), { ...extraData, context, error: msg });
            }
        },
    };
}

export function addLoggerGlobalContext(key: string, value: string | undefined): void {
    datadogLogs.addLoggerGlobalContext(key, value);
}

export function normalizeError(err: typeof Error | string | unknown) {
    return err instanceof Error ? err : new Error(String(err));
}

export type ErrorMessageOpts = {
    content: string | Error; // what end user will see
    error?: Error;
    duration?: number | undefined;
    onClose?: ConfigOnClose | undefined;
    extraData?: Record<string, any>;
    loggerContext?: string;
    logger?: ErrorLogger;
    customNode?: ReactNode;
    destroyOnClick?: boolean;
};

export function renderErrorMessage({
    content,
    error,
    duration,
    onClose,
    extraData,
    loggerContext,
    logger,
    customNode,
    destroyOnClick = false,
}: ErrorMessageOpts): void {
    logger = logger || getLogger(loggerContext || "Visual Error");
    logger.error(error || content, { ...extraData, ...(error && content ? { visualDisplay: content } : {}) });
    let contentToDisplay: ReactNode | string;
    if (customNode) {
        contentToDisplay = customNode;
    } else if (isString(content)) {
        contentToDisplay = content;
    } else if (content?.message) {
        contentToDisplay = content.message;
    } else {
        contentToDisplay = "There was an error";
    }
    const key = Math.random();
    message.error({
        key,
        content: contentToDisplay,
        duration,
        onClose,
        onClick: () => {
            if (destroyOnClick) {
                message.destroy(key);
            }
        },
    });
}
