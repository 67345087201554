import dayjs from "dayjs";

import type { Message as IMessage } from "@regal-voice/shared-types";
import type { Message } from "@twilio/conversations";

export type DeserializedMessage = Message & {
    timestamp: string;
    dateUpdated: string;
};

// import type { Message } from "@twilio/conversations";
// the type is Message, but that thorws an error complaining about
// private state. Using any works.
export const deserializeConversationMessage = (m: any): DeserializedMessage => {
    return {
        ...m.state,
        timestamp: m.state.timestamp.toISOString(),
        dateUpdated: m.state.dateUpdated.toISOString(),
    };
};

// this function takes a type that is the output of deserializeConversationMessage
export const formatConversationMessage = (m: DeserializedMessage): Partial<IMessage> => {
    // @ts-expect-error direction is a property of attributes
    const direction = m.attributes?.direction ?? "inbound";
    return {
        // @ts-expect-error contactPhone is a property of the message
        contactPhone: m.author,
        content: m.body ?? undefined, // Message does not expect null content
        direction: direction.toUpperCase(),
        eventType: "proxy_message_event",
        smsMessageSid: m.sid,
        // @ts-expect-error name is a property of attributes
        agentFullname: direction === "outbound" ? m.attributes?.name : null,
        createdAt: dayjs(m.dateUpdated).unix(),
        status: "sent",
        rvInfo: m.sid,
        ...(m.media
            ? {
                  mediaUrl: m.media.getContentTemporaryUrl(),
                  mediaType: m.media.contentType,
              }
            : {}),
        // None of these props were part of the original logic from the snippet.
        // but are all part of the IMessage type.
        // https://github.com/Regal-Voice/regal-voice-ui/blob/main/src/Pages/agentDesktop/agentInterface/ActivityView/hooks/useActiveMessages.ts#L44C15-L44C29
        // success: true,
        // sessionSid: m.sid,
        // campaignName: m.attributes?.campaignName,
        // campaignFriendlyId: m.attributes?.campaignFriendlyId,
        // regalVoicePhone: m.attributes?.regalVoicePhone,
    };
};
