import { useNotifications } from "Services/notifications";

import { useMonitorActiveConversations } from "./hooks/useMonitorActiveConversations/useMonitorActiveConversations";
import { useTaskSpecificAudio } from "./hooks/useTaskSpecificAudio";

export function UserNotifier(): JSX.Element {
    const { play: playTaskAudio } = useTaskSpecificAudio();
    useMonitorActiveConversations({ playTaskAudio });
    useNotifications();
    return <></>;
}
