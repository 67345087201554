import { getLogger, type LogLevel as LogFnName } from "Services/LoggingService";

export const TWILIO_LOG_LEVEL = {
    SILENT: 5,
    ERROR: 4,
    WARN: 3,
    INFO: 2,
    DEBUG: 1,
    TRACE: 0,
};

type TwilioLogLevels = typeof TWILIO_LOG_LEVEL;
export type TwilioLogLevel = keyof TwilioLogLevels;
export type TwilioLogLevelCode = TwilioLogLevels[keyof TwilioLogLevels];

type LogContext = Record<string, unknown>;
type LogContextLike = LogContext | string;

export const logger = getLogger("Twilio Device");

/**
 * This custom logger allow us to intercept the Twilio device logs and send them to our logger (DD).
 */
export const deviceLogger = {
    level: TWILIO_LOG_LEVEL.SILENT as TwilioLogLevelCode,
    setDefaultLevel: (level: TwilioLogLevel | TwilioLogLevelCode) => {
        let codeLevel: TwilioLogLevelCode;
        if (typeof level === "string") {
            codeLevel = TWILIO_LOG_LEVEL[level];
        } else {
            codeLevel = level;
        }

        deviceLogger.level = codeLevel;
    },

    trace: (message: string, context?: LogContextLike) => {
        /**
         * The reason we are using `log` instead of `trace` or `debug` is because even if we
         * specify the device log level to allow `trace` or `debug` logs, we would also need to
         * change the log level of our logger to allow those messages to be sent, and that could
         * lead to other `debug`/`trace` messages being sent.
         */
        deviceLogger.logIfLevel(TWILIO_LOG_LEVEL.TRACE, "log", `TRACE: ${message}`, context);
    },
    debug: (message: string, context?: LogContextLike) => {
        deviceLogger.logIfLevel(TWILIO_LOG_LEVEL.DEBUG, "log", `DEBUG: ${message}`, context);
    },

    info: (message: string, context?: LogContextLike) => {
        deviceLogger.logIfLevel(TWILIO_LOG_LEVEL.INFO, "log", `INFO: ${message}`, context);
    },

    warn: (message: string, context?: LogContextLike) => {
        deviceLogger.logIfLevel(TWILIO_LOG_LEVEL.WARN, "warn", `WARN: ${message}`, context);
    },

    error: (message: string, context?: LogContextLike) => {
        deviceLogger.logIfLevel(TWILIO_LOG_LEVEL.ERROR, "error", `ERROR: ${message}`, context);
    },

    logIfLevel: (level: TwilioLogLevelCode, fn: LogFnName, message: string, context?: LogContextLike) => {
        if (deviceLogger.level <= level) {
            let twilioContext: LogContext;
            if (typeof context === "string") {
                try {
                    twilioContext = JSON.parse(context);
                } catch {
                    twilioContext = {
                        message: context,
                    };
                }
            } else {
                twilioContext = context || {};
            }

            logger[fn](message, { twilioContext });
        }
    },
};
