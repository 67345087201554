import { useCallback, useEffect, useRef, useState } from "react";

import { NullOr } from "@regal-voice/shared-types";
import { useSelector } from "react-redux";

import {
    setUserInput,
    clearUserInput,
    resetUserInput,
    InputPersistState,
    InputPersistNamespace,
    DataByNamespace,
} from "Services/state/input-persist/IndexProvider";
import { SelectRootInputPersistState } from "Services/state/input-persist/selectors";
import { useRVDispatch as useDispatch } from "Services/state/Storage";

export default function usePersistUserInput<N extends InputPersistNamespace, V extends DataByNamespace[N]>({
    namespace,
    key,
    defaultValue,
    eager = false,
}: {
    namespace: N;
    key: string;
    defaultValue: V;
    eager?: boolean;
}): {
    value: DataByNamespace[N];
    setValue: (input: DataByNamespace[N]) => void;
    setPersistedInput: (input: DataByNamespace[N]) => void;
    clearPersistedInput: () => void;
    resetPersistedInput: () => void;
} {
    const inputPersistState = useSelector(SelectRootInputPersistState);

    const initialValue = (key && namespace && inputPersistState[namespace]?.[key]) || defaultValue;
    const [value, setValue] = useState<DataByNamespace[N]>(initialValue as V);
    // this is weird, but necessary so that the useEffect behaves like componentWillUnmount and still captures the real value
    const valueRef = useRef<NullOr<DataByNamespace[N]>>(null);

    const { values, setPersistedInput, clearPersistedInput, resetPersistedInput } = useManagePersistedUserInput({
        namespace,
    });

    const wrappedSetPersistedInput = useCallback(
        (input: DataByNamespace[N]) => {
            key && setPersistedInput(key, input);
        },
        [key, setPersistedInput]
    );

    const wrappedClearPersistedInput = useCallback(() => {
        key && clearPersistedInput(key);
    }, [clearPersistedInput, key]);

    useEffect(() => {
        if (key && eager && valueRef.current) {
            setPersistedInput(key, valueRef.current);
        }

        return () => {
            if (key && valueRef.current != null) {
                setPersistedInput(key, valueRef.current);
                valueRef.current = null;
            }
        };
        // only care if the key or if eager changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, eager]);

    useEffect(() => {
        if (key) {
            setValue(values?.[key] as V);
        }
    }, [key, values]);

    useEffect(() => {
        valueRef.current = value;
    }, [value]);

    return {
        value,
        setValue,
        setPersistedInput: wrappedSetPersistedInput,
        clearPersistedInput: wrappedClearPersistedInput,
        resetPersistedInput,
    };
}

export function useManagePersistedUserInput<N extends InputPersistNamespace>({
    namespace,
}: {
    namespace: N;
}): {
    values: InputPersistState[N];
    setPersistedInput: (key: string, input: DataByNamespace[N]) => void;
    clearPersistedInput: (key: string) => void;
    resetPersistedInput: () => void;
} {
    const values = useSelector(SelectRootInputPersistState)[namespace];

    const dispatch = useDispatch();

    const clearPersistedInput = useCallback(
        (key: string) => {
            dispatch(clearUserInput({ namespace, key }));
        },
        [dispatch, namespace]
    );

    const resetPersistedInput = useCallback(() => {
        dispatch(resetUserInput({ namespace }));
    }, [dispatch, namespace]);

    const setPersistedInput = useCallback(
        (key: string, input: DataByNamespace[N]) => {
            dispatch(
                setUserInput({
                    namespace,
                    key,
                    value: input,
                })
            );
        },
        [dispatch, namespace]
    );

    return { values, setPersistedInput, clearPersistedInput, resetPersistedInput };
}
