import { gql } from "@apollo/client";

export const getTaskOutcomes = gql`
    query getTaskOutcomes($outcomeType: String!) {
        getTaskOutcomes(outcomeType: $outcomeType) {
            id
            outcomeType
            text
            description
            conversationHappened
            channelVoice
            channelSms
            isTag
            isSystem
            campaignOnly
        }
    }
`;
