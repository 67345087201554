import { useEffect } from "react";

import { useSelector } from "react-redux";

import { useFlags } from "Services/FeatureFlagService";
import { selectActiveCallSid } from "Services/state/conferences/ConferenceStateSlice";
import { selectActiveWarnings, type NetworkQualityWarning } from "Services/state/conferences/NetworkQualitySlice";

import { networkQualityLogger } from "../CallBar.utils";

export type UseNetworkQualityStatusResult = {
    warnings: NetworkQualityWarning[];
    showBanner: boolean;
};

export const useNetworkQualityStatus = (): UseNetworkQualityStatusResult => {
    const callSid = useSelector(selectActiveCallSid);
    const activeWarnings = useSelector(selectActiveWarnings);
    const { networkQualityMonitor } = useFlags();

    useEffect(() => {
        if (activeWarnings.length > 0) {
            networkQualityLogger.log("Network quality monitor alert", {
                callSid,
                activeWarnings,
                networkQualityMonitor,
            });
        } else {
            networkQualityLogger.log("Network quality monitor cleared", {
                callSid,
                networkQualityMonitor,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to trigger when there's a change in warnings or the flag.
    }, [callSid, networkQualityMonitor, activeWarnings.length]);

    return {
        warnings: activeWarnings,
        showBanner: activeWarnings.length > 0 && networkQualityMonitor,
    };
};
