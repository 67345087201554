import { message } from "antd";

export function isInteractionRequiredError(error: Error) {
    return /failed because the user didn't interact with the document/.test(error.message);
}

export function showInteractionRequiredMessage(content: string, onClear: () => void): void {
    message.warn({
        key: "audio-error",
        content,
        duration: 0,
    });

    const clear = () => {
        message.destroy("audio-error");
        document.removeEventListener("click", clear);
        onClear();
    };
    document.addEventListener("click", clear);
}
