import { formatToE164 } from "Services/CommunicationService";

import type { Brand } from "Types/Brand";

export function brandMapper(
    data: Brand & { default_from_number?: string; account_sid?: string; service_url?: string }
): Brand {
    const mapped = { ...data };

    if (mapped.communicationSources?.length) {
        mapped.communicationSources = mapped.communicationSources.map((item) => ({
            ...item,
            source: item.source ? formatToE164(String(item.source)) : item.source,
        }));
    }

    if (mapped.defaultFromNumber) {
        mapped.defaultFromNumber = formatToE164(String(mapped.defaultFromNumber));
    }

    return mapped;
}
