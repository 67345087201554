import { useCallback, useEffect, useRef } from "react";

import { checkSessionRequest, isClientLoggingOut, kickOut } from "Services/AuthService";
import { getLogger } from "Services/LoggingService";
import { isUnauthenticatedResponse } from "Services/util/NetworkUtilService";
import useEventListener from "Utils/useEventListener/useEventListener";

import { regalAuthProfileExists } from "./RegalAuthContext";

const logger = getLogger("useSessionValidityCheck");

const AUTH_COOKIE_CHECK_INTERVAL = 3000;

/**
 * Upon making the tab visible, it will check if the session is still valid by pinging backend.
 * If invalid, we'll log and kick the user out to login.
 */
export function useSessionValidityCheck() {
    const documentRef = useRef(document);
    const handler = useCallback(async () => {
        if (document.visibilityState === "visible" && !isClientLoggingOut()) {
            // we purposefully not use the fetch ponyfill to handle the unauthenticated scenario differently
            const response = await checkSessionRequest();
            if (isUnauthenticatedResponse(response.status) && !isClientLoggingOut()) {
                logger.log("User's session became invalid, kicking user to login", {
                    statusCode: response.status,
                });
                await kickOut();
            }
        }
    }, []);

    useEventListener("visibilitychange", handler, documentRef);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            // check if auth cookie is there (profile is loaded from cookie)
            if (!regalAuthProfileExists() && !isClientLoggingOut()) {
                logger.log("User's cookie is not present in browser, kicking user to login");
                await kickOut();
            }
        }, AUTH_COOKIE_CHECK_INTERVAL);
        return () => clearInterval(intervalId);
    }, []);
}
