import { createAsyncThunk } from "@reduxjs/toolkit";
import { PartiallyRequired } from "@regal-voice/shared-types";
import { omit } from "lodash";

import { apolloQuery } from "Services/ApolloClientService";
import { getProfilesById } from "Services/marketing-api/profiles/queries";

import { setContact, upsertContacts } from "./ContactsStateProvider";
import { contactsSelectors, selectContactByIdOrPhone } from "./Selectors/Selectors";
import { createContactFromPartial, mergeContact } from "./utils";

import type { RVDispatch, RootReducerShape, RootState } from "../Storage";
import type { Profile } from "Types/Profile";

// We're reviving this prefetching contacts experiment but only for profiles to reduce risk and complexity
export const fetchContactList = createAsyncThunk<
    void,
    string[],
    {
        dispatch: RVDispatch;
        state: RootReducerShape;
    }
>("contacts/fetch", async (profileIds: string[], { dispatch }) => {
    const { data } = await apolloQuery({
        query: getProfilesById,
        variables: { profileIds },
    });
    const profiles = data.getProfilesById;

    if (profiles.length) {
        dispatch(upsertContacts(profiles));
    }
});

export const updateContact = createAsyncThunk<
    void,
    Partial<Profile>,
    {
        dispatch: RVDispatch;
        state: RootReducerShape;
    }
>("contacts/update", (data: Partial<Profile>, { dispatch, getState }) => {
    if (!data?.id && !data?.contactPhone) {
        return;
    }

    const contact = selectContactByIdOrPhone(getState() as RootReducerShape, (data.id || data.contactPhone) as string);
    if (contact) {
        const contactUpdated = mergeContact(contact, omit(data, "contactPhone"));
        dispatch(setContact(contactUpdated));
    }
});

/**
 * Given a list of partial contacts, shalowly updates all existing contacts
 * and adds all others using fallback/default data to flesh out the full Contacts
 */
export const addPartialContacts = createAsyncThunk<
    void,
    PartiallyRequired<Profile, "id">[],
    {
        dispatch: RVDispatch;
        state: RootReducerShape;
    }
>("contacts/addPartialContacts", (contacts: PartiallyRequired<Profile, "id">[], { getState, dispatch }) => {
    const rootState = getState() as RootState;
    const existingEntities = contactsSelectors.selectEntities(rootState);
    const fullContacts = contacts.map((partialContact) => {
        const partialContactId = partialContact.id;
        const existingContact = partialContactId && existingEntities[partialContactId];
        if (existingContact) {
            return { ...existingContact, ...partialContact };
        } else {
            return createContactFromPartial(partialContact);
        }
    });
    dispatch(upsertContacts(fullContacts));
});
