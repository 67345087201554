import { clsx } from "clsx";

import Icon, { IconSizes } from "Components/elements/Icon";
import { RVPopoverWithTracking } from "Components/elements/RVPopoverWithTracking/RVPopoverWithTracking";

import { VoicemailListForm } from "../../../VoicemailListForm/VoicemailListForm";

import type { PrerecordedVoiceMessage } from "Types/Recordings";

import styles from "./VoicemailSettingsPopover.module.scss";

export interface VoicemailSettingsPopoverProps {
    open: boolean;
    campaignId?: string;
    loading: boolean;
    dropActive: boolean;
    disabled: boolean;
    voiceMailOptionData: Array<PrerecordedVoiceMessage>;
    setVmRecordingId: (value: string) => void;
    onVoicemailDrop: () => Promise<void>;
    toggleModal: () => void;
}

export function VoicemailSettingsPopover({
    open,
    campaignId,
    onVoicemailDrop,
    voiceMailOptionData,
    setVmRecordingId,
    loading,
    dropActive,
    disabled,
    toggleModal,
}: VoicemailSettingsPopoverProps): JSX.Element {
    // only display the disabled "button"
    if (disabled) {
        return (
            <div className={clsx([styles.buttonContainer, styles.disabled])} onClick={toggleModal}>
                <div className={styles.iconContainer}>
                    <Icon
                        icon={open && !disabled ? "arrow-down" : "arrow-up"}
                        size={IconSizes.XSMALL}
                        className={clsx([styles.icon, styles.disabled])}
                    />
                </div>
            </div>
        );
    }

    return (
        <RVPopoverWithTracking
            overlayClassName={styles.popoverFix}
            id="transfers-popover"
            open={open}
            trigger="click"
            placement="top"
            content={
                <VoicemailListForm
                    campaignId={campaignId}
                    loading={loading}
                    voiceMailOptionData={voiceMailOptionData}
                    dropVoicemail={onVoicemailDrop}
                    setVmRecordingId={setVmRecordingId}
                    isDroppingVoicemail={dropActive}
                />
            }
        >
            <div
                className={clsx(styles.buttonContainer, {
                    [styles.active]: open,
                })}
                onClick={toggleModal}
            >
                <div className={styles.iconContainer}>
                    <Icon
                        icon={open ? "arrow-down" : "arrow-up"}
                        size={IconSizes.XSMALL}
                        className={clsx(styles.icon, {
                            [styles.open]: open,
                        })}
                    />
                </div>
            </div>
        </RVPopoverWithTracking>
    );
}
