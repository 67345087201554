import { useEffect } from "react";

import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

import { hardRedirect } from "App/routes/routes";
import { logoutCleanup, logger as authLogger } from "Services/AuthService";
import { selectOnActiveCall } from "Services/state/agent/AgentInformationSlice";

import { loadRegalAuthProfile } from "./RegalAuthContext";

const debouncedLoadRegalAuthProfile = debounce(() => loadRegalAuthProfile(), 500, { leading: true });

type SecureRouteProps = React.ComponentProps<typeof Route>;
export default function SecureRoute(props: SecureRouteProps) {
    const regalAuthProfile = debouncedLoadRegalAuthProfile();
    const onActiveCall = useSelector(selectOnActiveCall);

    // wrap in a useEffect so that dont cause a re-render with the cleanup
    useEffect(() => {
        // we don't control the session so we need to make sure that we are respecting it.
        // if our cookie expires, then on the next navigation we need redirect to login.
        if (!regalAuthProfile?.user?.oktaUserId) {
            authLogger.log("Logging out when navigating without a cookie", {
                didManuallyLogOut: false,
                onActiveCall,
            });
            logoutCleanup();
            hardRedirect("/login");
        }
    }, [onActiveCall, regalAuthProfile?.user?.oktaUserId]);

    // if we dont have a user, render nothing so we dont see a flash of the page
    if (!regalAuthProfile?.user?.oktaUserId) {
        return null;
    }

    return <Route {...props} />;
}
