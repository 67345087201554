import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../Storage";

type UserState = {
    loginBeganEmail?: string;
    didManuallyLogOut: boolean;
};

const initialState: UserState = {
    didManuallyLogOut: false,
};

const UserSlice = createSlice({
    name: "userInformation",
    initialState,
    reducers: {
        beginLogin: (state, action: PayloadAction<string>) => {
            state.loginBeganEmail = action.payload;
        },
        completeLogin: () => {
            return initialState;
        },
        setDidManuallyLogOut: (state, action: PayloadAction<boolean>) => {
            state.didManuallyLogOut = action.payload;
        },
    },
});

export const selectLoginBeganEmail = (state: RootState) =>
    (state.userInformation as UserState).loginBeganEmail as string;
export const selectDidManuallyLogOut = (state: RootState): boolean => state.userInformation.didManuallyLogOut;

export const { beginLogin, completeLogin, setDidManuallyLogOut } = UserSlice.actions;
export default UserSlice.reducer;
