import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

import { BusinessProfile } from "Types/BusinessProfile";

export const businessProfilesAdapter = createEntityAdapter<BusinessProfile>({
    selectId: (businessProfile) => businessProfile.id,
});

const BusinessProfileStateSlice = createSlice({
    name: "BusinessProfile",
    initialState: businessProfilesAdapter.getInitialState(),
    reducers: {
        setBusinessProfiles: businessProfilesAdapter.setAll,
    },
});

export const { setBusinessProfiles } = BusinessProfileStateSlice.actions;
export default BusinessProfileStateSlice.reducer;
