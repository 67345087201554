import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UnreadSmsInfo {
    count: number;
    mostRecentSmsMessageSid: string;
}
type UnreadSms = Record<string, UnreadSmsInfo>;
interface UnreadConversations {
    sms: UnreadSms;
}
interface UnreadConversationPayload {
    conversationSid: string;
}
interface IncrementSmsCountPayload extends UnreadConversationPayload {
    smsMessageSid: string; // to increment only once
}

const initialState: UnreadConversations = { sms: {} };
const UnreadConversationsStateSlice = createSlice({
    name: "UnreadMessagesState",
    initialState,
    reducers: {
        resetUnreadSms(
            state: UnreadConversations,
            action: PayloadAction<UnreadConversationPayload>
        ): UnreadConversations {
            delete state.sms[action.payload.conversationSid];
            return state;
        },
        incrementUnreadSms(
            state: UnreadConversations,
            action: PayloadAction<IncrementSmsCountPayload>
        ): UnreadConversations {
            if (!state.sms.hasOwnProperty(action.payload.conversationSid)) {
                return {
                    ...state,
                    sms: {
                        ...state.sms,
                        [action.payload.conversationSid]: {
                            count: 1,
                            mostRecentSmsMessageSid: action.payload.smsMessageSid,
                        },
                    },
                };
            }
            if (state.sms[action.payload.conversationSid].mostRecentSmsMessageSid !== action.payload.smsMessageSid) {
                // increment only for unique smsMessageSid
                return {
                    ...state,
                    sms: {
                        ...state.sms,
                        [action.payload.conversationSid]: {
                            ...state.sms[action.payload.conversationSid],
                            count: state.sms[action.payload.conversationSid].count + 1,
                            mostRecentSmsMessageSid: action.payload.smsMessageSid,
                        },
                    },
                };
            }
            return state;
        },
        resetAllUnreadConversations(): UnreadConversations {
            return initialState;
        },
    },
});

export const { resetAllUnreadConversations, incrementUnreadSms, resetUnreadSms } =
    UnreadConversationsStateSlice.actions;
export default UnreadConversationsStateSlice.reducer;
