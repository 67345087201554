import { CallBarLine } from "../CallBarLine";
import { CallTaskInfo } from "../CallTaskInfo";

import styles from "./CallInfoDetail.module.scss";

export const CallInfoDetail = (): JSX.Element => {
    return (
        <div className={styles.container} data-testid="call-info-detail">
            <div className={styles.taskInfo}>
                <CallTaskInfo updateDuration={false} />
            </div>
            <div className={styles.lineInfo}>
                <CallBarLine />
            </div>
        </div>
    );
};
