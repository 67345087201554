import { useMemo } from "react";

import { useSelector } from "react-redux";

import { selectWorkerUri } from "Services/state/agent/AgentInformationSlice";
import { selectConference } from "Services/state/conferences";
import { CallStatusEvent } from "Services/Task.service";

export type UseBarActionsStateResult = {
    disableIndependentActions: boolean;
    disableActions: boolean;
};

export const useBarActionsState = (): UseBarActionsStateResult => {
    const workerUri = useSelector(selectWorkerUri);
    const { deviceCallSid, participants } = useSelector(selectConference);
    return useMemo(() => {
        const result = {
            disableIndependentActions: false,
            disableActions: false,
        };
        if (!participants) {
            return result;
        }

        const participantsList = Object.values(participants);
        const participantsJoined = participantsList.some(({ status }) => status == CallStatusEvent.IN_PROGRESS);
        result.disableActions = !participantsJoined && !deviceCallSid;

        result.disableIndependentActions =
            workerUri !== "" &&
            participantsList.filter(
                ({ phoneNumber, managerAction }) => phoneNumber !== `client:${workerUri}` && managerAction !== "listen"
            ).length >= 2;

        return result;
    }, [participants, deviceCallSid, workerUri]);
};
