import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

export type TaskToAutocompleteSettings = {
    startTimerAt: number;
    autocompleteAt: number;
    pendingRemoval?: boolean;
};

export type TasksToAutocompleteState = {
    tasks: Record<string, TaskToAutocompleteSettings>;
};

const initialState: TasksToAutocompleteState = {
    tasks: {},
};

const timers: Record<string, NodeJS.Timeout> = {};

const tasksToAutocompleteSlice = createSlice({
    name: "tasksToAutocomplete",
    initialState,
    reducers: {
        setTaskToAutocomplete: (
            state,
            { payload: { taskSid, ...options } }: PayloadAction<{ taskSid: string } & TaskToAutocompleteSettings>
        ) => {
            state.tasks[taskSid] = options;
        },
        clearTaskFromAutocomplete: (state, { payload: { taskSid } }: PayloadAction<{ taskSid: string }>) => {
            delete state.tasks[taskSid];
        },
        prepareTaskForRemoval: (state, { payload: { taskSid } }: PayloadAction<{ taskSid: string }>) => {
            if (state.tasks[taskSid]) {
                state.tasks[taskSid].pendingRemoval = true;
            }
        },
    },
});

export default tasksToAutocompleteSlice.reducer;
export const { clearTaskFromAutocomplete } = tasksToAutocompleteSlice.actions;

const { setTaskToAutocomplete, prepareTaskForRemoval } = tasksToAutocompleteSlice.actions;

type UpdateAutocompletePayload = {
    taskSid: string;
} & (
    | {
          clear: true;
      }
    | {
          autocompleteStartTime: number;
          autocompleteCountdownTimeSeconds: number;
      }
);

export const updateAutocomplete = createAsyncThunk<void, UpdateAutocompletePayload>(
    "autocomplete/update",
    ({ taskSid, ...options }, { dispatch }) => {
        if (timers[taskSid]) {
            clearTimeout(timers[taskSid]);
        }
        if ("clear" in options) {
            dispatch(clearTaskFromAutocomplete({ taskSid }));
            delete timers[taskSid];
        } else {
            const now = new Date().getTime();
            const { autocompleteStartTime: startTimerAt, autocompleteCountdownTimeSeconds: countdown } = options;
            const autocompleteAt = startTimerAt + countdown;
            const timeoutAt = autocompleteAt - now;
            dispatch(setTaskToAutocomplete({ taskSid, startTimerAt, autocompleteAt }));
            timers[taskSid] = setTimeout(() => {
                dispatch(prepareTaskForRemoval({ taskSid }));
            }, timeoutAt);
        }
    }
);
