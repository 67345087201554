import { message } from "antd";

// the default duration is 3
// node_modules/antd/lib/message/index.js
const TOAST_DISPLAY_LENGTH_DEFAULT_SEC = 3;
export const TOAST_DISPLAY_LENGTH_MAX_SEC = 10;
export const TOAST_DISPLAY_LENGTH_MED_SEC = 6;

export const FailedCallMessages = {
    blocked: {
        messageDisplay: "This call was blocked by a Gryphon do-not-call list.",
        duration: TOAST_DISPLAY_LENGTH_MAX_SEC,
    },
    failed: {
        messageDisplay: "Contact phone number is unreachable.",
        duration: TOAST_DISPLAY_LENGTH_DEFAULT_SEC,
    },
    notAllowed: {
        messageDisplay: "Contact phone number is blocked or malformed.",
        duration: TOAST_DISPLAY_LENGTH_DEFAULT_SEC,
    },
    notAuthorized: {
        messageDisplay: "Contact phone number is in a geography not covered in your account.",
        duration: TOAST_DISPLAY_LENGTH_DEFAULT_SEC,
    },
    busy: {
        messageDisplay: "Contact phone number is busy",
        duration: TOAST_DISPLAY_LENGTH_DEFAULT_SEC,
    },
    invalid: {
        messageDisplay: "Contact phone number is invalid",
        duration: TOAST_DISPLAY_LENGTH_MAX_SEC,
    },
} as const;

export type FailStatus = keyof typeof FailedCallMessages;

export function showFailedCallToast(status: FailStatus): void {
    const { messageDisplay, duration } = FailedCallMessages[status];
    message.info(`Call failed to connect. ${messageDisplay}`, duration);
}
