import { gql } from "@apollo/client";

export const getTransferPhoneNumbers = gql`
    query GetTransferPhoneNumbers($queryParams: QueryParams) {
        getTransferPhoneNumbers(queryParams: $queryParams, isManagerContext: true) {
            id
            name
            phoneNumber
            teams {
                id
                name
            }
            createdBy
            createdAt
            createdBy
            updatedAt
            updatedBy
        }
    }
`;

export const getTransferPhoneNumbersForTeams = gql`
    query GetTransferPhoneNumbers($queryParams: QueryParams) {
        getTransferPhoneNumbers(queryParams: $queryParams) {
            id
            name
            phoneNumber
        }
    }
`;
