import { useCallback } from "react";

import { useSelector } from "react-redux";

import { manageRecording } from "Services/ConversationsApiService";
import { setConferenceRecording } from "Services/state/conferences";
import { selectConference } from "Services/state/conferences/Selectors";
import { useRVDispatch } from "Services/state/Storage";

import { logger } from "../CallBar.utils";

export type UseRecordCallResult = [isRecording: boolean, toggleRecording: () => void];

export const useRecordCall = (): UseRecordCallResult => {
    const dispatch = useRVDispatch();
    const { isRecording = false, conferenceSid, deviceCallSid, conferenceFriendlyId } = useSelector(selectConference);
    const toggleRecording = useCallback(() => {
        if (!deviceCallSid || !conferenceFriendlyId) {
            logger.error("Tried to toggle recording but no active call found", {
                deviceCallSid,
                conferenceSid,
                conferenceFriendlyId,
            });

            return;
        }

        const [nextStatus, nextState] = isRecording ? (["paused", false] as const) : (["in-progress", true] as const);

        return manageRecording({
            callSid: deviceCallSid,
            status: nextStatus,
            conferenceSid,
            conferenceFriendlyId,
        }).then(() => dispatch(setConferenceRecording({ isRecording: nextState })));
    }, [isRecording, dispatch, deviceCallSid, conferenceSid, conferenceFriendlyId]);

    return [isRecording, toggleRecording];
};
