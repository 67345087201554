import { useCollapsiblePanel } from "./CollapsiblePanelsContext";

import styles from "./CollapsiblePanel.module.scss";

export type CollapsiblePanelProps = React.PropsWithChildren<{
    panelName: string;
    expandedWidth: number;
}>;

export function CollapsiblePanel({ panelName, expandedWidth, children }: CollapsiblePanelProps) {
    const { collapsed } = useCollapsiblePanel(panelName);
    const width = collapsed ? 0 : expandedWidth;
    const styleProps = { width, minWidth: width };

    return (
        <div className={styles.panel} style={styleProps}>
            <div style={styleProps}>{children}</div>
        </div>
    );
}
