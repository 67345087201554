import { useState, useCallback } from "react";

import { message, Tooltip } from "antd";
import { clsx } from "clsx";

import { RVButton, type RVButtonProps, clsRaisedButton } from "Components/elements/RVButton/RVButton";

import type { CallBarButtonMessagesFactory } from "../CallBar.consts";

import styles from "../CallBar.module.scss";

type CallBarButtonBaseProps = {
    id: string;
    active: boolean;
    // currently only hangUpCall uses the boolean argument
    // think about removing it and updating hangUpCall
    action?: (isActive?: boolean) => void | Promise<void | unknown>;
    icon: string | JSX.Element;
    activeIcon?: string | JSX.Element;
    messages?: CallBarButtonMessagesFactory;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    popover?: boolean;
};

type RVButtonExtraProps = Omit<RVButtonProps, keyof CallBarButtonBaseProps>;

export type CallBarButtonProps = CallBarButtonBaseProps & Partial<RVButtonExtraProps>;

export const CallBarButton = ({
    id,
    active,
    action,
    icon,
    messages,
    activeIcon,
    disabled,
    className,
    popover = false,
    loading: parentLoading,
    ...buttonProps
}: CallBarButtonProps): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const execute = useCallback(async () => {
        if (!action) {
            return;
        }

        try {
            setLoading(true);
            await action(active);
            if (messages?.success) {
                message.success(messages.success(!active));
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            const messageText = messages?.error ? messages.error(errorMessage, active) : errorMessage;
            message.error(messageText);
        } finally {
            setLoading(false);
        }
    }, [action, active, messages]);

    const iconNode = active ? activeIcon || icon : icon;
    const buttonNode = (
        <RVButton
            data-dd-action-name={`call bar: ${id}`}
            data-testid={`call-bar-button-${id}`}
            data-active={active}
            icon={iconNode}
            shape="circle"
            size="middle"
            onClick={execute}
            className={clsx(clsRaisedButton, styles.button, className, {
                [styles.active]: active,
            })}
            disabled={disabled || loading}
            loading={parentLoading || loading}
            {...buttonProps}
        />
    );

    if (!messages || (popover && active)) {
        return buttonNode;
    }

    return (
        <Tooltip title={messages.tooltip(active)} placement="top">
            {buttonNode}
        </Tooltip>
    );
};
