import { useState } from "react";

import { clsx } from "clsx";

import RejectTaskOptions from "Pages/agentDesktop/agentInterface/shared/RejectTaskOptions/RejectTaskOptions";
import { useFlags } from "Services/FeatureFlagService";

import { TaskCancelation } from "../../TaskOutcomes/TaskCancelation";
import { BUTTONS_MESSAGES } from "../CallBar.consts";
import { CallBarButton } from "./CallBarButton";

import type { RVTask } from "@regal-voice/shared-types";

import styles from "../CallBar.module.scss";

export type IncomingCallBarProps = {
    activeTask?: RVTask;
    acceptingCall: boolean;
    acceptActiveTask: () => void;
};

export function IncomingCallBarButtons({
    activeTask,
    acceptActiveTask,
    acceptingCall,
}: IncomingCallBarProps): JSX.Element {
    const { enableAgentToReassignTask } = useFlags();

    const [rejectLoading, setRejectLoading] = useState(false);

    return (
        <ul className={clsx(styles.buttonsList, "rv-call-bar-button-list")}>
            <li key="accept">
                <CallBarButton
                    id="accept"
                    active={false}
                    action={acceptActiveTask}
                    icon="accept"
                    messages={BUTTONS_MESSAGES.accept}
                    className={styles.accept}
                    loading={acceptingCall}
                />
            </li>
            <li key="reject">
                {enableAgentToReassignTask ? (
                    <TaskCancelation task={activeTask} asPopup renderTargetClass="reject" />
                ) : (
                    <RejectTaskOptions
                        onLoadStateChange={setRejectLoading}
                        task={activeTask}
                        dropdownPlacement="top"
                        tooltipPlacement="top"
                    >
                        <CallBarButton
                            id="reject"
                            active={false}
                            icon="close"
                            className={styles.reject}
                            loading={rejectLoading}
                        />
                    </RejectTaskOptions>
                )}
            </li>
        </ul>
    );
}
