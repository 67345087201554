import { useState, useCallback } from "react";

import { RVPopoverWithTracking } from "Components/elements/RVPopoverWithTracking/RVPopoverWithTracking";

import { TransfersWithParticipants } from "./TransfersWithParticipants/TransfersWithParticipants";

import type { RVTask } from "@regal-voice/shared-types";

export type TransfersPopoverButtonRenderer = (open: boolean) => JSX.Element;

export type TransfersPopoverProps = {
    activeConferenceTask?: RVTask;
    renderButton: TransfersPopoverButtonRenderer;
};

export function TransfersPopover({ activeConferenceTask, renderButton }: TransfersPopoverProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const closeTransfers = useCallback(() => setOpen(false), []);

    return (
        <RVPopoverWithTracking
            id="transfers-popover"
            open={open}
            onOpenChange={setOpen}
            trigger="click"
            placement="top"
            content={
                <TransfersWithParticipants
                    activeConferenceTask={activeConferenceTask}
                    onCloseRequest={closeTransfers}
                />
            }
        >
            {renderButton(open)}
        </RVPopoverWithTracking>
    );
}
