import { useMutation } from "@apollo/client";
import { clsx } from "clsx";

import Icon, { IconVariants, IconSizes } from "Components/elements/Icon";
import { cancelTasks } from "Services/marketing-api/tasks/mutations";
import { ParticipantInfo } from "Services/state/conferences/ConferenceStateSlice";
import { Agent } from "Types/Agent";

import CallActionButton from "../../ActiveCall/CallActionButton/CallActionButton";

import styles from "./PendingParticipant.module.scss";

const messages = {
    hangUp: {
        title: () => "Cancel Transfer",
        error: (err: string) => `An error occured while canceling the call - ${err}`,
    },
};

export const cancelReasons = {
    reason: "cancel_transfer",
    source: "agent_call_panel",
};

export function CallPendingParticipant({
    agents,
    participant,
}: {
    agents: Agent[];
    participant: ParticipantInfo;
}): JSX.Element {
    const [cancelTaskList] = useMutation<{ cancelTasks: string[] }>(cancelTasks);
    async function cancelTransfer() {
        await cancelTaskList({
            variables: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ids: [participant!.taskSid],
                cancelAttributes: cancelReasons,
            },
        });
    }

    function getParticipantName() {
        if (participant.phoneNumber) {
            return agents.find((a) => a.twilioContactUri == participant.phoneNumber)?.name;
        } else if (participant.queueName) {
            return participant.queueName;
        }
    }

    return (
        <div className={clsx(styles.contactInfo, styles.layoutContainer)}>
            <div className={clsx(styles.layoutContainer, styles.contactName)}>
                <Icon
                    icon={participant.queueName ? "queues" : "headset"}
                    variant={IconVariants.CIRCLE}
                    size={IconSizes.LARGE}
                    bgColor="purple-1"
                    color="purple-4"
                    className={styles.externalTransfer}
                />
                <div className={styles.infoContainer}>
                    <div className={clsx(styles.name, "ellipsis")}>
                        <b>{getParticipantName()}</b>
                    </div>
                    <div className={styles.number}>Internal Transfer</div>
                </div>
            </div>
            <div className={clsx(styles.status, "flex-column align-end")}>
                <div>Pending</div>

                <div className={clsx(styles.buttons, "flex-row")}>
                    <CallActionButton
                        callBack={cancelTransfer}
                        icon="call-hang-up-cobra"
                        className={styles.hangUp}
                        messages={messages.hangUp}
                    />
                </div>
            </div>
        </div>
    );
}
