import { useEffect } from "react";

import { notification } from "antd";
import { useLocation } from "react-router";
import { Subject } from "rxjs";
import { v4 as uuidv4 } from "uuid";

import { useFlags } from "Services/FeatureFlagService";
import { getLogger } from "Services/LoggingService";

const logger = getLogger("MultiTabDetection");

const TabEvent = {
    None: "none",
    NewTab: "newTab",
    ExistingTab: "existingTab",
    ClosingTab: "closingTab",
};

function useLocationBasePath(): string {
    // should be returned as `/<route>/<etc>`
    const { pathname } = useLocation();
    const pathComponents = pathname?.split("/");
    return pathComponents?.length > 1 ? pathComponents[1] : "/";
}

type TabEventType = (typeof TabEvent)[keyof typeof TabEvent];

/**
 * TabDetectionObserver - listen for TabDetected events from MultiTabDetection and show notifications
 */
class TabDetectionObserver {
    multiTabDetection: MultiTabDetection;
    enabled: boolean;
    numberOfTabsOpened = 0;
    eventType: TabEventType = TabEvent.None;
    locationBasePath: string;
    // Only show MTD warning in these routes
    locationWhitelist: Array<string> = ["agent"];

    constructor(enabled: boolean, locationBasePath: string) {
        this.enabled = enabled;
        this.locationBasePath = locationBasePath;
        this.multiTabDetection = new MultiTabDetection();
        this.showNotification = this.showNotification.bind(this);
    }

    enableNotifications(enable = false, locationBasePath: string) {
        this.enabled = enable;
        this.locationBasePath = locationBasePath;
        setTimeout(() => this.showNotification(), 100);
    }

    showNotification(): void {
        const { numberOfTabsOpened } = this;

        if (numberOfTabsOpened < 2) {
            logger.action("Multitab - Session in single tab state");
        } else {
            logger.action("Multitab - Session has multiple tabs open");
        }

        if (!this.enabled || !this.locationWhitelist.includes(this.locationBasePath)) {
            // close notification when flag is disabled
            notification.close("mtd");
            return;
        }
        // close previous notifications
        notification.close("mtd");
        if (numberOfTabsOpened < 2) {
            // MultiTabDetection sends closedTab with numberOfTabsOpened = 1
            return;
        }

        notification.warning({
            message: "Warning",
            description: `You currently have Regal open in multiple browser tabs which can cause unexpected results on the Agent Desktop. Please close the other open tabs.`,
            duration: 0, // don't close it automatically
            placement: "topRight",
            top: 80,
            key: "mtd",
        });
    }

    showDelayedNotification(numberOfTabsOpened: number, eventType: TabEventType) {
        this.numberOfTabsOpened = numberOfTabsOpened;
        this.eventType = eventType;
        logger.debug(`mtd:${eventType}, tabs: ${numberOfTabsOpened}`);
        setTimeout(() => this.showNotification(), 1000);
    }

    initializeDetection(): void {
        this.multiTabDetection.existingTabDetectedEvent$.subscribe({
            next: (totalNumberOfTabsOpened) => {
                this.showDelayedNotification(totalNumberOfTabsOpened, TabEvent.ExistingTab);
            },
        });

        this.multiTabDetection.newTabDetectedEvent$.subscribe({
            next: (totalNumberOfTabsOpened) => {
                this.showDelayedNotification(totalNumberOfTabsOpened, TabEvent.NewTab);
            },
        });

        this.multiTabDetection.closedTabDetectedEvent$.subscribe({
            next: (totalNumberOfTabsOpened) => {
                this.showDelayedNotification(totalNumberOfTabsOpened, TabEvent.ClosingTab);
            },
        });
    }
}

/**
 * TabDetection Observable - notify observers when a tab is opened, closed
 * this was copied from https://github.com/uy-andrew/multi-tab-detection/blob/master/src/multi-tab-detection.ts
 */
class MultiTabDetection {
    /**
     * @description Informs the listener that a new tab has been detected for the same browser session.
     * It also pass in the total number of tabs opened for the same browser session.
     */
    public newTabDetectedEvent$: Subject<number> = new Subject<number>();

    /**
     * @description Informs the listener that an existing tab existed for the same browser session.
     */
    public existingTabDetectedEvent$: Subject<number> = new Subject<number>();

    /**
     * @description Informs the listener that a tab for the same browser session has been closed.
     * It also pass in the updated total number of tabs opened for the same browser session.
     */
    public closedTabDetectedEvent$: Subject<number> = new Subject<number>();

    private prefix = "multiTabDetection-";
    private numberOfTabsOpened = 1;
    private initialized = false;
    id: string = uuidv4();
    // Connection to a broadcast channel
    private broadcastChannel: BroadcastChannel;

    constructor() {
        this.broadcastChannel = new BroadcastChannel("tabDetection");
        this.addListener();
        this.setNewTab();
    }

    /**
     * @description Gets the total number of tabs opened. It is recommended to wait for 1 second
     * after receiving existingTabDetectedEvent before calling this property to get the accurate
     * total number of tabs opened.
     * @returns {number}
     */
    public get NumberOfTabsOpened(): number {
        return this.numberOfTabsOpened;
    }

    broadcastChannelMessageHandler = (messageEvent: MessageEvent) => {
        const {
            data: { eventType, from },
        } = messageEvent;
        if (from == this.id) {
            return;
        }
        if (eventType === this.newTabKey) {
            this.initialized = true;
            // i'm receiving this event from another tab
            // start counting tabs from 2 - this tab plus the tab that sent newTab message
            this.numberOfTabsOpened = 2;
            this.newTabDetectedEvent$.next(this.numberOfTabsOpened);
            // notify all tabs about existence of this tab
            this.postMessage(this.existingTabKey);
        } else if (eventType === this.existingTabKey) {
            this.initialized = true;
            this.incrementNumberOfTabsOpened();
            this.existingTabDetectedEvent$.next(this.numberOfTabsOpened);
        } else if (eventType === this.closingTabKey) {
            // i have one tab opened, i clear local sorage
            // and then after i re-open tab i recive 2 times this event
            if (this.initialized) {
                this.decrementNumberOfTabsOpened();
                this.closedTabDetectedEvent$.next(this.numberOfTabsOpened);
            }
        }
    };

    handleUnload = () => {
        this.postMessage(this.closingTabKey);
        this.broadcastChannel.removeEventListener("message", this.broadcastChannelMessageHandler);
        this.broadcastChannel.close();
        window.removeEventListener("beforeunload", this.handleUnload);
    };

    private addListener(): void {
        logger.log(`mtd - id:${this.id}`);
        this.broadcastChannel.addEventListener("message", this.broadcastChannelMessageHandler);
        window.addEventListener("beforeunload", this.handleUnload, false);
    }

    private setNewTab(): void {
        this.postMessage(this.newTabKey);
    }

    // keys
    private get newTabKey(): string {
        return this.createUniqueKey("new-tab");
    }

    private get existingTabKey(): string {
        return this.createUniqueKey("existing-tab");
    }

    private get closingTabKey(): string {
        return this.createUniqueKey("closing-tab");
    }

    // methods to increment/decrement
    private incrementNumberOfTabsOpened() {
        this.numberOfTabsOpened++;
    }

    private decrementNumberOfTabsOpened() {
        if (this.numberOfTabsOpened > 0) {
            this.numberOfTabsOpened--;
        }
    }

    // local storage methods
    private postMessage(eventType: string) {
        this.broadcastChannel.postMessage({ eventType, from: this.id });
    }

    // utility methods
    private createUniqueKey(key: string): string {
        return `${this.prefix}${key}`;
    }
}

let tabDetection: TabDetectionObserver | null = null;

export function getTabDetection() {
    return tabDetection;
}

export function useTabDetection(): void {
    const { multiTabDetection } = useFlags();
    const locationBasePath = useLocationBasePath();

    useEffect(() => {
        if (multiTabDetection && tabDetection == null) {
            tabDetection = new TabDetectionObserver(false, locationBasePath);
            tabDetection.initializeDetection();
        }
        if (tabDetection != null) {
            tabDetection.enableNotifications(multiTabDetection, locationBasePath);
        }
    }, [multiTabDetection, locationBasePath]);
}
