import { useCallback, useMemo } from "react";

import { useSelector } from "react-redux";

import { holdParticipant } from "Services/ConversationsApiService";
import { getLogger } from "Services/LoggingService";
import { selectConference } from "Services/state/conferences";
import { CallStatusEvent } from "Services/Task.service";

const ALLOWED_STATUSES = [CallStatusEvent.IN_PROGRESS, CallStatusEvent.RINGING];
const logger = getLogger("On hold hook");

export type UseHoldCallResult = [onHold: boolean, toggleHold: () => Promise<unknown>];

export const useHoldCall = (): UseHoldCallResult => {
    const { deviceCallSid, participants, conferenceSid, conferenceFriendlyId } = useSelector(selectConference);
    const participant = useMemo(() => {
        if (!participants) {
            return;
        }

        return Object.values(participants).find(
            ({ callSid, managerAction, status }) =>
                ALLOWED_STATUSES.includes(status as CallStatusEvent) && callSid !== deviceCallSid && !managerAction
        );
    }, [participants, deviceCallSid]);

    const toggleHold = useCallback(() => {
        if (!participant?.callSid) {
            const error = new Error("Call sid not found");
            logger.error(error);
            throw error;
        }

        return holdParticipant({
            conferenceSid,
            conferenceFriendlyId,
            callSid: participant.callSid,
            hold: !participant.onHold,
        });
    }, [participant, conferenceSid, conferenceFriendlyId]);

    return [participant?.onHold || false, toggleHold];
};
