import { CloseOutlined } from "@ant-design/icons";
import { Row } from "antd";

import styles from "./PopoverTitle.module.scss";

export default function PopoverTitle({
    onClose,
    title = "",
    ...props
}: {
    onClose: any;
    title?: string;
    [x: string]: any;
}): JSX.Element {
    return (
        <Row align="middle" justify="space-between" {...props}>
            <span className={styles.title} data-testid="popover-title">
                {title}
            </span>
            <CloseOutlined className={styles.icon} onClick={onClose} />
        </Row>
    );
}
