import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { formatConversationMessage } from "./utils";

import type { DeserializedMessage } from "./utils";
import type { Message as IMessage } from "@regal-voice/shared-types";

export interface ConversationEntry {
    sid: string;
    lastReviewedAt: number;
}

export type ActiveConversationsState = {
    entries: ConversationEntry[];
    conversationMessages: { [sid: string]: Partial<IMessage>[] };
    reservationMessages: { [sid: string]: Partial<IMessage>[] };
    notififiedMessages: { [sid: string]: string[] };
};

const initialState: ActiveConversationsState = {
    entries: [],
    conversationMessages: {},
    reservationMessages: {},
    notififiedMessages: {},
};

const conversationsSlice = createSlice({
    name: "activeConversations",
    initialState,
    reducers: {
        setConversation: (state, action: PayloadAction<{ sid: string }>) => {
            const index = state.entries.findIndex((e) => e.sid == action.payload.sid);
            if (index >= 0) {
                state.entries[index].lastReviewedAt = Date.now();
            } else {
                state.entries.push({
                    sid: action.payload.sid,
                    lastReviewedAt: Date.now(),
                });
            }
        },
        updateConversation: (state, action: PayloadAction<{ sid: string }>) => {
            const index = state.entries.findIndex((e) => e.sid == action.payload.sid);
            if (index >= 0) {
                state.entries[index].lastReviewedAt = Date.now();
            }
        },
        removeConversation: (state, action: PayloadAction<{ sid: string }>) => {
            state.entries = state.entries.filter((entry) => entry.sid != action.payload.sid);
            state.conversationMessages[action.payload.sid] = [];
            state.reservationMessages[action.payload.sid] = [];
            state.notififiedMessages[action.payload.sid] = [];
        },
        setReservationMessages: (state, action: PayloadAction<{ sid: string; messages: any[] }>) => {
            if (state.reservationMessages[action.payload.sid] === undefined) {
                state.reservationMessages[action.payload.sid] = [];
            }
            const cleanMessages = action.payload.messages.map((m) => {
                return formatConversationMessage(m);
            });

            state.reservationMessages[action.payload.sid].push(...cleanMessages);
        },
        // this message any type is the output of deserializeConversationMessage.
        addConversationMessage: (state, action: PayloadAction<{ sid: string; message: DeserializedMessage }>) => {
            if (state.conversationMessages[action.payload.sid] === undefined) {
                state.conversationMessages[action.payload.sid] = [];
            }
            const cleanMessage = formatConversationMessage(action.payload.message);
            state.conversationMessages[action.payload.sid].push(cleanMessage);
        },
        addNotifiedMessage: (state, action: PayloadAction<{ sid: string; messageSid: string }>) => {
            if (state.notififiedMessages[action.payload.sid] === undefined) {
                state.notififiedMessages[action.payload.sid] = [];
            }
            state.notififiedMessages[action.payload.sid].push(action.payload.messageSid);
        },
    },
});

export const {
    setConversation,
    updateConversation,
    removeConversation,
    setReservationMessages,
    addConversationMessage,
    addNotifiedMessage,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
