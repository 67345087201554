import { selectBrandAppNotificationsConfig } from "Services/state/brand";
import { reduxStore } from "Services/state/Storage";

/**
 * Should only be used to access the brand's app notifications config from outside the React context.
 * Goes into redux store directly. For React components/hooks, use the `useSelector` hook directly.
 */
export function getAppNotificationsConfig() {
    return selectBrandAppNotificationsConfig(reduxStore.getState());
}

export function getTaskAudioConfig(taskTitle: string): { url?: string; loop?: boolean } {
    const notificationsConfig = getAppNotificationsConfig();
    if (!notificationsConfig.enabled) {
        return {};
    }
    return notificationsConfig.events?.["reservation.created"]?.[taskTitle] || {};
}
