import { createSelector } from "@reduxjs/toolkit";

import { RootReducerShape } from "Services/state/Storage";
import { selectTasks } from "Services/state/tasks/Selectors";
import { isPendingInboundConferenceTask } from "Services/Task.service";

export const selectConferenceState = (state: RootReducerShape) => state.conference;

export const selectConference = createSelector(
    selectConferenceState,
    (conferenceState) => conferenceState.activeConference.value
);

export const selectActiveConferenceTask = createSelector(selectConference, selectTasks, (conference, tasks) => {
    const conferenceTaskId = conference.connectionParams?.taskSid;
    if (conferenceTaskId) {
        // TODO: [RD-10599] this feels unnecessary as we have tasks with the sid as the key.
        // We are using sid (from reservation) as the key instead of taskSid which does not help
        return Object.values(tasks).find((task) => task.taskSid == conferenceTaskId);
    }
});

export const selectDeviceCallSid = createSelector(selectConference, (conference) => conference.deviceCallSid);
export const selectDeviceCallConnectionParams = createSelector(
    selectConference,
    (conference) => conference.connectionParams
);
export const selectDeviveCallConnected = createSelector(selectConference, (conference) => conference.connected);
export const selectDeviceCallIsRecording = createSelector(selectConference, (conference) => conference.isRecording);
export const selectDeviceCallParticipants = createSelector(selectConference, (conference) => conference.participants);

export const selectIsPowerDialTaskWaiting = createSelector(
    selectActiveConferenceTask,
    (activeConferenceTask) =>
        activeConferenceTask &&
        activeConferenceTask.status === "pending" &&
        activeConferenceTask.attributes.autoAnswer &&
        activeConferenceTask.taskChannelUniqueName !== "regal" // "regal" is used for sms
);

const selectInboundCallTaskWaiting = (state: RootReducerShape): boolean => {
    const activeConferenceTask = selectActiveConferenceTask(state);

    return !!(
        activeConferenceTask &&
        activeConferenceTask.status != "wrapping" &&
        isPendingInboundConferenceTask(activeConferenceTask)
    );
};

export const selectShowPanel = createSelector(
    selectDeviceCallSid,
    selectActiveConferenceTask,
    selectIsPowerDialTaskWaiting,
    selectInboundCallTaskWaiting,
    (deviceCallSid, activeConferenceTask, inboundCallTaskWaiting, isPowerDialTaskWaiting) => {
        return !!(
            deviceCallSid ||
            isPowerDialTaskWaiting ||
            inboundCallTaskWaiting ||
            activeConferenceTask?.status == "accepted"
        );
    }
);

export const selectBargingParticipants = createSelector(selectConference, ({ participants }) => {
    if (!participants) {
        return [];
    }
    return Object.values(participants).filter(({ managerAction }) => managerAction == "barge");
});
