import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "Services/state/Storage";

const WARNING_RESOLUTION_DELAY_TIMEOUT_MS = 10000;

export const NETWORK_QUALITY_WARNINGS = [
    "high-rtt",
    "low-mos",
    "high-jitter",
    // "high-packet-loss",
    "high-packets-lost-fraction",
    "low-bytes-received",
    "low-bytes-sent",
    "ice-connectivity-lost",
] as const;

export type NetworkQualityWarning = (typeof NETWORK_QUALITY_WARNINGS)[number];

type WarningTimestamps = { warnedAt: number; resolvedAt?: number };
type NetworkQualityState = Record<NetworkQualityWarning, WarningTimestamps>;

const clearWarningsTimeouts: Partial<Record<NetworkQualityWarning | "all", number>> = {};

const NetworkQualitySlice = createSlice({
    name: "networkQuality",
    initialState: {} as NetworkQualityState,
    reducers: {
        warn(state, action: PayloadAction<NetworkQualityWarning>) {
            state[action.payload] = { warnedAt: Date.now() };
        },
        resolveWarning(state, action: PayloadAction<NetworkQualityWarning>) {
            state[action.payload] = {
                ...state[action.payload],
                resolvedAt: Date.now(),
            };
        },
        clearWarning(state, action: PayloadAction<NetworkQualityWarning>) {
            delete state[action.payload];
        },
        clearAllWarnings() {
            return {} as NetworkQualityState;
        },
    },
});

const {
    clearWarning,
    clearAllWarnings,
    resolveWarning: resolveWarningAction,
    warn: warnAction,
} = NetworkQualitySlice.actions;

export const { name, reducer } = NetworkQualitySlice;

export function selectNetworkQualityWarnings(state: RootState) {
    return state.conference[name];
}

// Selectors
export const selectActiveWarnings = createSelector(
    selectNetworkQualityWarnings,
    (warnings): Array<NetworkQualityWarning> => {
        // Active warning is defined as one where there is a `warnedAt` set
        // and there either is not a resolution time set, or it was set within
        // the past `timeoutMs` (defaults to 10 seconds)
        return (Object.keys(warnings) as Array<NetworkQualityWarning>).filter(
            (warningName) =>
                NETWORK_QUALITY_WARNINGS.includes(warningName) &&
                warnings[warningName].warnedAt &&
                (!warnings[warningName].resolvedAt ||
                    // bug bash clean up, I think our typing is off somewhere.
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    warnings[warningName].resolvedAt! > Date.now() - WARNING_RESOLUTION_DELAY_TIMEOUT_MS)
        );
    }
);

// Thunks
export const warn = createAsyncThunk("networkQuality/warn", (warningName: NetworkQualityWarning, { dispatch }) => {
    // If a new warning is raised, prevent the warnings from
    // automatically clearing
    clearWarningsTimeouts.all && clearTimeout(clearWarningsTimeouts.all);
    clearWarningsTimeouts[warningName] && clearTimeout(clearWarningsTimeouts[warningName]);

    dispatch(warnAction(warningName));
});

export const resolveWarning = createAsyncThunk(
    "networkQuality/resolveWarning",
    (warningName: NetworkQualityWarning, { dispatch }) => {
        dispatch(resolveWarningAction(warningName));
        clearWarningsTimeouts[warningName] = window.setTimeout(() => {
            dispatch(clearWarning(warningName));
        }, WARNING_RESOLUTION_DELAY_TIMEOUT_MS);
    }
);

export const clearWarnings = createAsyncThunk("networkQuality/clearWarnings", (_, { dispatch }) => {
    clearWarningsTimeouts.all = window.setTimeout(() => {
        dispatch(clearAllWarnings());
    }, WARNING_RESOLUTION_DELAY_TIMEOUT_MS);
});
