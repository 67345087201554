import { StrictMode } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { message } from "antd";
import { LDLogger, asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { noop } from "lodash";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import AppContainer from "App/main/AppContainer";
import { RegalAuthProvider, loadRegalAuthProfile } from "App/main/RegalAuthContext";
import { prepareDOM } from "App/main/Utils";
import { MicPermissionsCheck } from "App/MicPermissionsCheck";
import { hardRedirect } from "App/routes";
import { FLAG_DEFAULTS } from "Application/thirdParty/launchDarkly";
import { Loading } from "Components/elements/Loading";
import { getLogger } from "Services/LoggingService";
import { reduxStore } from "Services/state/Storage";
import "Styles/globals.scss";

// config antd max visible messages
message.config({
    maxCount: 5,
});

const logger = getLogger("LaunchDarklyClient");

// we want to disregard most logs since LD is noisy
const ldLogger: LDLogger = {
    error: (message) => logger.error(message),
    debug: noop,
    warn: noop,
    info: noop,
};

const LD_CLIENT_ID = process.env.PUBLIC_ENV_LAUNCH_DARKLY_CLIENT_ID as string;

(async function app(appRoot) {
    const regalAuthProfile = loadRegalAuthProfile();
    if (!regalAuthProfile) {
        hardRedirect("/login");
        return;
    }

    const { user, brand } = regalAuthProfile;

    const LDProvider = await asyncWithLDProvider({
        clientSideID: LD_CLIENT_ID,
        context: {
            kind: "user",
            anonymous: false,
            key: user.oktaUserId,
            email: user.email,
            brand: brand.slug,
            roles: user.roles,
        },
        flags: FLAG_DEFAULTS, // only subscribe to specified flags
        options: {
            logger: ldLogger,
            bootstrap: "localStorage",
            fetchGoals: false, // used for A/B testing, can be off for now
            inspectors: [
                {
                    type: "flag-used",
                    name: "dd-inspector",
                    method: (key, detail) => {
                        datadogRum.addFeatureFlagEvaluation(key, detail.value);
                    },
                },
            ],
        },
    });

    const reduxPersistor = persistStore(reduxStore);

    appRoot.render(
        <StrictMode>
            <LDProvider>
                <RegalAuthProvider profile={regalAuthProfile}>
                    <ReduxProvider store={reduxStore}>
                        <PersistGate loading={<Loading />} persistor={reduxPersistor}>
                            <MicPermissionsCheck />
                            <AppContainer />
                        </PersistGate>
                    </ReduxProvider>
                </RegalAuthProvider>
            </LDProvider>
        </StrictMode>
    );
})(createRoot(prepareDOM()));
