import { useCallback, useMemo } from "react";

import { useSelector } from "react-redux";

import { useTwilioDevice } from "Hooks/useTwilioDevice/useTwilioDevice";
import { selectBargingParticipants } from "Services/state/conferences";
import { useRVDispatch } from "Services/state/Storage";
import { setActiveTask } from "Services/state/tasks/Thunks";

import { BUTTONS_MESSAGES, type CallBarButtonMessagesFactory } from "../CallBar.consts";
import { logger } from "../CallBar.utils";

import type { RVTask } from "@regal-voice/shared-types";
import type { Agent } from "Types/Agent";

export type UseHangUpCallOptions = {
    activeConferenceTask?: RVTask;
    agents: Agent[];
};

type HangUpCallFn = (alreadyHangingUp?: boolean) => void;

export type UseHangUpCallResult = {
    hangUpCall: HangUpCallFn;
    hangUpButtonMessages: CallBarButtonMessagesFactory;
};

export const useHangUpCall = ({ activeConferenceTask, agents }: UseHangUpCallOptions): UseHangUpCallResult => {
    const dispatch = useRVDispatch();
    const bargingParticipants = useSelector(selectBargingParticipants);
    const { device } = useTwilioDevice();
    const hangUpCall = useCallback<HangUpCallFn>(
        (alreadyHangingUp) => {
            if (activeConferenceTask) {
                dispatch(setActiveTask(activeConferenceTask));
            }
            logger.log("Attempting to hang up", { device, param: alreadyHangingUp });
            device?.disconnectAll();
        },
        [dispatch, activeConferenceTask, device]
    );
    const hangUpButtonMessages = useMemo(() => {
        if (bargingParticipants.length == 0) {
            return BUTTONS_MESSAGES.hangUp;
        }

        const participantNames = bargingParticipants.map(
            ({ phoneNumber }) => agents.find((a) => a.twilioContactUri == phoneNumber)?.name || "A manager"
        );

        let title: string;
        if (participantNames.length == 1) {
            const [firstParticipant] = participantNames;
            title = `${firstParticipant} is barging. Ending this call will end it for all parties.`;
        } else {
            const [firstParticipant, ...otherParticipants] = participantNames;
            const otherParticipantsAreMoreThanOne = otherParticipants.length > 1;
            const label = `${firstParticipant} + ${otherParticipants.length} other${
                otherParticipantsAreMoreThanOne ? "s" : ""
            }`;
            title = `${label} are barging. Ending this call will end it for all parties.`;
        }

        return {
            ...BUTTONS_MESSAGES.hangUp,
            title: () => title,
        };
    }, [bargingParticipants, agents]);

    return { hangUpCall, hangUpButtonMessages };
};
