import { createContext, useContext } from "react";

import { memoize } from "lodash";

import { RegalAuthProfile } from "Types/Auth";

export type RegalAuthContextValue = {
    user: {
        oktaUserId: string;
        email: string;
        firstName: string;
        lastName: string;
        name: string;
        roles: string[];
    };
    brand: {
        slug: string;
    };
};

const COOKIE_NAME = "regal-sid-profile";

const parseCookiesString = memoize((cookie: string) =>
    cookie.split(";").reduce<Record<string, string>>((acc, cookie) => {
        const [key, value] = cookie.split("=");
        acc[key.trim()] = value;
        return acc;
    }, {})
);

const getCookieDict = () => parseCookiesString(document.cookie);

const getProfileFromBase64Cookie = (cookie: string): RegalAuthProfile => {
    const parsed = atob(cookie);
    if (parsed.startsWith("{")) {
        return JSON.parse(parsed) as RegalAuthProfile;
    }

    const decoded = Object.fromEntries(new URLSearchParams(parsed)) as Omit<RegalAuthProfile, "roles"> & {
        roles: string;
    };

    return {
        ...decoded,
        roles: decoded.roles.split(","),
    };
};

export const regalAuthProfileExists = (): boolean => {
    const cookie = getCookieDict()[COOKIE_NAME];
    return !!cookie;
};

export const loadRegalAuthProfile = (): RegalAuthContextValue | undefined => {
    const cookie = getCookieDict()[COOKIE_NAME];

    if (!cookie) {
        return;
    }

    let profile: RegalAuthProfile;
    const uriDecodedCookie = decodeURIComponent(cookie);
    if (cookie.includes(".")) {
        const [content] = uriDecodedCookie.split(".");
        if (!content) {
            return;
        }
        profile = getProfileFromBase64Cookie(content);
    } else {
        profile = getProfileFromBase64Cookie(uriDecodedCookie);
    }

    return {
        user: {
            oktaUserId: profile.oktaUserId,
            email: profile.email,
            firstName: profile.firstName,
            lastName: profile.lastName,
            name: profile.name,
            roles: profile.roles,
        },
        brand: {
            slug: profile.brandSlug,
        },
    };
};

export const RegalAuthContext = createContext<RegalAuthContextValue>({
    user: {
        oktaUserId: "",
        email: "",
        firstName: "",
        lastName: "",
        name: "",
        roles: [],
    },
    brand: {
        slug: "",
    },
});

export const RegalAuthProvider = ({
    children,
    profile,
}: {
    children: React.ReactNode;
    profile: RegalAuthContextValue;
}) => <RegalAuthContext.Provider value={profile}>{children}</RegalAuthContext.Provider>;

export const useRegalAuthContext = () => useContext(RegalAuthContext);
