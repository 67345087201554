import { createAsyncThunk } from "@reduxjs/toolkit";
import { RVTask } from "@regal-voice/shared-types";
import { Device } from "@twilio/voice-sdk";
import { message } from "antd";
import { SerializedEditorState, SerializedElementNode, SerializedTextNode } from "lexical";
import { intersection, omitBy } from "lodash";
import { batch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { FlagTypes } from "Application/thirdParty/launchDarkly";
import { apolloQuery } from "Services/ApolloClientService";
import { getSmsError } from "Services/CommunicationService";
import { sendOutboundSms, outboundCall, OutboundCallBody } from "Services/ConversationsApiService";
import { postMessageToParentWithTaskIfNeeded } from "Services/embed/CrossDomainMessenger";
import { getLogger, normalizeError, renderErrorMessage } from "Services/LoggingService";
import { getProfile } from "Services/marketing-api/audience/queries";
import { getRenderedSMSCampaignTemplate } from "Services/marketing-api/campaigns/queries";
import { getReservationsByEmail } from "Services/marketing-api/tasks/queries";
import { Marks, performanceMark } from "Services/PerfService";
import { selectOnActiveCall } from "Services/state/agent/AgentInformationSlice";
import { clearConference, setActiveConferenceTask } from "Services/state/conferences/ConferenceStateSlice";
import { selectActiveConferenceTask } from "Services/state/conferences/Selectors";
import { clearSelectedTaskContact, setSelectedTaskContact } from "Services/state/contacts/ActiveContactStateProvider";
import { RootState, RVDispatch, ThunkType, ThunkAction, AsyncThunkStore } from "Services/state/Storage";
import {
    getAttributes,
    isEarlyCustomerHangUpProgressiveDialCall,
    isInboundConferenceCall,
    isPendingConferenceTask,
} from "Services/Task.service";
import { openTaskUrlIfNeeded } from "Services/TasksOpenUrlSettings";

import { newEmailEventToRespondTo } from "../agent/desktopUI/email/EmailThunks";
import { clearPersistedDataForThread } from "../agent/desktopUI/email/EmailUIPersistSlice";
import { selectPageAwareContactObject } from "../contacts/Selectors/Selectors";
import { fetchContactList } from "../contacts/Thunks";
import { handleError, handleSuccess } from "../global-messages/GlobalMessages";
import { InputPersistNamespaces, setUserInput } from "../input-persist/IndexProvider";
import { clearActiveTask, updateActiveTask } from "./ActiveTaskSlice";
import {
    selectActiveTask,
    selectTasks,
    selectSortedTasks,
    selectTask,
    autocompletePendingTasksSelector,
    selectWaitingForTaskState,
    selectTasksList,
} from "./Selectors";
import { clearWaitingForTaskState, setTask, setWaitingForTask, TasksState, tasksStateActions } from "./TasksStateSlice";
import { clearTaskFromAutocomplete } from "./TasksToAutocompleteSlice";
import {
    acceptReservation,
    finalReservationStatuses,
    handleNonLoopingAudioNotification,
    keysDiff,
    makeCall,
    mapRecentTaskToRVTask,
    shouldAutoAcceptTask,
    isVoiceTask,
    shouldIgnoreTask,
    shouldWaitForSubsequentTask,
    isAgentWaitingForThisTask,
    getContactIdentifierForTask,
    taskIsAcceptedProgressiveDial,
    isAgentSMSTask,
    checkIfDeviceIsAvailableForCallTask,
} from "./Utils";

// TODO find a better way to do this
// for AgentSMS tasks we need to convert the text we get from the journey into a Lexical state
// but we can't do that without having an editor instance and we do need to create it before the instance
// as a temporary solution, we'll return a pre-serialized state where we just fill in the text
function serializeToLexical(text: string) {
    const state = {
        root: {
            children: [
                {
                    children: [
                        {
                            detail: 0,
                            format: 0,
                            mode: "normal",
                            style: "",
                            type: "text",
                            version: 1,
                            text,
                        },
                    ],
                    direction: "ltr",
                    format: "",
                    indent: 0,
                    type: "paragraph",
                    version: 1,
                },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "root",
            version: 1,
        },
    } satisfies SerializedEditorState<SerializedElementNode<SerializedTextNode>>;

    return JSON.stringify(state);
}

type ReservationWrapupInput = {
    reservationSid: string;
    reservationUpdatedDate: string;
};

export const logger = getLogger("Task thunks");

const { setTasksAction, removeTasksBySid } = tasksStateActions;
const wrapping = "wrapping" as const;

export function setActiveTask(task?: RVTask): ThunkAction<void, RootState, unknown, AnyAction> {
    return function thunk(dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState): void {
        const tasks = selectTasks(getState());
        if (task && !tasks[task.sid]) {
            logger.warn("setActiveTask to a task that doesn't exist in tasks");
        }

        const contactIdentifier = getContactIdentifierForTask(task);
        dispatch(setSelectedTaskContact(contactIdentifier));
        dispatch(updateActiveTask(task));
        dispatch(newEmailEventToRespondTo(task?.attributes.originatingEmailEvent ?? null));

        if (task) {
            postMessageToParentWithTaskIfNeeded(task, "task-active");
        }
    };
}

function isManualOutboundTask(task: RVTask) {
    // TODO: not sure about this. check again
    return task?.attributes?.autoAnswer && task?.taskChannelUniqueName !== "regal";
}

function autoSelectActiveTask({
    currentTasks,
    newTasks,
    activeTask,
    topTask,
    dispatch,
}: {
    currentTasks: TasksState;
    newTasks: TasksState;
    activeTask: RVTask | undefined;
    topTask: RVTask | undefined;
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
}) {
    // the selected task should only be automatically changed when:
    // 1. The user logs in and has tasks in the task list.
    // There is no active task and a new task comes in.
    // 2. The agent creates a manual outbound task (phone or SMS).
    // 3. The active task is resolved (wrapped, canceled, rescinded).
    if (Object.keys(currentTasks).length == 0) {
        // 1. new tasks added when current tasks empty
        dispatch(setActiveTask(topTask));
    } else {
        const diff = keysDiff(currentTasks, newTasks);
        if (diff.added.length) {
            const manualOutboundTask = diff.added.find((sid) => {
                return isManualOutboundTask(newTasks[sid]);
            });
            // 2. manual outbound task added
            if (manualOutboundTask) {
                dispatch(setActiveTask(newTasks[manualOutboundTask]));
            } else if (diff.removed.length && diff.removed.includes(activeTask?.sid || "")) {
                // 3. select top task if active task removed
                dispatch(setActiveTask(topTask));
            }
        } else if (diff.removed.length && diff.removed.includes(activeTask?.sid || "")) {
            // 3. select top task if active task removed
            dispatch(setActiveTask(topTask));
        }
    }
}

export const setTasks = createAsyncThunk<void, { newTasks: TasksState; prefetchContacts?: boolean }, AsyncThunkStore>(
    "tasks/setTasks",
    ({ newTasks, prefetchContacts = false }, { dispatch, getState }) => {
        const currentTasks = selectTasks(getState());
        const activeTask = selectActiveTask(getState() as RootState);

        dispatch(setTasksAction(newTasks));
        const topTask = selectSortedTasks(getState())[0];
        autoSelectActiveTask({ currentTasks, newTasks, activeTask, topTask, dispatch });

        if (prefetchContacts) {
            const fetchProfileIds: string[] = [];
            Object.values(newTasks).forEach((task) => {
                const profileId = task?.attributes?.profileId;
                if (profileId) {
                    fetchProfileIds.push(profileId);
                }
            });
            if (fetchProfileIds.length) {
                dispatch(fetchContactList(fetchProfileIds));
            }
        }
    }
);

export function updateTaskAttributes(task: RVTask): ThunkType {
    return function thunk(dispatch: RVDispatch, getState: () => RootState): void {
        const taskSid = task.sid;
        const tasks = selectTasks(getState());
        const activeTask = selectActiveTask(getState() as RootState);

        logger.log("Update task attributes", { taskSid });
        // because state is not normalized, I have to update task in tasks list and also active task.
        // active task should store only task sid to have state normalized
        // 1. update task in task list
        const updatedTask = Object.values(tasks).find((task) => task.taskSid == taskSid);
        if (updatedTask) {
            dispatch(setTask({ ...updatedTask, attributes: { ...updatedTask.attributes, ...task.attributes } }));
        }

        // 2. update active task
        if (activeTask && activeTask.taskSid == task.sid) {
            const updated = { ...activeTask, attributes: { ...activeTask.attributes, ...task.attributes } };
            dispatch(setActiveTask(updated));
        }
    };
}

/**
 * Remove tasks from task list and cleanup if needed:
 * - clear active task
 * - clear conference
 *
 * @param clearedTasks array of task sid (not reservation sid)
 */
export const removeTasksByTaskSid = createAsyncThunk<void, string[], AsyncThunkStore>(
    "tasks/removeTasksByTaskSid",
    (clearedTasks: string[], { dispatch, getState }) => {
        const tasks = selectTasks(getState());
        const activeTask = selectActiveTask(getState());
        // clearedTasks are not reservation sid. search by taskSid
        const newTasks = omitBy(tasks, (value: RVTask) => clearedTasks.includes(value.taskSid));
        const activeConferenceTask = selectActiveConferenceTask(getState());

        const contact = selectPageAwareContactObject(getState());
        const pendingOrActiveTasks = selectTasksList(getState());
        const existingActiveTaskForContact = pendingOrActiveTasks.find(
            (task) =>
                ["pending", "accepted", "wrapping"].includes(task.status) &&
                (task.attributes.email === contact?.email || task.attributes.profileId === contact?.id)
        );

        logger.log("Remove tasks by task sid", { clearedTasks });
        batch(() => {
            dispatch(setTasks({ newTasks }));

            if (activeTask && clearedTasks.includes(activeTask.taskSid)) {
                dispatch(clearActiveTask());
                dispatch(clearSelectedTaskContact());
                existingActiveTaskForContact && dispatch(clearPersistedDataForThread(existingActiveTaskForContact.sid));

                const topTask = selectSortedTasks(getState())[0];
                dispatch(setActiveTask(topTask));
            }
            if (activeConferenceTask && clearedTasks.includes(activeConferenceTask.sid)) {
                logger.log("Clearing conference state", {
                    activeConferenceTaskSid: activeConferenceTask.sid,
                });
                dispatch(clearConference());
                dispatch(setActiveConferenceTask({ id: undefined }));
                existingActiveTaskForContact && dispatch(clearPersistedDataForThread(existingActiveTaskForContact.sid));
            }
        });
    }
);

/**
 * Remove task from task list and cleanup if needed:
 * - clear active task
 * - clear conference
 *
 * @param sid reservation sid
 */
export function clearReservationBySid(sid: string): ThunkAction<void, RootState, unknown, AnyAction> {
    return function thunk(dispatch, getState: () => RootState): void {
        const activeTask = selectActiveTask(getState() as RootState);
        const activeConferenceTask = selectActiveConferenceTask(getState());

        logger.log("Clear reservation by sid", { sid });
        batch(() => {
            dispatch(removeTasksBySid([sid]));
            if (activeTask && activeTask.sid == sid) {
                dispatch(clearActiveTask());
                dispatch(clearSelectedTaskContact());
                dispatch(clearPersistedDataForThread(activeTask.sid));
                const topTask = selectSortedTasks(getState())[0];
                dispatch(setActiveTask(topTask));
            }
            if (activeConferenceTask && activeConferenceTask.sid == sid) {
                logger.log("Clearing conference state", {
                    activeConferenceTaskSid: activeConferenceTask.sid,
                });
                dispatch(clearConference());
                dispatch(clearPersistedDataForThread(activeConferenceTask.sid));
                dispatch(setActiveConferenceTask({ id: undefined }));
            }
        });
    };
}

// ONLY to be used when sse-powered-task-data flag is true (aka post-Flex world)
type backfillOptions = object;
export const backfillExistingActiveTasks = createAsyncThunk<void, backfillOptions, AsyncThunkStore>(
    "tasks/backfillExistingActiveTasks",
    async ({}, { dispatch }) => {
        const { data } = await apolloQuery({ query: getReservationsByEmail });

        // TODO: Add explicit type for this?
        const rvTasks: RVTask[] = data.reservationsByEmail.map((reservationData: any) => {
            const reservation = {
                reservationSid: reservationData.reservationSid,
                workerSid: reservationData.userSid,
                createdAt: reservationData.createdAt,
                updatedAt: reservationData.updatedAt,
            };
            return mapRecentTaskToRVTask(reservation, reservationData.task);
        });

        if (!rvTasks.length) {
            return;
        }

        const tasksState: TasksState = rvTasks.reduce(
            (acc: TasksState, curr) => ({ ...acc, [curr.reservationSid]: curr }),
            {}
        );

        dispatch(setTasks({ newTasks: tasksState, prefetchContacts: true }));
    }
);

export function acceptTaskThunk(
    task: RVTask,
    { multipleCalls }: Partial<FlagTypes>
): ThunkAction<void, RootState, unknown, AnyAction> {
    return async function thunk(dispatch): Promise<boolean | undefined> {
        if (multipleCalls && isPendingConferenceTask(task)) {
            dispatch(clearConference());
            dispatch(clearActiveTask());
            dispatch(setActiveConferenceTask({ id: task?.sid }));
        }

        dispatch(setActiveTask(task));
        const shouldWaitForSubsequent = shouldWaitForSubsequentTask(task);
        if (shouldWaitForSubsequent) {
            const { contactPhone, taskType } = task.attributes;
            dispatch(setWaitingForTask({ contactPhone, taskType }));
        }

        const { success: acceptResSuccess, errors } = await acceptReservation(task);

        if (!acceptResSuccess) {
            dispatch(clearWaitingForTaskState());
            if (errors) {
                dispatch(handleError(errors));
                dispatch(clearReservationBySid(task.sid));
            }
            return;
        }

        const { errors: makeCallError, message: makeCallMessage } = await makeCall(task);

        if (makeCallError) {
            dispatch(clearConference());
            dispatch(handleError(makeCallError));
            return;
        }

        if (makeCallMessage) {
            dispatch(handleSuccess(makeCallMessage));
        }
    };
}

function autoSelectActiveTask_PostFlexRemoval(
    task: RVTask,
    direction: "added" | "removed"
): ThunkAction<void, RootState, unknown, AnyAction> {
    return function thunk(dispatch, getState: () => RootState) {
        const taskList = selectSortedTasks(getState());
        const topTask = taskList[0];
        if (direction === "added" && (shouldAutoAcceptTask(task) || taskIsAcceptedProgressiveDial(task))) {
            dispatch(setActiveTask(task));
            if (isVoiceTask(task)) {
                dispatch(setActiveConferenceTask({ id: task.sid }));
            }
        } else if (taskList.length === 1) {
            dispatch(setActiveTask(topTask));
        } else if (
            direction === "removed" &&
            // Remvoing the active task should select the top the task every time.
            // Allowing either of these cases makes this logic work both regardless of if that task has already been removed from reduxs.
            (!selectActiveTask(getState() as RootState) ||
                selectActiveTask(getState() as RootState)?.taskSid === task.taskSid)
        ) {
            dispatch(setActiveTask(topTask));
        }
    };
}

function warnUpdateUnfoundTask(reservationSid: string, eventType: string) {
    logger.warn("Attempted to update unfound task in redux", { reservationSid, eventType });
}

export const handleReservationCreated = createAsyncThunk<
    void,
    { task: RVTask; flags: Partial<FlagTypes>; device: Device | undefined; fetchNewDeviceToken: () => Promise<void> },
    AsyncThunkStore
>(
    "tasks/handleReservationCreated",
    ({ task, flags: { multipleCalls }, device, fetchNewDeviceToken }, { dispatch, getState }) => {
        logger.log("handleReservationCreated thunk running", {
            taskSid: task.taskSid,
            reservationSid: task.reservationSid,
        });

        // if we are waiting for another task, we ignore any priority tasks
        if (shouldIgnoreTask(task)) {
            const { contactPhone, taskType } = selectWaitingForTaskState(getState());
            logger.warn(
                "Race condition prevented. UI ignored reservation because it is waiting for another task to come through",
                {
                    ignoredTaskSid: task.taskSid,
                    ignoredTaskContactPhone: task.attributes.contactPhone,
                    ignoredTaskType: task.attributes.taskType,
                    waitingForTaskWithContactPhone: contactPhone,
                    waitingForTaskWithType: taskType,
                }
            );
        } else if (task.attributes?.originatingEmailEvent?.direction === "inbound") {
            // set a timeout to allow the email event to be processed
            logger.log(`Delaying setting inbound email task for task SID: ${task.sid}`);
            setTimeout(() => {
                logger.log(`Dispatching setTask for inbound email task SID ${task.sid}`);
                dispatch(setTask(task));
                dispatch(autoSelectActiveTask_PostFlexRemoval(task, "added"));
                postMessageToParentWithTaskIfNeeded(task, "task-assigned");
            }, 10000);
        } else {
            checkIfDeviceIsAvailableForCallTask(task, task.status, device, fetchNewDeviceToken);
            dispatch(setTask(task));
            if (shouldAutoAcceptTask(task)) {
                dispatch(acceptTaskThunk(task, { multipleCalls }));
            }
            dispatch(autoSelectActiveTask_PostFlexRemoval(task, "added"));
            postMessageToParentWithTaskIfNeeded(task, "task-assigned");
            if (isAgentSMSTask(task)) {
                dispatch(populateAgentSMSTaskContent(task));
            }
        }
    }
);

/**
 * Agent SMS tasks have content that needs to be prepopulated for the SMS input.
 * We gather the evaluated content and populate it for the task in the input persisted state
 */
export const populateAgentSMSTaskContent = createAsyncThunk<void, RVTask, AsyncThunkStore>(
    "tasks/populateAgentSMSTaskContent",
    async (task, { dispatch }) => {
        try {
            const campaignId = getAttributes(task)?.campaignInfo?.id;
            const { profileId } = task.attributes;
            // we might not have the contact's populated info yet, so we need to fetch it
            const { data: contactResponse } = await apolloQuery({
                query: getProfile,
                variables: { profileId },
            });
            const contact = contactResponse.getProfile;
            const { data } = await apolloQuery({
                query: getRenderedSMSCampaignTemplate,
                variables: {
                    renderedSMSTemplateInput: {
                        campaignId,
                        templateVariables: { contact },
                    },
                },
            });
            const renderedSmSContent = data?.getRenderedSMSCampaignTemplate?.renderedSMSContentTemplate;
            dispatch(
                setUserInput({
                    namespace: InputPersistNamespaces.TEXT_MESSAGE_INPUT,
                    key: task.sid,
                    value: renderedSmSContent,
                })
            );
            const lexValue = serializeToLexical(renderedSmSContent);
            dispatch(
                setUserInput({
                    namespace: InputPersistNamespaces.TEXT_MESSAGE_LEXICAL_INPUT,
                    key: task.sid,
                    value: lexValue,
                })
            );
        } catch (e) {
            logger.warn("Failed to populate agent SMS task content", { taskSid: task.sid, error: normalizeError(e) });
        }
    }
);

export function handleReservationAccepted(
    device: Device | undefined,
    fetchNewDeviceToken: () => Promise<void>,
    reservationSid: string,
    reservationAcceptedAt?: string
): ThunkAction<void, RootState, unknown, AnyAction> {
    return async function thunk(dispatch, getState) {
        const task = selectTask(getState())(reservationSid);

        if (!task) {
            warnUpdateUnfoundTask(reservationSid, "accepted");
            return;
        }

        logger.log("handleReservationAccepted thunk running", { reservationSid, taskSid: task?.taskSid });

        checkIfDeviceIsAvailableForCallTask(task, "accepted", device, fetchNewDeviceToken);

        await handleNonLoopingAudioNotification(getState, task, "reservation.accepted", logger);

        if (isAgentWaitingForThisTask(task)) {
            dispatch(clearWaitingForTaskState());
        }

        dispatch(
            setTask({
                ...task,
                status: "accepted",
                taskStatus: "assigned",
                reservationAcceptedAt,
            })
        );

        openTaskUrlIfNeeded(task);
        postMessageToParentWithTaskIfNeeded(task, "task-accepted");
    };
}

export function handleReservationCanceled(
    reservationSid: string,
    sseTask: Partial<RVTask<"unknown">>
): ThunkAction<void, RootState, unknown, AnyAction> {
    return function thunk(dispatch, getState) {
        const task = selectTask(getState())(reservationSid);

        logger.log("handleReservationCanceled thunk running", { reservationSid, taskSid: task?.taskSid });

        // This is above the task-exists-in-redux check because the task may have been removed by the time this specific SSE comes in
        if (isEarlyCustomerHangUpProgressiveDialCall(sseTask)) {
            message.info(`The contact hung up the call`, 6);
        }

        if (!task) {
            return;
        }
        dispatch(clearReservationBySid(reservationSid));
        if (isInboundConferenceCall(task) && !task.attributes.canceled_by) {
            message.info(`${task.attributes.name || "Contact"} hung up the incoming call`);
        }

        dispatch(autoSelectActiveTask_PostFlexRemoval(task, "removed"));
        postMessageToParentWithTaskIfNeeded(task, "task-cancelled");
    };
}

export function handleReservationCompleted(reservationSid: string): ThunkAction<void, RootState, unknown, AnyAction> {
    return function thunk(dispatch, getState) {
        const state = getState();
        const task = selectTask(state)(reservationSid);

        logger.log("handleReservationCompleted thunk running", { reservationSid, taskSid: task?.taskSid });
        if (!task) {
            return;
        }
        const tasksToAutocomplete = autocompletePendingTasksSelector(state);
        if (intersection(tasksToAutocomplete, [task.sid, task.taskSid]).length) {
            dispatch(
                clearTaskFromAutocomplete({
                    taskSid: task.sid,
                })
            );
            message.success("Conversation automatically summarized.");
        }
        dispatch(clearReservationBySid(reservationSid));
        dispatch(autoSelectActiveTask_PostFlexRemoval(task, "removed"));
        postMessageToParentWithTaskIfNeeded(task, "task-completed");
    };
}

export function handleReservationRejected(reservationSid: string): ThunkAction<void, RootState, unknown, AnyAction> {
    return function thunk(dispatch, getState) {
        const task = selectTask(getState())(reservationSid);

        logger.log("handleReservationRejected thunk running", { reservationSid, taskSid: task?.taskSid });
        if (!task) {
            warnUpdateUnfoundTask(reservationSid, "rejected");
            return;
        }
        dispatch(clearReservationBySid(reservationSid));
        dispatch(autoSelectActiveTask_PostFlexRemoval(task, "removed"));
    };
}

export function handleReservationRescinded(reservationSid: string): ThunkAction<void, RootState, unknown, AnyAction> {
    return function thunk(dispatch, getState) {
        const task = selectTask(getState())(reservationSid);

        logger.log("handleReservationRescinded thunk running", { reservationSid, taskSid: task?.taskSid });
        if (!task) {
            warnUpdateUnfoundTask(reservationSid, "rescinded");
            return;
        }
        dispatch(clearReservationBySid(reservationSid));
        if (isInboundConferenceCall(task)) {
            message.info("The incoming call was answered by another agent.");
        }
        dispatch(autoSelectActiveTask_PostFlexRemoval(task, "removed"));
    };
}

export function handleReservationTimedOut(reservationSid: string): ThunkAction<void, RootState, unknown, AnyAction> {
    return function thunk(dispatch, getState) {
        const task = selectTask(getState())(reservationSid);

        logger.log("handleReservationTimedOut thunk running", { reservationSid, taskSid: task?.taskSid });
        if (!task) {
            warnUpdateUnfoundTask(reservationSid, "timeout");
            return;
        }
        dispatch(clearReservationBySid(reservationSid));
        dispatch(autoSelectActiveTask_PostFlexRemoval(task, "removed"));
    };
}

export const handleEndedConference = createAsyncThunk<void, RVTask, AsyncThunkStore>(
    "tasks/ended-conference",
    (task: RVTask, { dispatch, getState }) => {
        const isOnActiveCall = selectOnActiveCall(getState());
        const activeConferenceTask = selectActiveConferenceTask(getState());

        if (
            !isOnActiveCall &&
            activeConferenceTask?.taskSid === task.taskSid &&
            finalReservationStatuses.includes(task?.status)
        ) {
            dispatch(clearConference());
        }
    }
);

export const handleReservationWrapup = createAsyncThunk<void, ReservationWrapupInput, AsyncThunkStore>(
    "tasks/reservation-wrapup",
    ({ reservationSid, reservationUpdatedDate }, { dispatch, getState }) => {
        const task = selectTask(getState())(reservationSid);
        logger.log("handleReservationWrapup thunk running", { reservationSid, taskSid: task?.taskSid });
        if (!task) {
            warnUpdateUnfoundTask(reservationSid, "wrapup");
            return;
        }

        const updatedTask = {
            ...task,
            reservationUpdatedDate,
            status: wrapping,
            taskStatus: wrapping,
        };

        dispatch(handleEndedConference(updatedTask));
        dispatch(setTask(updatedTask));
        // TODO: [RD-9998] deprecate call-disconnected in favor of task-wrapping after the salesforce integration is not consuming it anymore
        postMessageToParentWithTaskIfNeeded(task, "call-disconnected");
        postMessageToParentWithTaskIfNeeded(task, "task-wrapping");
    }
);

export const handleTaskUpdate = createAsyncThunk<void, Partial<RVTask>, AsyncThunkStore>(
    "tasks/task-updated",
    (newTaskData: Partial<RVTask>, { dispatch, getState }) => {
        logger.log("handleTaskUpdate thunk running", { taskSid: newTaskData.taskSid });
        const tasks = Object.values(selectTasks(getState()));
        const currentTask = newTaskData.taskSid && tasks.find((t) => t.taskSid === newTaskData.taskSid);
        if (!currentTask) {
            return;
        }

        // there are race conditions that can cause useConferenceParticipants to add participant-join data after reservation.wrapup
        // this will provide another failsafe to clear the conference if there is no active call and the conference task is in wrapping
        dispatch(handleEndedConference(currentTask));

        const mergedTask = { ...currentTask, ...newTaskData };
        /**
         * Make sure we are not overriding the autoAnswer attribute, since it's not being handled
         * in the UI, and even before that, there's no reason to update it on a task that is
         * already in here.
         */
        if ("autoAnswer" in currentTask.attributes) {
            mergedTask.attributes["autoAnswer"] = currentTask.attributes["autoAnswer"];
        }
        dispatch(setTask(mergedTask));
    }
);

export function handleOutboundCallTaskCreationThunk(
    outboundCallBody: OutboundCallBody,
    successCallback?: () => void,
    errorCallback?: () => void
): ThunkAction<void, RootState, unknown, AnyAction> {
    return async function thunk(dispatch): Promise<void> {
        try {
            const startTime = performance.now();
            // we block the UI from receiving other call tasks while we wait for this one
            dispatch(
                setWaitingForTask({
                    contactPhone: outboundCallBody.contactPhone,
                    taskType: outboundCallBody.triggeredFromDialpad ? "Outbound Manual Call" : "Outbound Call",
                })
            );
            const { taskSid } = await outboundCall(outboundCallBody);

            performanceMark(Marks.getManualOutboundCallCreate(taskSid), { startTime });
            successCallback?.();
        } catch (e: any) {
            // if task couldn't be created, we clear the waiting state
            dispatch(clearWaitingForTaskState());
            renderErrorMessage({ content: "Task failed to be created", error: e });

            errorCallback?.();
        }
    };
}

type OutboundSmsTaskCreationThunkParams = {
    contactPhone: string;
    regalVoicePhone: string;
    errorCallback?: () => void;
    successCallback?: (res: { success: boolean; taskSid: string }) => void;
    profileId?: string;
};

export function handleOutboundSmsTaskCreationThunk({
    contactPhone,
    regalVoicePhone,
    errorCallback,
    successCallback,
    profileId,
}: OutboundSmsTaskCreationThunkParams): ThunkAction<void, RootState, unknown, AnyAction> {
    return async function thunk(): Promise<void> {
        try {
            const { success, taskSid } = await sendOutboundSms({
                contactPhone,
                regalVoicePhone,
                profileId,
            });

            if (!success) {
                throw new Error();
            }

            successCallback?.({ success, taskSid });
        } catch (err) {
            const userFacingError = getSmsError(err);
            message.error(userFacingError);

            logger.error(userFacingError, { err });

            errorCallback?.();
        }
    };
}
