import { RecentActivity } from "Types/RecentActivity";

export type ActivityEventConfig = {
    icon: string;
    iconColor: string;
    text: string;
};

type ActivityEventConfigMap = {
    icon: (recentActivity: RecentActivity) => string;
    iconColor: (recentActivity: RecentActivity) => string;
    text: (recentActivity: RecentActivity) => string;
};

const defaultMapping: ActivityEventConfigMap = {
    icon: () => "list",
    iconColor: (recentActivity: RecentActivity) => (recentActivity.source === "Regal Voice" ? "purple-3" : "gray-4"),
    text: (recentActivity: RecentActivity) => recentActivity.name,
};

const directionIconMapping = (recentActivity: RecentActivity) => {
    return `${recentActivity.properties?.direction === "OUTBOUND" ? "outbound" : "inbound"}-message`;
};

const EventTypeMapping: { [id: string]: Partial<ActivityEventConfigMap> } = {
    pageview: {
        icon: () => "pageview",
        iconColor: () => "gray-4",
        text: (recentActivity: RecentActivity) => "Pageview" + (recentActivity.page ? ": " + recentActivity.page : ""),
    },
    "contact.subscribed": {
        icon: () => "list",
        iconColor: () => "purple-3",
    },
    "contact.unsubscribed": {
        icon: () => "list",
        iconColor: () => "purple-3",
    },
    "Outbound SMS Sent": {
        icon: () => "outbound-message",
        iconColor: () => "blue-4",
    },
    "Outbound SMS Queued": {
        icon: () => "outbound-message",
        iconColor: () => "blue-5",
    },
    "sms.received": {
        icon: (recentActivity) => directionIconMapping(recentActivity),
        iconColor: () => "blue-3",
    },
    "mms.received": {
        icon: (recentActivity) => directionIconMapping(recentActivity),
        iconColor: () => "blue-3",
    },
    "sms.queued": {
        icon: (recentActivity) => directionIconMapping(recentActivity),
        iconColor: () => "blue-3",
    },
    "sms.sent": {
        icon: (recentActivity) => directionIconMapping(recentActivity),
        iconColor: () => "blue-3",
    },
    "mms.sent": {
        icon: (recentActivity) => directionIconMapping(recentActivity),
        iconColor: () => "blue-3",
    },
    "sms.conversation.completed": {
        icon: () => "task-completed",
        iconColor: () => "purple-3",
    },
    "call.completed": {
        icon: () => "task-completed",
        iconColor: () => "purple-3",
    },
    "Outbound Call": {
        iconColor: () => "green-3",
    },
    "Inbound Call": {
        iconColor: () => "gray-4",
    },
    "Missed Outbound Call": {
        iconColor: () => "red-3",
    },
    "Missed Inbound Call": {
        iconColor: () => "red-4",
    },
};

export function getEventTypeViewConfig(recentActivity: RecentActivity): ActivityEventConfig {
    const mapping = EventTypeMapping[recentActivity.name];

    return {
        ...defaultMapping,
        ...mapping,
        iconColor: (mapping?.iconColor ?? defaultMapping.iconColor)(recentActivity),
        icon: (mapping?.icon ?? defaultMapping.icon)(recentActivity),
        text: (mapping?.text ?? defaultMapping.text)(recentActivity),
    };
}
