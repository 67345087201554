import { useState } from "react";

import { RVPopoverWithTracking } from "Components/elements/RVPopoverWithTracking/RVPopoverWithTracking";

import { CallInfoDetail } from "./CallInfoDetail";

export type CallInfoPopoverButtonRenderer = (open: boolean) => JSX.Element;

export type CallInfoPopoverProps = {
    renderButton: CallInfoPopoverButtonRenderer;
    overlayClassName?: string;
};

export const CallInfoPopover = ({ renderButton, overlayClassName }: CallInfoPopoverProps): JSX.Element => {
    const [open, setOpen] = useState(false);

    return (
        <RVPopoverWithTracking
            id="call-info-popover"
            open={open}
            onOpenChange={setOpen}
            trigger="click"
            placement="top"
            content={<CallInfoDetail />}
            overlayClassName={overlayClassName}
        >
            {renderButton(open)}
        </RVPopoverWithTracking>
    );
};
