import { getLogger } from "Services/LoggingService";

/**
 * PromiseChain accepts jobs - sync or async - and invokes them one at a time in the order they were enqueued. Processing is automatic. Jobs can be awaited.
 */
export class PromiseChain<R, Job extends (...args: any[]) => R> {
    private chainName: string;
    private logger;
    private lastPromise: Promise<any>;

    constructor(chainName: string) {
        this.chainName = chainName;
        this.logger = getLogger(chainName);
        this.lastPromise = Promise.resolve();
    }

    addJobToQueue(job: Job): Promise<R> {
        const currentLast = this.lastPromise;
        const newLastPromise: Promise<R> = new Promise(async (resolve, reject) => {
            try {
                await currentLast;
            } finally {
                try {
                    resolve(await job());
                } catch (error) {
                    this.logger.error(`Error processing job in ${this.chainName} promise chain`, { error });
                    reject(error);
                }
            }
        });
        this.lastPromise = newLastPromise;
        return newLastPromise;
    }
}
