import { Form, Select, Input } from "antd";

import { withApolloQuery } from "HOC/withApolloQuery";
import { withSortedData } from "HOC/withSortedData";
import { getQueues } from "Services/marketing-api/tasks/queries";
import { QueryParams } from "Types/QueryParams";

type Queue = {
    sid: string;
    friendlyName: string;
    transferEligible: boolean;
};

interface QueueTransferProps extends Record<string, unknown> {
    data?: Queue[];
}

const defaultQueryParams: QueryParams = {
    pageSize: 25,
    page: 0,
    orderBy: [
        {
            key: "name",
            value: "ASC",
        },
    ],
    filterBy: [],
};

function QueueTransfer({ data }: QueueTransferProps): JSX.Element {
    const form = Form.useFormInstance();
    return (
        <>
            <Form.Item
                name="queueSid"
                style={{
                    display: "none",
                }}
            >
                <Input autoFocus size="large" />
            </Form.Item>
            <Form.Item
                name="queueName"
                style={{
                    display: "none",
                }}
            >
                <Input autoFocus size="large" />
            </Form.Item>
            <Form.Item name="internalQueueName">
                <Select
                    placeholder="Select a queue"
                    size="large"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onSelect={(value, option) => {
                        form.setFieldsValue({
                            queueSid: option.key,
                            queueName: value,
                        });
                    }}
                >
                    {(data || []).map(({ sid, friendlyName }) => (
                        <Select.Option key={sid} value={friendlyName}>
                            {friendlyName}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </>
    );
}

export function compareOptions(left: Queue, right: Queue): number {
    return left.friendlyName < right.friendlyName ? -1 : 1;
}

const SortedQueueTransfer = withSortedData(QueueTransfer, compareOptions);

export function transformData(responseData?: { queues: Queue[] }): Queue[] {
    return (responseData?.queues ?? []).filter((queue: Queue) => !!queue.transferEligible);
}

export const QueueInternalTransfer = withApolloQuery<
    QueueTransferProps,
    Queue[],
    { queues: Queue[] },
    { queryParams: QueryParams }
>(
    SortedQueueTransfer,
    getQueues,
    {
        fetchPolicy: "no-cache",
        variables: { queryParams: defaultQueryParams },
    },
    transformData
);
