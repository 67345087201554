import { useMemo } from "react";

export function withSortedData<TProps extends Record<string, unknown>, T>(
    Component: React.ComponentType<TProps>,
    compareFn: (a: T, b: T) => number
) {
    return function WithSortedDataHOC(props: TProps): React.ReactElement {
        const data = props.data as Array<T>;
        const sortedData = useMemo(() => (data || []).sort(compareFn), [data]);
        return <Component {...props} data={sortedData} />;
    };
}
