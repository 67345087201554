import { useFlags } from "Services/FeatureFlagService";

type OnFulfilledCallback = (response: Response) => Response | Promise<Response>;

export const fetchOptions: { interceptEnabled: boolean; onFetchCallback: OnFulfilledCallback } = {
    interceptEnabled: false,
    onFetchCallback: (r: Response) => r,
};

/**
 * Globally configures fetch interception as long as the feature flag is on. App should consume fetch Ponyfill for this to work.
 * @param onFetchCallback
 */
export function useFetchInterceptor(onFetchCallback: OnFulfilledCallback) {
    const { rolloutUiUnauthorizedInterception } = useFlags();
    fetchOptions.interceptEnabled = rolloutUiUnauthorizedInterception;
    fetchOptions.onFetchCallback = onFetchCallback;
}
