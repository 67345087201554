import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ManagerCallAction } from "@regal-voice/shared-types";
import { isNil } from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootReducerShape, ThunkAction } from "Services/state/Storage";
import { selectTasks } from "Services/state/tasks/Selectors";
import { CallStatusEvent } from "Services/Task.service";

export interface ParticipantInfo {
    callSid: string | undefined;
    conferenceFriendlyId: string | undefined;
    conferenceSid: string | undefined;
    phoneNumber: string | undefined;
    startTimestamp: number | undefined;
    status: CallStatusEvent | undefined;
    name?: string;
    type: "contact" | "external" | "internal" | "external-phonebook";
    onHold?: boolean;
    isMuted?: boolean;
    from: string;
    sequenceNo: string;
    to: string;
    taskSid: string;
    queueSid?: string;
    queueName?: string;
    managerAction?: ManagerCallAction;
}

export interface ConferenceEntry {
    deviceCallSid: string | undefined;
    connectionParams: Record<string, string | undefined>;
    conferenceFriendlyId: string | undefined;
    conferenceSid: string | undefined;
    isRecording: boolean | undefined;
    connected: boolean;
    participants: {
        [sid: string]: ParticipantInfo;
    };
    duration: number;
}

export type ConferenceState = {
    value: ConferenceEntry;
};

const initialState: ConferenceState = {
    value: {
        deviceCallSid: undefined,
        connectionParams: { taskSid: undefined },
        conferenceFriendlyId: undefined,
        conferenceSid: undefined,
        isRecording: undefined,
        connected: false,
        participants: {},
        duration: 0,
    },
};

const ConferenceStateSlice = createSlice({
    name: "activeConference",
    initialState,
    reducers: {
        setConference(state, action: PayloadAction<Partial<ConferenceEntry>>): ConferenceState {
            return {
                ...state,
                value: {
                    ...state.value,
                    deviceCallSid: action.payload.deviceCallSid ?? state.value.deviceCallSid,
                    conferenceFriendlyId: action.payload.conferenceFriendlyId,
                    conferenceSid: action.payload.conferenceSid,
                    participants: action.payload.participants,
                    connected: action.payload.connected,
                    isRecording: isNil(state.value.isRecording) ? action.payload.isRecording : state.value.isRecording,
                } as ConferenceEntry,
            };
        },
        setConferenceCallSid(state, action: PayloadAction<{ deviceCallSid: string }>): ConferenceState {
            return {
                ...state,
                value: {
                    ...state.value,
                    deviceCallSid: action.payload.deviceCallSid,
                } as ConferenceEntry,
            };
        },
        setConferenceRecording(state, action: PayloadAction<Partial<ConferenceEntry>>): ConferenceState {
            return {
                ...state,
                value: {
                    ...state.value,
                    isRecording: action.payload.isRecording,
                } as ConferenceEntry,
            };
        },
        setParticipantHold(state, action: PayloadAction<{ callSid: string; onHold?: boolean }>): ConferenceState {
            return {
                ...state,
                value: {
                    ...state.value,
                    participants: {
                        ...state.value.participants,
                        [action.payload.callSid]: {
                            ...state.value.participants[action.payload.callSid],
                            onHold: action.payload.onHold,
                        },
                    },
                } as ConferenceEntry,
            };
        },
        setConferenceConnectionSid(state, action: PayloadAction<{ connectionCallSid?: string }>) {
            state.value.deviceCallSid = action.payload.connectionCallSid;
        },
        setDeviceParams(state, action: PayloadAction<{ taskSid: string | undefined }>) {
            return {
                ...state,
                value: {
                    ...state.value,
                    connectionParams: action.payload,
                },
            };
        },
        setConferenceTaskSid(state, action: PayloadAction<{ taskSid?: string }>) {
            state.value.connectionParams = { ...state.value.connectionParams, ...action.payload };
        },
        setConferenceDuration(state, action: PayloadAction<{ duration: number }>) {
            state.value.duration = action.payload.duration;
        },
        setParticipantStatus: (
            state,
            action: PayloadAction<{ callSid: string; status: CallStatusEvent }>
        ): ConferenceState => {
            return {
                ...state,
                value: {
                    ...state.value,
                    participants: {
                        ...state.value.participants,
                        [action.payload.callSid]: {
                            ...state.value.participants[action.payload.callSid],
                            status: action.payload.status,
                        },
                    },
                } as ConferenceEntry,
            };
        },
        clearConference: () => {
            return initialState;
        },
    },
});

export const {
    actions: {
        clearConference,
        setConference,
        setConferenceCallSid,
        setConferenceTaskSid,
        setDeviceParams,
        setConferenceDuration,
        setConferenceRecording,
        setConferenceConnectionSid,
        setParticipantHold,
        setParticipantStatus,
    },
    name,
    reducer,
} = ConferenceStateSlice;

export function selectActiveCallSid({ conference }: RootReducerShape) {
    return conference[ConferenceStateSlice.name].value.deviceCallSid;
}

export function setActiveConferenceTask({
    id,
}: {
    id?: string;
}): ThunkAction<void, RootReducerShape, unknown, AnyAction> {
    return function thunk(
        dispatch: ThunkDispatch<RootReducerShape, unknown, AnyAction>,
        getState: () => RootReducerShape
    ): void {
        let taskSid;
        if (id) {
            const task = selectTasks(getState())[id];
            taskSid = task?.taskSid;
        }
        dispatch(setConferenceTaskSid({ taskSid }));
    };
}
