import {
    EmbedEventKey,
    EmbedMessagePayload,
    EmbedParentConfig,
    EmbedParentLayoutConfig,
    RVTask,
} from "@regal-voice/shared-types";

import { getLogger } from "Services/LoggingService";

import { selectAgentInformation } from "../state/agent/AgentInformationSlice";
import { reduxStore } from "../state/Storage";
import { isIframed } from "./EmbedUtilService";

const embeddedLogger = getLogger("Embedded Messaging");

const GENERIC_ORIGIN_CONFIG: EmbedParentConfig = {
    key: "generic",
    origins: ["*"],
    generateMessagePayload: (task, eventKey) => ({
        source: "regal",
        action: eventKey,
        contactPhone: task.attributes?.contactPhone,
        contactEmail: task.attributes?.email,
        externalId: task.attributes?.externalId,
        profileId: task.attributes?.profileId,
        taskType: task.attributes?.taskType,
    }),
    hideNavigation: false,
    hideActionablePanels: false,
    hideDetailsPanel: false,
    hideLogo: false,
    hideAgentSettings: false,
};

const ORIGIN_CONFIGS: EmbedParentConfig[] = [
    {
        key: "salesforce",
        origins: ["force.com"],
        generateMessagePayload: (task, eventKey) => ({
            source: "regal",
            objectId: task.attributes?.relatedObjectId,
            profileLink: task.attributes?.salesforceLink,
            action: eventKey,
            taskType: task.attributes?.taskType,
            contactPhone: task.attributes?.contactPhone,
            contactEmail: task.attributes?.email,
            externalId: task.attributes?.externalId,
            profileId: task.attributes?.profileId,
        }),
        hideNavigation: true,
        hideActionablePanels: false,
        hideDetailsPanel: true,
        hideLogo: false,
        hideAgentSettings: false,
    },
    {
        key: "retool",
        origins: ["retool-edge.com"],
        generateMessagePayload: (task, eventKey) => {
            const agentInformation = selectAgentInformation(reduxStore.getState());
            return {
                source: "regal",
                contactPhone: task.attributes?.contactPhone,
                contactEmail: task.attributes?.email,
                externalId: task.attributes?.externalId,
                agentEmail: agentInformation?.email,
                profileId: task.attributes?.profileId,
                taskType: task.attributes?.taskType,
                action: eventKey,
            };
        },
        hideNavigation: false,
        hideActionablePanels: false,
        hideDetailsPanel: false,
        hideLogo: false,
        hideAgentSettings: false,
    },
    {
        key: "kustomer",
        origins: ["kustomerapp.com"],
        generateMessagePayload: (task, eventKey) => {
            const isIncoming = task.attributes?.direction === "inbound";
            const [fromNumber, toNumber] = isIncoming
                ? [task.attributes?.contactPhone, task.attributes?.regalVoicePhone]
                : [task.attributes?.regalVoicePhone, task.attributes?.contactPhone];
            return {
                source: "regal",
                action: eventKey,
                contactPhone: task.attributes?.contactPhone,
                contactEmail: task.attributes?.email,
                externalId: task.attributes?.externalId,
                profileId: task.attributes?.profileId,
                taskType: task.attributes?.taskType,
                fromNumber,
                toNumber,
            };
        },
        hideNavigation: true,
        hideActionablePanels: true,
        hideDetailsPanel: true,
        hideLogo: true,
        hideAgentSettings: true,
    },
];

let currentEmbedParentConfig = getEmbedParentConfig();
let currentMatchingAncestorOrigins = getMatchingAncestorOrigins();

/**
 * ONLY FOR TESTING. DO NOT USE.
 */
export function __setInitialEmbedParentConfigs__() {
    currentEmbedParentConfig = getEmbedParentConfig();
    currentMatchingAncestorOrigins = getMatchingAncestorOrigins();
}

/**
 * Matches preset config based on the parent container's origins. If no match is found, returns a generic config.
 */
function getEmbedParentConfig() {
    const ancestorOrigins = getAncestorOrigins();
    return (
        ORIGIN_CONFIGS.find(({ origins }) =>
            origins.some((configOrigin) =>
                ancestorOrigins.some((ancestorOrigin) => ancestorOrigin.includes(configOrigin))
            )
        ) ?? GENERIC_ORIGIN_CONFIG
    );
}

/**
 * Gets all ancestor origins that match our embed config (potential destinations for messages).
 * We also blanket match any regal domain to support posting to our Embed Adapter or Playground.
 */
function getMatchingAncestorOrigins() {
    const origins = getAncestorOrigins();
    if (currentEmbedParentConfig.key === "generic") {
        return origins;
    }

    return origins.filter((origin) =>
        currentEmbedParentConfig.origins.some(
            (configOrigin) => origin.includes(configOrigin) || origin.includes("regal.io")
        )
    );
}

export function getAncestorOrigins() {
    // check ancestorOrigin domains to get the parent container url
    return Array.from(window.location.ancestorOrigins || []);
}

export function isIframedInSalesforce() {
    return isIframed() && currentEmbedParentConfig.key === "salesforce";
}

/**
 * If the app is iframed, it will post a task-based message to any matching configured parent containers.
 */
export function postMessageToParentWithTaskIfNeeded(task: RVTask, eventKey: EmbedEventKey): void {
    if (isIframed()) {
        const messagePayload = currentEmbedParentConfig.generateMessagePayload(task, eventKey);
        postMessageToParentIfNeeded(messagePayload);
    }
}

/**
 * If the app is iframed, it will post a status-based message to any matching configured parent containers.
 */
export function postMessageToParentWithStatusIfNeeded(status: string): void {
    if (isIframed()) {
        const messagePayload = {
            source: "regal",
            action: "status-changed",
            status,
        } as const;
        postMessageToParentIfNeeded<"status-changed">(messagePayload);
    }
}

/**
 * If the app is iframed, it will post a manager action-based message to any matching configured parent containers.
 */
export function postMessageToParentWithManagerActionIfNeeded<T extends "started-barging" | "started-listening">(
    data: Omit<EmbedMessagePayload<T>, "source" | "action">,
    eventKey: T
): void {
    if (isIframed()) {
        const messagePayload = {
            source: "regal",
            action: eventKey,
            ...data,
        } as const;
        postMessageToParentIfNeeded(messagePayload);
    }
}

/**
 * If the app is iframed, given a message, posts it to all matching configured parent containers.
 */
export function postMessageToParentIfNeeded<T extends EmbedEventKey>(message: EmbedMessagePayload<T>): void {
    if (isIframed()) {
        currentMatchingAncestorOrigins.forEach((origin) => {
            window.parent.postMessage(message, origin);
        });
    }
}
export type EmbedParentListeners = {
    changeStatusCallback: (data: EmbedMessagePayload<"change-status">) => void;
    createTaskCallback: (data: EmbedMessagePayload<"create-task">) => Promise<void>;
};

/**
 * Handles messages from parent containers. Currently supports creating tasks and changing agent status.
 */
function generateParentListenerHandler({ changeStatusCallback, createTaskCallback }: EmbedParentListeners) {
    return function <T extends EmbedEventKey>(event: MessageEvent<EmbedMessagePayload<T>>) {
        if (currentMatchingAncestorOrigins.includes(event.origin)) {
            const { data } = event;
            if (!data) {
                embeddedLogger.warn("Unsupported message content", event);
                return;
            }
            const { action } = data;
            switch (action) {
                case "create-task":
                    createTaskCallback(data as EmbedMessagePayload<"create-task">);
                    break;
                case "change-status":
                    changeStatusCallback(data as EmbedMessagePayload<"change-status">);
                    break;
                default:
                    embeddedLogger.warn("Unhandled message from parent", data);
            }
        }
    };
}

export function setupParentListenersIfNeeded({ changeStatusCallback, createTaskCallback }: EmbedParentListeners) {
    if (isIframed()) {
        const listenerFn = generateParentListenerHandler({ changeStatusCallback, createTaskCallback });
        window.addEventListener("message", listenerFn);
        return () => window.removeEventListener("message", listenerFn);
    }
}

// ==============================
// UI LAYOUT CONFIGS
// ==============================

/**
 * Will return the value for the given UI Layout Config only if the app is iframed.
 * For now keeping it simple with boolean values.
 */
export function getEmbedUiConfigByKey(key: keyof EmbedParentLayoutConfig): boolean {
    return isIframed() && currentEmbedParentConfig[key];
}
