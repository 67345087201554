import type { RVTask } from "@regal-voice/shared-types";

export type UseCallTitleOptions = {
    participant?: { name?: string; phoneNumber: string };
    task?: RVTask;
};

export const useCallTitle = ({ participant, task }: UseCallTitleOptions): string => {
    if (!participant || !task) {
        return "";
    }

    const { name, phoneNumber } = participant;
    const displayName = name || phoneNumber;
    const taskTitle: string = task.attributes?.title || "";
    return taskTitle.match(/incoming/i) ? `${taskTitle} from ${displayName}` : `${taskTitle} to ${displayName}`;
};
