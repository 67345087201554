import { useCallback } from "react";

import { Tooltip } from "antd";
import { clsx } from "clsx";
import { useSelector } from "react-redux";

import Icon, { IconSizes } from "Components/elements/Icon";
import { setConferenceDuration } from "Services/state/conferences/ConferenceStateSlice";
import { selectDeviveCallConnected, selectActiveConferenceTask } from "Services/state/conferences/Selectors";
import { useRVDispatch } from "Services/state/Storage";

import {
    useNavigateToTask,
    useRecordCall,
    useOtherParticipants,
    useMainParticipant,
    useLineInfo,
    useCallTitle,
} from "../hooks";
import { CallStatus, type CallStatusUpdateDurationFn } from "./CallStatus";

import styles from "../CallBar.module.scss";

export type CallTaskInfoProps = {
    onInfoUnavailable?: () => void;
    updateDuration?: boolean;
};

export const CallTaskInfo = ({ onInfoUnavailable, updateDuration }: CallTaskInfoProps): JSX.Element => {
    const dispatch = useRVDispatch();
    const task = useSelector(selectActiveConferenceTask);
    const [isRecording] = useRecordCall();
    const connected = useSelector(selectDeviveCallConnected);
    const lineInfo = useLineInfo({ task });
    const navigateToTask = useNavigateToTask({ task });
    const otherParticipants = useOtherParticipants({ task });
    const participant = useMainParticipant({ participants: otherParticipants });
    const callTitle = useCallTitle({ participant, task });

    const dispatchDuration = useCallback<CallStatusUpdateDurationFn>(
        (newDuration) => {
            if (!updateDuration) {
                return;
            }

            dispatch(setConferenceDuration({ duration: newDuration }));
        },
        [dispatch, updateDuration]
    );

    if (!task || !lineInfo) {
        onInfoUnavailable?.();
        return <span className={styles.textTitle}>Information Unavailable</span>;
    }

    return (
        <>
            <span className={clsx(styles.textTitle, styles.titleClassName)}>
                <Tooltip title={callTitle} placement="top">
                    <button type="button" className={styles.textActionTitle} onClick={navigateToTask}>
                        {callTitle}
                    </button>
                </Tooltip>
                {" | "}
                <CallStatus activeConferenceTask={task} updateDuration={dispatchDuration} />
            </span>
            {connected && (
                <span>
                    {isRecording ? (
                        <>
                            <Icon icon="resume-recording" size={IconSizes.XXSMALL} color="red-4" /> Recording
                        </>
                    ) : (
                        "Recording stopped"
                    )}
                </span>
            )}
        </>
    );
};
