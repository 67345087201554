import { RVTask } from "@regal-voice/shared-types";

import usePersistUserInput from "Hooks/usePersistUserInput";
import { InputPersistNamespaces } from "Services/state/input-persist/IndexProvider";
import { useRVDispatch } from "Services/state/Storage";
import { clearReservationBySid } from "Services/state/tasks/Thunks";

export type TaskSummary = {
    disposition: string | undefined;
    objections: Array<string> | undefined;
    notes: string | undefined;
};

export function useTaskSummaryPersistence(activeTask?: RVTask): {
    value: TaskSummary;
    setValue: (input: TaskSummary) => void;
    clearTaskSummary: () => void;
} {
    const { value, setValue, clearPersistedInput } = usePersistUserInput({
        namespace: InputPersistNamespaces.TASK_SUMMARIES,
        key: activeTask?.sid || "",
        defaultValue: {
            disposition: undefined,
            objections: undefined,
            notes: undefined,
        } as TaskSummary,
    });

    return { value, setValue, clearTaskSummary: clearPersistedInput };
}

export function useRemoveTask(): (sid: string) => void {
    const dispatch = useRVDispatch();
    return function (sid: string) {
        dispatch(clearReservationBySid(sid));
    };
}
