import { useCallback } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";

import { setSubPage, AgentDesktopSubPages } from "Services/state/agent/desktopUI/AgentDesktopUISlice";
import { useRVDispatch } from "Services/state/Storage";
import { setActiveTask } from "Services/state/tasks/Thunks";

import type { RVTask } from "@regal-voice/shared-types";

export type UseNavigateToTaskOptions = {
    task?: RVTask;
};

export type UseNavigateToTaskResult = () => void;

export const useNavigateToTask = ({ task }: UseNavigateToTaskOptions = {}): UseNavigateToTaskResult => {
    const dispatch = useRVDispatch();
    const router = useHistory();
    const isAgentDesktopPage = useRouteMatch({
        path: "/agent",
        exact: true,
    });

    return useCallback(() => {
        if (!task) {
            return;
        }
        if (!isAgentDesktopPage) {
            router.push(`/agent?sid=${task.sid}`);
        }
        dispatch(setSubPage(AgentDesktopSubPages.TASKS_PAGE));
        dispatch(setActiveTask(task));
    }, [task, isAgentDesktopPage, router, dispatch]);
};
