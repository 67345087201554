import { fetchOptions } from "App/main/useFetchInterceptor";
import { isClientLoggingOut, kickOut } from "Services/AuthService";
import { getLogger } from "Services/LoggingService";

const logger = getLogger("NetworkUtilService");

export function isUnauthenticatedResponse(statusCode: number): boolean {
    return statusCode === 403 || statusCode === 401;
}

/**
 * This callback verifies if a response is unauthorized and kicks the user out if it is.
 */
export const interceptUnauthorizedResponses = async (response: Response) => {
    if (isUnauthenticatedResponse(response.status) && !isClientLoggingOut()) {
        logger.log("Request returned unauthenticated status code, kicking user to login", {
            statusCode: response.status,
        });
        await kickOut();
    }
    return response;
};

/**
 * We wrap the fetch function to intercept and handle any HTTP response. The app should always use this Ponyfill instead of the window's fetch.
 * @param args regular fetch arguments
 * @returns exact same fetch API, but the configured fetch callback will be executed before the promise is returned
 */
export const fetch: typeof window.fetch = (...args) =>
    fetchOptions.interceptEnabled ? window.fetch(...args).then(fetchOptions.onFetchCallback) : window.fetch(...args);
