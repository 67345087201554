import { useEffect, useMemo, useRef } from "react";

import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { selectConference } from "Services/state/conferences";
import { CallStatusEvent, isPendingInboundConferenceTask } from "Services/Task.service";

import { useOtherParticipants, useMainParticipant } from "../hooks";

import type { RVTask } from "@regal-voice/shared-types";

const formatDuration = (duration: number): string => {
    const formatted = dayjs.duration(duration).format("HH:mm:ss");
    const [hr, min, sec] = formatted.split(":");
    if (hr === "00") {
        return `${min}:${sec}`;
    }

    return formatted;
};

export type CallStatusUpdateDurationFn = (newDuration: number) => void;

export type CallStatusProps = {
    activeConferenceTask?: RVTask;
    updateDuration?: CallStatusUpdateDurationFn;
};

export function CallStatus({ activeConferenceTask, updateDuration }: CallStatusProps): JSX.Element {
    const participants = useOtherParticipants({ task: activeConferenceTask });
    const participant = useMainParticipant({ participants })?.participant;
    const { duration } = useSelector(selectConference);
    const connected = participant?.status == CallStatusEvent.IN_PROGRESS;
    const elementRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<number>(0);
    const updateDurationRef = useRef(updateDuration);

    const statusText = useMemo(() => {
        if (!participants?.length) {
            return activeConferenceTask && isPendingInboundConferenceTask(activeConferenceTask)
                ? "Ringing..."
                : "Connecting...";
        }

        const status = participant?.status;

        if (status === "ringing" || status == "queued") {
            return "Ringing...";
        }

        if (status === "in-progress") {
            return "00:00";
        }

        return "Connecting...";
    }, [activeConferenceTask, participants, participant]);

    useEffect(() => {
        updateDurationRef.current = updateDuration;
    }, [updateDuration]);

    useEffect(() => {
        if (!connected) {
            updateDurationRef.current?.(0);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = 0;
            }
        } else if (duration === 0) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = 0;
            }

            const intervalFn = () => {
                const startTimestamp = participant?.startTimestamp;
                if (typeof startTimestamp !== "number") {
                    return;
                }

                const newDuration = dayjs().unix() - startTimestamp;
                updateDurationRef.current?.(newDuration);
                const target = elementRef.current;
                if (!target) {
                    return;
                }

                target.innerText = formatDuration(newDuration * 1000);
            };

            intervalRef.current = window.setInterval(intervalFn, 1000);
        }
    }, [connected, duration, participant]);

    return <span ref={elementRef}>{statusText}</span>;
}
