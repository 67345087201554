export const LEGACY_CHAT_CHANNEL = "sms";
export const LEGACY_VOICE_CHANNEL = "voice";

// These may eventually be consolidated
export const CK_CHAT_CHANNEL = "regal";
export const CK_VOICE_CHANNEL = "conferences";
export const PRIORITY_CHANNEL = "priority";
export const DEFAULT_CHANNEL = "default";
export const EMAIL_CHANNEL = "email";
export const CUSTOM_TASKS_CHANNEL = "custom-tasks";
export const VOICEMAIL_CHANNEL = "voicemail";

/**
 * Channels to be evaluated in task list in order to decide if the task is a call task
 */
export const TASK_LIST_EVALUATE_CALL_CHANNELS = [
    LEGACY_VOICE_CHANNEL,
    CK_VOICE_CHANNEL,
    DEFAULT_CHANNEL,
    PRIORITY_CHANNEL,
];
/**
 * Channels that are allowed to trigger the creation of the calls and conferences
 */
export const CHANNELS_TO_TRIGGER_OUTBOUND_CALL_CREATION = [PRIORITY_CHANNEL, CK_VOICE_CHANNEL];

/**
 * Channels to be evaluated when deciding if the task if an inbound task
 * @param DEFAULT_CHANNEL - evaluate inbound tasks
 * @param CK_VOICE_CHANNEL - evaluate inbound tasks
 */
export const CHANNELS_TO_EVALUATE_INBOUND_TASK = [DEFAULT_CHANNEL, CK_VOICE_CHANNEL, PRIORITY_CHANNEL];

/**
 * Defines how long (in ms) will the UI wait for a specific reservation to come through
 */
export const AGENT_WAIT_FOR_TASK_THRESHOLD = 5000;

export const CONTACT_COMMUNICATION_MESSAGES = {
    NO_PHONE_ON_CONTACT_CALL: "Add a phone number to the contact to make calls",
    NO_PHONE_ON_CONTACT_SMS: "Add a phone number to the contact to send a text",
    AGENT_OFFLINE: "Set yourself to an available status to make a call",
    START_CALL: "Start a Call",
    ACCEPT_TASK_FOR_SMS: "Please accept the task to start messaging.",
};
