import { createContext, useContext, useState, useCallback, useMemo } from "react";

import { getLogger } from "Services/LoggingService";

export type CollapsiblePanelConfig = {
    expanded: boolean;
};

export type CollapsiblePanelsContextValue = {
    panels: Record<string, CollapsiblePanelConfig>;
    togglePanel: (panelName: string) => void;
    clear: () => void;
};

export const COLLAPSIBLE_PANELS_STORAGE_KEY = "regal-toggleable-panels";
export const COLLAPSIBLE_PANELS_STORAGE = {
    read: (): CollapsiblePanelsContextValue["panels"] => {
        const stored = localStorage.getItem(COLLAPSIBLE_PANELS_STORAGE_KEY);
        return stored ? JSON.parse(stored) : {};
    },
    update: (panels: CollapsiblePanelsContextValue["panels"]): CollapsiblePanelsContextValue["panels"] => {
        localStorage.setItem(COLLAPSIBLE_PANELS_STORAGE_KEY, JSON.stringify(panels));
        return panels;
    },
    clear: (): void => {
        localStorage.removeItem(COLLAPSIBLE_PANELS_STORAGE_KEY);
    },
};

export const CollapsiblePanelsContext = createContext<CollapsiblePanelsContextValue>({
    panels: {},
    togglePanel: () => {
        // Do nothing.
    },
    clear: () => {
        // Do nothing.
    },
});

const logger = getLogger("CollapsiblePanels");

export const CollapsiblePanelsProvider = ({ children }: React.PropsWithChildren) => {
    const [panels, setPanels] = useState<Record<string, CollapsiblePanelConfig>>(COLLAPSIBLE_PANELS_STORAGE.read);

    const togglePanel = useCallback(
        (panelName: string) => {
            setPanels((prev) => {
                let newState: CollapsiblePanelsContextValue["panels"];
                if (prev[panelName]) {
                    const prevConfig = prev[panelName];
                    const expanded = !prevConfig.expanded;
                    newState = {
                        ...prev,
                        [panelName]: {
                            ...prevConfig,
                            expanded,
                        },
                    };
                    if (expanded) {
                        logger.action(`Panel ${panelName} expanded`);
                    } else {
                        logger.action(`Panel ${panelName} collapsed`);
                    }
                } else {
                    newState = {
                        ...prev,
                        [panelName]: {
                            expanded: false,
                        },
                    };
                    logger.action(`Panel ${panelName} collapsed`);
                }

                return COLLAPSIBLE_PANELS_STORAGE.update(newState);
            });
        },
        [setPanels]
    );

    const clear = useCallback(() => {
        COLLAPSIBLE_PANELS_STORAGE.clear();
        setPanels({});
    }, [setPanels]);

    const context = useMemo(() => ({ panels, togglePanel, clear }), [panels, togglePanel, clear]);

    return <CollapsiblePanelsContext.Provider value={context}>{children}</CollapsiblePanelsContext.Provider>;
};

export type UseCollapsiblePanelResult = {
    collapsed: boolean;
    toggle: () => void;
};

export const useCollapsiblePanel = (panelName: string): UseCollapsiblePanelResult => {
    const { panels, togglePanel } = useContext(CollapsiblePanelsContext);
    const collapsed = panels[panelName]?.expanded === false;
    const toggle = useCallback(() => togglePanel(panelName), [panelName, togglePanel]);

    return { collapsed, toggle };
};
