import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NullOr } from "@regal-voice/shared-types";
import { uniq } from "lodash";

import { EnhancedRecordingMoment } from "Types/Transcripts";

export type TranscriptionInteractionState = {
    error: NullOr<string>;
    loading: boolean;

    filteredMoments: EnhancedRecordingMoment[];
    searchTerms: string[];
    searchTrackers: string[];
    autoScroll: boolean;
    currentMomentId: NullOr<string>;
};

export const initialState: TranscriptionInteractionState = {
    loading: false,
    error: null,
    filteredMoments: [],
    searchTerms: [],
    searchTrackers: [],
    autoScroll: true,
    currentMomentId: null,
};

const TranscriptionInteractionStateSlice = createSlice({
    name: "transcriptions",
    initialState,
    reducers: {
        setError(state, action: PayloadAction<NullOr<string>>) {
            state.error = action.payload;
            state.loading = false;
        },
        setFilteredMoments(state, action: PayloadAction<EnhancedRecordingMoment[]>) {
            state.filteredMoments = action.payload;
        },
        setSearchTerms(state, action: PayloadAction<string[]>) {
            state.searchTerms = action.payload;
        },
        setSearchTrackers(state, action: PayloadAction<string[]>) {
            state.searchTrackers = uniq(action.payload);
        },
        setCurrentMomentIndex(state, action: PayloadAction<NullOr<string>>) {
            state.currentMomentId = action.payload;
        },
        setAutoScroll(state, action: PayloadAction<boolean>) {
            state.autoScroll = action.payload;
        },
        clearTranscriptionInteractions() {
            return { ...initialState };
        },
    },
});

export const {
    setFilteredMoments,
    clearTranscriptionInteractions,
    setSearchTerms,
    setSearchTrackers,
    setError,
    setAutoScroll,
    setCurrentMomentIndex,
} = TranscriptionInteractionStateSlice.actions;
export default TranscriptionInteractionStateSlice.reducer;
