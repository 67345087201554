import { MessageType, SSE } from "@regal-voice/shared-types";
import { notification } from "antd";

import { getLogger } from "Services/LoggingService";
import { getApiUrl } from "Services/ProxyConfig";
import { selectBrand } from "Services/state/brand";
import { reduxStore } from "Services/state/Storage";

import ErrorMessage from "./ErrorMessage";
import eventEmitter from "./EventEmitter";
import MonitorAndRetryStrategy from "./MonitorRetryStrategy";

let source: EventSource | undefined;

// TODO: put in shared types
const HEARTBEAT_EVENT = "ping";
const SERVER_ERROR_EVENT = "server.error";

const logger = getLogger("Server Events");
export enum SEEConnectionState {
    CONNECTING = 0,
    OPEN = 1,
    CLOSED = 2,
}

function isActive() {
    const isActive =
        source?.readyState === SEEConnectionState.CONNECTING || source?.readyState === SEEConnectionState.OPEN;
    return isActive;
}

function close() {
    source?.close();
}

function checkIfShouldCloseConnection() {
    return window.location.pathname === "/login";
}

function isServerThrownError(event: MessageEvent): boolean {
    return event.type == "error" && event.data;
}

function handleOpen() {
    logger.log("SSE Connected", { sourceUrl: source?.url });
    eventEmitter.emit("connection.open");
}

function handleServerError(event: { type: string; data?: string }): void {
    logger.error(`SSE Server Error: `, { data: event.data });
}

function reportHardFailure() {
    notification.warning({
        message: "Warning",
        description: <ErrorMessage />,
        duration: 0,
        placement: "topRight",
        top: 80,
        key: "sse",
    });
}

const monitorRetryStrategy = new MonitorAndRetryStrategy({
    isActive,
    init,
    disconnect,
    close,
    reportHardFailure,
});

async function handleError(event: { type: string; data?: string }) {
    // TODO: Remove this after the `server.error` event type has been shipped
    if (isServerThrownError(event as MessageEvent)) {
        handleServerError(event as MessageEvent);
        return;
    }

    logger.error(`SSE Error: `, { data: event.data });
    await monitorRetryStrategy.ensureRetry();
}

function handleMessage({ lastEventId: id, data: payload }: MessageEvent) {
    monitorRetryStrategy.resetToInitialState();

    if (checkIfShouldCloseConnection()) {
        disconnect();
        return;
    }

    const { event, data } = JSON.parse(payload) as {
        event: string;
        data: SSE.Identifiers &
            (MessageType["sse"][keyof MessageType["sse"]] | MessageType["twilio"][keyof MessageType["twilio"]]);
        sentIds: string[];
    };

    event && eventEmitter.emit(event, data);
    logger.debug(`Received SSE Message ${id}: ${event}`);
}

export async function init(): Promise<EventSource | undefined> {
    try {
        return initSSE();
    } catch (e) {
        logger.error("Unable to initialize SSE connection", { sseError: e });
    }
}

const addListenersToSource = (source: EventSource) => {
    source.onopen = handleOpen;
    source.onerror = handleError;
    source.onmessage = handleMessage;

    const handlers = {
        [HEARTBEAT_EVENT]: monitorRetryStrategy.monitorHeartbeats,
        [SERVER_ERROR_EVENT]: handleServerError,
    };

    for (const [key, value] of Object.entries(handlers)) {
        source.addEventListener(key, value);
    }

    return source;
};

const initSSEForCookie = () => {
    // if the connection closed due to an error reconnect
    if (isActive()) {
        logger.log("initSSE returning early becase SSE source state is not closed", {
            soureState: source?.readyState,
            usingCookie: true,
        });
        return;
    }

    logger.log("SSE Connection was closed. Reconnecting.", {
        expTime: "No token",
        usingCookie: true,
    });
    // Close any existing connection so the server can cleanup
    close();

    // EventSources can't have headers, so we cannot leverage the existing flow to configure access
    // bug bash clean up, no idea why we are asserting here
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const brandInfo = selectBrand(reduxStore.getState())!;
    source = new EventSource(`${getApiUrl()}/sse/${brandInfo.slug}`, { withCredentials: true });
    return addListenersToSource(source);
};

export function initSSE(): EventSource | undefined {
    return initSSEForCookie();
}

// use on logout
export function disconnect(): void {
    monitorRetryStrategy.clearAllTimeouts();
    close();
    eventEmitter.removeAllListeners();
    logger.log("SSE Disconnected.");
}
