import { useEffect, useContext, useMemo } from "react";

import { debounce } from "lodash";
import { useSelector } from "react-redux";

import { UserContext } from "App/contexts";
import { useServerSentEvent } from "Hooks/useServerSentEvent";
import { getLogger } from "Services/LoggingService";

import { selectBrandAdminSettings, selectBrandAdminSetting } from "./selectors";

import type { AdminSetting } from "Types/Brand";

const logger = getLogger("Admin settings");

const resolveSettingValue = (setting: AdminSetting, variant?: string): Record<string, unknown> => {
    const { defaults, overrides } = setting;
    if (!variant || !overrides[variant]) {
        return defaults;
    }

    const override = overrides[variant];
    return {
        ...defaults,
        ...override,
    };
};

export type UseBrandAdminSettingOptions = {
    name: string;
    variant?: string;
};

export const useBrandAdminSetting = ({ name, variant }: UseBrandAdminSettingOptions): Record<string, unknown> => {
    const setting = useSelector(selectBrandAdminSetting)(name);
    if (!setting) {
        logger.warn(`Setting ${name} not found`);
        return {};
    }

    return resolveSettingValue(setting, variant);
};

let globalAdminSettings: Record<string, AdminSetting> = {};

/**
 * This is a component and not a hook so it can access the user context and can be implemented
 * on a top level container.
 */
export const BrandAdminSettingsLoader = () => {
    const settings = useSelector(selectBrandAdminSettings);
    useEffect(() => {
        globalAdminSettings = settings;
    }, [settings]);
    const { reloadBrand } = useContext(UserContext);
    const debouncedReloadBrand = useMemo(() => debounce(reloadBrand, 1000, { leading: true }), [reloadBrand]);

    useServerSentEvent("sse", "brand.adminSettingsUpdated", debouncedReloadBrand);

    return null;
};

export const getBrandAdminSetting = ({ name, variant }: UseBrandAdminSettingOptions): Record<string, unknown> => {
    if (!globalAdminSettings[name]) {
        logger.warn(`Setting ${name} not found`);
        return {};
    }

    return resolveSettingValue(globalAdminSettings[name], variant);
};
