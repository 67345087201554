/**
 * These are selectors that are reliant on the tasks slice of state.
 * They needed to move to their own file to get around a circular dependency issue
 * between the tasks and contacts slices. The selectActiveTask depends on the contacts slice
 */
import { createSelector } from "@reduxjs/toolkit";
import { RVTask } from "@regal-voice/shared-types";
import { memoize } from "lodash";

import { selectActiveConferenceTask } from "Services/state/conferences";

import { createContactFromTask } from "../utils";
import { contactsSelectors } from "./Selectors";

/**
 * Returns the contact associated to the given task by pulling it from redux.
 * If it hasn't been fetched yet, it creates one from the task attributes.
 */
export const selectTaskContact = createSelector(contactsSelectors.selectEntities, (allContacts) =>
    memoize(
        (task: RVTask) => {
            const contactId = task.attributes.profileId;
            const contact = allContacts[contactId];
            if (contact) {
                return contact;
            }
            return createContactFromTask({ id: contactId, task });
        },
        (task) => task.taskSid
    )
);

/**
 * Returns the contact associated to the active call task.
 * If it hasn't been fetched yet, it creates a contact from the task attributes.
 */
export const selectActiveCallTaskContact = createSelector(
    selectActiveConferenceTask,
    contactsSelectors.selectEntities,
    (task, allContacts) => {
        if (!task) {
            return createContactFromTask({}); // return empty contact momentarily
        }
        const contactId = task.attributes.profileId;
        let contact = allContacts[contactId];
        if (!contact) {
            contact = createContactFromTask({ id: contactId, task });
        }
        return contact;
    }
);
