import { useMemo } from "react";

import { orderBy } from "lodash";
import { useSelector } from "react-redux";

import { useExternalTransferPhoneNumbers } from "Pages/settings/ExternalTransferPhonebook/Hooks/useExternalTransferPhoneNumbers";
import { selectWorkerUri } from "Services/state/agent/AgentInformationSlice";
import { selectDeviceCallParticipants } from "Services/state/conferences/Selectors";
import { CallStatusEvent } from "Services/Task.service";
import { ExternalPhoneNumber } from "Types/ExternalPhoneNumber";

import type { RVTask } from "@regal-voice/shared-types";
import type { ParticipantInfo } from "Services/state/conferences";

export type UseOtherParticipantsOptions = {
    task?: RVTask;
};

export const useOtherParticipants = ({ task }: UseOtherParticipantsOptions = {}): ParticipantInfo[] => {
    const workerUri = useSelector(selectWorkerUri);
    const participants = useSelector(selectDeviceCallParticipants);
    const { phoneNumbersData } = useExternalTransferPhoneNumbers({ isManagerContext: false });

    return useMemo(() => {
        let result: ParticipantInfo[];
        if (participants) {
            const workerNumber = `client:${workerUri}`;
            result = Object.values(participants).filter(
                (participant) => participant.phoneNumber !== workerNumber && participant.managerAction !== "listen"
            );
        } else {
            result = [];
        }

        if (result.length) {
            result = result.map((participant) => {
                const externalPhonebookParticipant = phoneNumbersData?.find(
                    (externalNumber: ExternalPhoneNumber) => externalNumber.phoneNumber === participant.phoneNumber
                );
                if (externalPhonebookParticipant) {
                    return {
                        ...participant,
                        name: externalPhonebookParticipant.name,
                        type: "external-phonebook",
                    };
                }
                return participant;
            });
            return orderBy(result, ["type"], ["asc"]);
        }

        if (!task?.attributes) {
            return result;
        }

        const taskName: string = task.attributes?.name || "";
        const taskPhoneNumber: string = task.attributes?.contactPhone || "";

        return [
            /**
             * @todo Validate this, this object is not compatible with ParticipantInfo
             */
            {
                name: taskName,
                phoneNumber: taskPhoneNumber,
                type: "contact",
                status: CallStatusEvent.QUEUED,
                callSid: undefined,
                onHold: undefined,
                startTimestamp: undefined,
            } as ParticipantInfo,
        ];
    }, [participants, workerUri, task, phoneNumbersData]);
};
