import { createAsyncThunk } from "@reduxjs/toolkit";

import { apolloMutation } from "Services/ApolloClientService";
import { getLogger } from "Services/LoggingService";
import { updateAgent } from "Services/marketing-api/agents/mutations";

import { selectBrandAvailableStatuses } from "../brand";
import { RootState } from "../Storage";
import { selectAgentInformation } from "./AgentInformationSlice";

const logger = getLogger("Agent Information Thunks");

export const setAgentOffline = createAsyncThunk<void, void, { state: RootState }>(
    "agent/setOffline",
    async (_, { getState }) => {
        const state = getState();
        const agentInformation = selectAgentInformation(state);
        if (!agentInformation) {
            logger.error("Could not set agent to be offline because agent information was not found");
            return;
        }

        const availableStatuses = selectBrandAvailableStatuses(state);
        const { status: currentStatusName, workerSid } = agentInformation;

        if (currentStatusName) {
            const currentActivity = availableStatuses.find((status) => status.name === currentStatusName);
            if (currentActivity && !currentActivity.available) {
                return;
            }
        }

        let offlineActivity = availableStatuses.find((status) => status.name === "Offline");

        if (!offlineActivity) {
            offlineActivity = availableStatuses.find((status) => !status.available);
        }

        if (!offlineActivity) {
            logger.error("Could not set agent to be offline because an offline status was not be found", {
                availableStatuses,
            });
            return;
        }

        try {
            await apolloMutation({
                mutation: updateAgent,
                variables: {
                    updateUserData: {
                        status: offlineActivity.name,
                        twilioSid: workerSid,
                    },
                },
            });
        } catch (error) {
            logger.error("Error attempting to update agent status to offline", {
                error,
                twilioWorkerSid: workerSid,
                status: offlineActivity.name,
            });
            return;
        }
    }
);
