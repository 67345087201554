import { createSelector } from "@reduxjs/toolkit";

import { RootReducerShape } from "../Storage";

export const SelectRootInputPersistState = (state: RootReducerShape) => state.inputPersist;

export const selectTextMessageInputPersistState = createSelector(
    SelectRootInputPersistState,
    (inputPersistState) => inputPersistState.textMessageInput
);

export const selectEmailInputPersistState = createSelector(
    SelectRootInputPersistState,
    (inputPersistState) => inputPersistState.emailInput
);

export const selectTaskSummaryPersistState = createSelector(
    SelectRootInputPersistState,
    (inputPersistState) => inputPersistState.taskSummaries
);

export const selectActiveContactPanelTabInputPersistState = createSelector(
    SelectRootInputPersistState,
    (inputPersistState) => inputPersistState.activeContactPanelTab
);
