import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";

import { getLogger } from "Services/LoggingService";

import type { NullOr, BrandSettings } from "@regal-voice/shared-types";
import type { Brand, BrandSettingDefinition } from "Types/Brand";

type BrandState = {
    brand: NullOr<Brand>;
};

const initialState: BrandState = {
    brand: null,
};

const logger = getLogger("Brand redux");

const BrandStateSlice = createSlice({
    name: "BrandState",
    initialState,
    reducers: {
        setBrandData(state, action: PayloadAction<Brand>) {
            if (action.payload.messagingConfig?.reservationCreated) {
                logger.error(
                    "Setting brand with a camel cased messaging config. This will prevent audio notifications from playing.",
                    { messagingConfig: action.payload.messagingConfig }
                );
            }
            state.brand = action.payload;
        },
        setBrandSetting(state, action: PayloadAction<BrandSettings>) {
            // bug bash clean up, I think our typing is off somewhere.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            state!.brand!.brandSettings = action.payload;
        },
        updateBrandSetting(
            state,
            action: PayloadAction<{
                definitions: BrandSettingDefinition[];
                overrides?: Record<string, string>;
            }>
        ) {
            if (!state.brand) {
                return;
            }

            const { definitions, overrides = {} } = action.payload;
            const updatedSettings = definitions.reduce((acc, setting) => {
                let value = setting.value;
                if (typeof overrides[setting.machineName] !== "undefined") {
                    value = overrides[setting.machineName];
                }

                return set(acc, setting.machineName, value);
            }, {} as BrandSettings);

            state.brand.brandSettings = updatedSettings;
        },
        clearBrand(): BrandState {
            return initialState;
        },
    },
});

export const { setBrandData, clearBrand, setBrandSetting, updateBrandSetting } = BrandStateSlice.actions;
export default BrandStateSlice.reducer;
