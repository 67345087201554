import { useCallback } from "react";

import { useSelector } from "react-redux";

import { CallParticipant } from "Pages/agentDesktop/agentInterface/CallPanel/TaskInfo/CallParticipant/CallParticipant";
import { CallPendingParticipant } from "Pages/agentDesktop/agentInterface/CallPanel/TaskInfo/PendingParticipant/PendingParticipant";
import {
    TransfersPanel,
    type TransferPanelHeaderRenderer,
} from "Pages/agentDesktop/agentInterface/CallPanel/TransfersPanel/TransfersPanel";
import { selectWorkerUri } from "Services/state/agent/AgentInformationSlice";

import { useOtherParticipants, useAgents } from "../../hooks";
import { TransfersHeader } from "./TransfersHeader";

import type { RVTask } from "@regal-voice/shared-types";

import styles from "./TransfersWithParticipants.module.scss";

export type TransfersWithParticipantsProps = {
    activeConferenceTask?: RVTask;
    onCloseRequest: () => void;
};

export function TransfersWithParticipants({
    activeConferenceTask,
    onCloseRequest,
}: TransfersWithParticipantsProps): JSX.Element {
    const workerUri = useSelector(selectWorkerUri);
    const otherParticipants = useOtherParticipants({ task: activeConferenceTask });
    const { agents } = useAgents();

    const renderPanelHeader = useCallback<TransferPanelHeaderRenderer>(
        (headerProps) => <TransfersHeader {...headerProps} />,
        []
    );

    if (!workerUri || !activeConferenceTask) {
        return <></>;
    }

    return (
        <div className={styles.container}>
            <TransfersPanel
                currentWorkerUri={workerUri}
                conferenceFriendlyId={activeConferenceTask?.attributes.conferenceFriendlyId}
                regalVoicePhone={activeConferenceTask?.attributes.regalVoicePhone}
                onCloseRequest={onCloseRequest}
                taskSid={activeConferenceTask?.taskSid}
                closeOnTransfer={false}
                asPopover
                renderHeader={renderPanelHeader}
            />
            {workerUri && otherParticipants.length > 1 && (
                <div className={styles.participants}>
                    {otherParticipants.map((participant) => {
                        if (participant.status) {
                            return (
                                <CallParticipant
                                    key={participant.callSid}
                                    participant={participant}
                                    task={activeConferenceTask}
                                    currentWorkerUri={workerUri}
                                    agents={agents}
                                />
                            );
                        }

                        return (
                            <CallPendingParticipant
                                key={participant.callSid}
                                agents={agents}
                                participant={participant}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
}
