import {
    Recording,
    RecordingMoment,
    RecordingMomentRoles,
    Tracker,
    TrackerWithAiIndicator,
} from "@regal-voice/shared-types";

export interface EnhancedRecordingMoment extends RecordingMoment {
    speakerName: string;
    role: RecordingMomentRoles;
    trackers?: Tracker[];
    trackersWithAiIndicator?: TrackerWithAiIndicator[];
}
export interface RecordingDetails extends Recording {
    moments: EnhancedRecordingMoment[];
    trackers: Tracker[];
    callSummary?: string;
}

export enum TranscriptTabs {
    CALL_DETAILS,
    SEARCH,
    SCORECARDS,
}

export type TranscriptionItemUrl = {
    params: TranscriptionItemParams;
    searchParams: URLSearchParams;
};

export type TranscriptionItemParams = {
    taskSid?: string;
    recordingSid: string;
    contactPhone?: string;
};

export const TranscriptionFilter = [
    "contactSentiment",
    "agentSentiment",
    "campaignName",
    "doesHaveTranscript",
    "handlingAgent",
    "dispositions",
    "callDuration",
    "trackers",
    "overallScore",
    "scoredByTemplate",
    "notScoredByTemplate",
    "taskType",
    "missingTrackers",
] as const;
