import { useCallback, useMemo, useState } from "react";

import { usePreferedAgentVMDropsData } from "ServerData/PrerecordedVoiceMessage/Hooks/usePrerecordedVoiceMessageData/usePrerecordedVoiceMessageData";

import { BUTTONS_MESSAGES } from "../../CallBar.consts";
import { CallBarButton } from "../CallBarButton";
import { VoicemailSettingsButton } from "./Components/VoicemailSettingsButton/VoicemailSettingsButton";

import styles from "./VoicemailDropButton.module.scss";

export interface VoicemailDropButtonProps {
    dropActive: boolean;
    campaignId?: string;
    voicemailDropDisabled: boolean;
    dropVoicemail: (id: string) => Promise<void>;
    contactPhone: string;
}

const AGENT_CALLBAR_LOCATION = "AgentCallbarQuery";

export function VoicemailDropButton({
    campaignId,
    dropVoicemail,
    voicemailDropDisabled = false,
    dropActive,
    contactPhone,
}: VoicemailDropButtonProps): JSX.Element {
    const { loading, data } = usePreferedAgentVMDropsData({
        contactPhone,
        campaignId,
        invokedLocation: AGENT_CALLBAR_LOCATION,
    });

    const [vmRecordingId, setVmRecordingId] = useState<string>("");
    const [open, setOpen] = useState(false);

    const toggleModal = useCallback(() => {
        if (!voicemailDropDisabled) {
            setOpen(!open);
        }
    }, [open, voicemailDropDisabled]);

    const handleSelectedVoicemailDrop = useCallback(async () => {
        try {
            await dropVoicemail(vmRecordingId);
            setOpen(false);
        } catch (error) {
            return Promise.reject(error);
        }
    }, [dropVoicemail, vmRecordingId]);

    // we are using a special enpoint, and [0] is always the default to use
    const handleDefaultVoicemailDrop = useCallback(async () => {
        await dropVoicemail(data?.[0]?.id);
    }, [data, dropVoicemail]);

    const VMMessages = useMemo(() => {
        return {
            ...BUTTONS_MESSAGES.voicemailDrop,
            tooltip: () => `${data?.[0]?.friendlyName}`,
        };
    }, [data]);
    return (
        <div className={styles.container}>
            <VoicemailSettingsButton
                voicemailDropDisabled={voicemailDropDisabled}
                open={open}
                campaignId={campaignId}
                onVoicemailDrop={handleSelectedVoicemailDrop}
                voiceMailOptionData={data}
                loading={loading}
                dropActive={dropActive}
                setVmRecordingId={setVmRecordingId}
                toggleModal={toggleModal}
            />
            <CallBarButton
                id="voicemailDrop"
                active={dropActive}
                action={handleDefaultVoicemailDrop}
                icon="voicemail"
                disabled={voicemailDropDisabled}
                messages={VMMessages}
            />
        </div>
    );
}
