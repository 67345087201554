import { useCallback } from "react";

import { useSelector } from "react-redux";

import { useTwilioDevice } from "Hooks/useTwilioDevice/useTwilioDevice";
import { emitMuteEvent } from "Services/ConversationsApiService";
import { selectConference } from "Services/state/conferences/Selectors";

import { logger } from "../CallBar.utils";

export type UseMuteCallResult = [isMuted: boolean, toggleMute: () => void];

export const useMuteCall = (): UseMuteCallResult => {
    const { activeCall } = useTwilioDevice();
    const { conferenceFriendlyId, deviceCallSid } = useSelector(selectConference);
    const toggleMute = useCallback(() => {
        if (!activeCall) {
            logger.error("Tried to mute call but no active call found");
            return;
        }

        const nextState = !activeCall.isMuted();
        activeCall.mute(nextState);
        if (!conferenceFriendlyId) {
            logger.error("Tried to mute call but no conference friendly id found");
            return;
        }

        emitMuteEvent({ conferenceFriendlyId, mute: nextState, callSid: deviceCallSid });
    }, [activeCall, conferenceFriendlyId, deviceCallSid]);

    const isMuted = activeCall?.isMuted() || false;
    return [isMuted, toggleMute];
};
