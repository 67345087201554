import { useMemo, useCallback } from "react";

import { Tooltip, Dropdown, Menu, type MenuItemProps } from "antd";
import { clsx } from "clsx";

import Icon, { IconSizes } from "Components/elements/Icon";
import {
    TRANSFER_TYPE,
    type TransferPanelHeaderRenderer,
} from "Pages/agentDesktop/agentInterface/CallPanel/TransfersPanel/TransfersPanel";

import callBarStyles from "../../CallBar.module.scss";

import styles from "./TransfersWithParticipants.module.scss";

const LABEL_BY_TYPE: Record<(typeof TRANSFER_TYPE)[keyof typeof TRANSFER_TYPE], string> = {
    [TRANSFER_TYPE.INTERNALLY]: "Internal",
    [TRANSFER_TYPE.EXTERNALLY]: "External",
};

const TOOLTIPS = {
    base: "Add internal or external participants (or both) to your call. When transfering a call, you will remain on the line until you choose to leave.",
    [TRANSFER_TYPE.INTERNALLY]: "Transfer to another participant in Regal",
    [TRANSFER_TYPE.EXTERNALLY]: "Transfer to a participant outside of Regal",
} as const;

type MenuClickEventHandler = NonNullable<MenuItemProps["onClick"]>;

export type TransfersHeaderProps = Parameters<TransferPanelHeaderRenderer>[0];

export function TransfersHeader({ goBack, transferType, setTransferType }: TransfersHeaderProps): JSX.Element {
    const setInternalTransfer = useCallback<MenuClickEventHandler>(
        (info) => {
            info.domEvent.stopPropagation();
            setTransferType(TRANSFER_TYPE.INTERNALLY);
        },
        [setTransferType]
    );

    const setExternalTransfer = useCallback<MenuClickEventHandler>(
        (info) => {
            info.domEvent.stopPropagation();
            setTransferType(TRANSFER_TYPE.EXTERNALLY);
        },
        [setTransferType]
    );

    const transferOverlay = useMemo(() => {
        if (!transferType) {
            return undefined;
        }

        return function renderMenu() {
            return (
                <Menu>
                    <Menu.Item
                        className={clsx({
                            [styles.selectedTransferType]: transferType == TRANSFER_TYPE.INTERNALLY,
                        })}
                        key="internal-transfer"
                        onClick={setInternalTransfer}
                    >
                        Internal
                    </Menu.Item>
                    <Menu.Item
                        className={clsx({
                            [styles.selectedTransferType]: transferType == TRANSFER_TYPE.EXTERNALLY,
                        })}
                        key="external-transfer"
                        onClick={setExternalTransfer}
                    >
                        External
                    </Menu.Item>
                </Menu>
            );
        };
    }, [transferType, setInternalTransfer, setExternalTransfer]);

    if (!transferOverlay || !transferType) {
        return (
            <div className={styles.header}>
                <span>Add Participants</span>
                <Tooltip placement="top" title={TOOLTIPS.base}>
                    <Icon className={styles.headerTooltipIcon} size={IconSizes.SMALL} icon="info-circle" />
                </Tooltip>
            </div>
        );
    }

    return (
        <div className={styles.header}>
            <button
                type="button"
                onClick={goBack}
                className={clsx(callBarStyles.textActionTitle, styles.selectTransferTypeButton)}
            >
                <Icon className={styles.headerGoBackIcon} icon="arrow-left" size={IconSizes.XSMALL} color="purple-5" />
                <span>
                    Add{" "}
                    <Dropdown placement="bottomRight" dropdownRender={transferOverlay}>
                        <span className={styles.transferType}>{LABEL_BY_TYPE[transferType]}</span>
                    </Dropdown>{" "}
                    Participant
                </span>
                <Tooltip placement="top" title={TOOLTIPS[transferType]}>
                    <Icon className={styles.headerTooltipIcon} size={IconSizes.SMALL} icon="info-circle" />
                </Tooltip>
            </button>
        </div>
    );
}
