import { useRef, RefObject, useState, LegacyRef, SetStateAction, ComponentProps, Dispatch } from "react";

import { Divider, Tooltip, FormInstance } from "antd";
import { Store } from "antd/lib/form/interface";
import { clsx } from "clsx";
import TetherComponent from "react-tether";

import PopoverTitle from "Components/elements/PopoverTitle";
import { RVButton } from "Components/elements/RVButton/RVButton";
import { RVForm } from "Components/elements/RVForm/RVForm";
import useEventListener from "Utils/useEventListener/useEventListener";

import styles from "./TaskActionModal.module.scss";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

function isAntdClearSelectParent(parent: Node | null) {
    return parent && parent.nodeName === "SPAN" && (parent as Element).ariaLabel === "close-circle";
}

/**
 * Figures out (loosely) if an element is an AntD clear icon
 */
function isAntdClearSelectElement(target: Node) {
    return (
        isAntdClearSelectParent(target.parentNode) ||
        (target.parentNode?.parentNode && isAntdClearSelectParent(target.parentNode.parentNode))
    );
}

/**
 * Checks if the given mouse event is actually within a given container component.
 * This is a workaround for elements that were clicked and are not in the dom any
 * more (like AntD's Select clear button).
 */
function isElementInsideGivenContainer(event: MouseEvent, container?: RefObject<HTMLElement>) {
    const { clientX: x, clientY: y } = event;
    const rect = container?.current?.getBoundingClientRect();
    return rect && x < rect.right && x > rect.left && y < rect.bottom && y > rect.top;
}

export function TaskActionModal({
    children,
    form,
    showWindow,
    setShowWindow,
    modalTitle,
    targetTooltipTitle,
    renderTargetIcon,
    onFinish,
    btnSize = "middle",
    asDropdown,
    asPopup,
    renderTargetClass,
    formRefs = [],
    initialValues,
}: {
    children: JSX.Element;
    form: FormInstance<any>;
    showWindow: boolean;
    setShowWindow: Dispatch<SetStateAction<boolean>>;
    modalTitle: string;
    targetTooltipTitle: string;
    renderTargetIcon: string;
    onFinish?: (formValues: any) => void;
    btnSize?: "middle" | "small";
    asDropdown?: boolean;
    asPopup?: boolean;
    renderTargetClass?: string;
    formRefs?: RefObject<HTMLElement | undefined>[];
    initialValues?: Store;
}): any {
    const containerId = "popoverContainer";
    const tracker = Math.random();
    const excludedButtonId = useRef(`${tracker}`);

    // Custom "onBlur" handling ( using onmousedown )
    const [popOverRef, setPopOverRef] = useState<RefObject<HTMLElement>>();
    function handleClick(e: MouseEvent) {
        const targetedNode = e.target || undefined;
        if (!targetedNode || !(targetedNode instanceof Node)) {
            return;
        }
        const refs = [popOverRef, ...formRefs];
        const isClearButton = isAntdClearSelectElement(targetedNode);
        // if the clicked element is not inside the popover, then we hide the window
        if (
            (isClearButton && !isElementInsideGivenContainer(e, popOverRef)) ||
            (!isClearButton && !refs.some((ref) => ref?.current?.contains(targetedNode)))
        ) {
            setShowWindow(false);
        }
    }
    useEventListener("mousedown", handleClick);
    let positionProps: ComponentProps<typeof TetherComponent>;
    let arrowPositionClass: string;
    if (asDropdown) {
        positionProps = {
            attachment: "top center",
            targetAttachment: "bottom center",
            offset: "-15px 0px",
        };
        arrowPositionClass = styles.arrowTop;
    } else if (asPopup) {
        positionProps = {
            attachment: "bottom center",
            targetAttachment: "top center",
            offset: "15x 0px",
        };
        arrowPositionClass = styles.arrowBottom;
    } else {
        positionProps = {
            attachment: "middle left",
        };
        arrowPositionClass = styles.arrowLeft;
    }

    return (
        <TetherComponent
            className={styles.tether}
            constraints={[{ to: "window", pin: true }]}
            renderTarget={(ref: React.RefObject<Element>) => (
                <div ref={ref as RefObject<HTMLDivElement>} id={`${excludedButtonId.current}`}>
                    <Tooltip title={targetTooltipTitle} placement="top">
                        <RVButton
                            id={`${excludedButtonId.current}`}
                            icon={renderTargetIcon}
                            shape="circle"
                            size={btnSize}
                            type="text"
                            className={renderTargetClass}
                            onClick={() => setShowWindow((curr) => !curr)}
                        />
                    </Tooltip>
                </div>
            )}
            renderElement={(ref: React.RefObject<Element>) => {
                setPopOverRef(ref as RefObject<HTMLElement>);
                return (
                    showWindow && (
                        <div
                            id={containerId}
                            ref={ref as LegacyRef<HTMLDivElement>}
                            className={clsx("flex-row align-center", styles.popoverContainer)}
                            data-testid="task-action-modal"
                        >
                            <div data-testid="task-action-modal-arrow" className={clsx(arrowPositionClass)}></div>
                            <div className={styles.popover}>
                                <div ref={ref as RefObject<HTMLDivElement>}>
                                    <PopoverTitle
                                        title={modalTitle}
                                        onClose={() => {
                                            setShowWindow(false);
                                        }}
                                        className={styles.title}
                                    />
                                    <Divider className={styles.divider} />
                                    <RVForm
                                        {...layout}
                                        form={form}
                                        className={clsx("sm-form-item-form", styles.form)}
                                        onFinish={onFinish}
                                        size="large"
                                        initialValues={initialValues}
                                    >
                                        <>{children}</>
                                    </RVForm>
                                </div>
                            </div>
                        </div>
                    )
                );
            }}
            {...positionProps}
        />
    );
}
