import { Route, Redirect } from "react-router-dom";

import { routes } from "App/routes";
import { AppPagePaths } from "App/routes/paths";
import { InternalAdminPages } from "Pages/admin";
import { PhoneNumbersPages } from "Pages/phone-numbers";
import { SettingsPages } from "Pages/settings";
import { TranscriptsPage } from "Pages/transcripts";

type RouteProps = React.ComponentProps<typeof Route>;
type SecureRouteComponent = React.ComponentType<Pick<RouteProps, "path" | "component" | "exact">>;

type AppRoutesProps = {
    secureRouteComponent: SecureRouteComponent;
};

const pathToKey = (path: string | string[]): string => {
    if (Array.isArray(path)) {
        return path.join("-");
    }

    return path;
};

export default function getAppRoutes({ secureRouteComponent: SecureRoute }: AppRoutesProps): JSX.Element[] {
    return [
        <SecureRoute key={AppPagePaths.admin} path={AppPagePaths.admin} component={InternalAdminPages} />,

        <SecureRoute key={AppPagePaths.settings} path={AppPagePaths.settings} component={SettingsPages} />,

        <SecureRoute key={AppPagePaths.transcripts} path={AppPagePaths.transcripts} component={TranscriptsPage} />,

        <SecureRoute key={AppPagePaths.phoneNumbers} path={AppPagePaths.phoneNumbers} component={PhoneNumbersPages} />,

        ...routes.map((route) => <SecureRoute key={pathToKey(route.path)} {...route} />),

        <Route key="fallback" exact path={AppPagePaths.fallback}>
            <Redirect to={AppPagePaths.agentDesktop} />
        </Route>,
    ];
}
