import { message } from "antd";
import { noop } from "lodash";
import { useHistory } from "react-router-dom";

export function useVisualNotification() {
    const router = useHistory();

    return {
        showVisualNotification(text: string, sid: string): void {
            // NOTE: Only applies pages that are NOT agent desktop.
            if (!/^\/agent(?!s)/.test(router.location?.pathname)) {
                message
                    .info({
                        key: `notify-${sid}`,
                        content: (
                            <span
                                onClick={() => {
                                    message.destroy(`notify-${sid}`);
                                    router.push(`/agent?sid=${sid}`);
                                }}
                            >
                                {text.replace(/\.?$/, ".")}&nbsp;&nbsp;Click to view.
                            </span>
                        ),
                        duration: 4,
                        style: { cursor: "pointer" },
                    })
                    .then(noop, noop);
                // fine to ignore the error
            }
        },
    };
}
