import { useCallback, useEffect, useMemo } from "react";

import { Form, Select, Skeleton, Typography } from "antd";
import { clsx } from "clsx";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { RVDescriptionSelectOption } from "Components/FormItems/SelectOptions/RVDescriptionSelectOption/RVDescriptionSelectOption";
import selectStyles from "Components/FormItems/SelectOptions/RVDescriptionSelectOption/RVDescriptionSelectOption.module.scss";
import { RECORDING_TYPE_OPTIONS } from "Pages/settings/Recordings/constants";

import type { PrerecordedVoiceMessage } from "Types/Recordings";

import styles from "./VoicemailListForm.module.scss";

export interface VoicemailListFormProps {
    campaignId?: string;
    loading: boolean;
    setVmRecordingId: (value: string) => void;
    isDroppingVoicemail?: boolean;
    dropVoicemail: () => Promise<void>;
    voiceMailOptionData: Array<PrerecordedVoiceMessage>;
}

const DEFAULT_DESC = "Brand Default VM";
const DEFAULT_AGENT_DESC = "Agent Default VM";
const CAMPAIGN_DESC = "Campaign VM";

export const sortVoicemails = (a: PrerecordedVoiceMessage, b: PrerecordedVoiceMessage) => {
    const leftName = a.friendlyName;
    const rightName = b.friendlyName;
    if (leftName.toLowerCase() > rightName.toLowerCase()) {
        return 1;
    }

    if (leftName.toLowerCase() < rightName.toLowerCase()) {
        return -1;
    }
    return 0;
};

export function VoicemailListForm({
    campaignId,
    voiceMailOptionData,
    setVmRecordingId,
    dropVoicemail,
    loading,
    isDroppingVoicemail,
}: VoicemailListFormProps) {
    const [form] = Form.useForm();
    const options = useMemo(() => {
        // the first item is the default to use when we don't select
        // and it is a dupe in the list, so we remove it.
        const optionData = voiceMailOptionData.slice(1);

        // sort alphabetically
        const sortedData = optionData.sort(sortVoicemails);

        return sortedData?.map((voicemail) => {
            let description = "";

            if (voicemail.isDefault) {
                if (voicemail.type === RECORDING_TYPE_OPTIONS.VOICEMAIL) {
                    description = DEFAULT_DESC;
                } else if (voicemail.type === RECORDING_TYPE_OPTIONS.AGENT_VOICEMAIL) {
                    description = DEFAULT_AGENT_DESC;
                }
            } else {
                voicemail?.phoneCallCampaigns?.forEach((pcCamp) => {
                    // there can be alot of agent vms associate with a campaign so we only want to show the brand vm for the campaign
                    if (pcCamp?.campaign?.id === campaignId && voicemail.type === RECORDING_TYPE_OPTIONS.VOICEMAIL) {
                        description = CAMPAIGN_DESC;
                    }
                });
            }
            return {
                value: voicemail.id,
                label: <RVDescriptionSelectOption name={voicemail.friendlyName} description={description} />,
            };
        });
    }, [campaignId, voiceMailOptionData]);

    useEffect(() => {
        options?.[0]?.value && setVmRecordingId(options?.[0]?.value);
    }, [options, setVmRecordingId]);

    const handleVMSelect = useCallback(
        (value: string) => {
            setVmRecordingId(value);
        },
        [setVmRecordingId]
    );

    if (loading) {
        return (
            <div data-testid="voicemail-list-loading" className={styles.container}>
                <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <Typography.Title level={5} className={styles.title}>
                Select Voicemail
            </Typography.Title>
            <Form
                form={form}
                initialValues={{
                    type: options?.[0]?.value,
                }}
            >
                <Form.Item name="type" className={styles.formItem}>
                    <Select
                        className={selectStyles.forceSelectFix}
                        popupClassName={selectStyles.forceSelectDropFix}
                        options={options}
                        onChange={handleVMSelect}
                    />
                </Form.Item>
                <Form.Item className={clsx(styles.actionButton, "flex-row justify-end")}>
                    <RVButton
                        size="middle"
                        type="primary"
                        data-testid="transfer-button"
                        onClick={dropVoicemail}
                        loading={isDroppingVoicemail}
                    >
                        Drop Voicemail
                    </RVButton>
                </Form.Item>
            </Form>
        </div>
    );
}
