import createDOMPurify from "dompurify";

/**
 * This is a standalone DOMPurify instance that should be used for sanitizing the HTML used for email content.
 * Instead of removing target attribute, it enforces target="_blank" and ref="noopener norefferer".
 * We want target="_blank"'s behavior, but there is a security concern unless we add the ref attribute as well.
 */
export const EmailDOMPurify = createDOMPurify();

EmailDOMPurify.addHook("afterSanitizeAttributes", (node) => {
    if (node.tagName === "A") {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener noreferrer");
    }
    return node;
});

export const TextDOMPurify = createDOMPurify();
TextDOMPurify.setConfig({
    ADD_ATTR: ["target"],
});
