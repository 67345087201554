export type CallBarButtonMessagesFactory = {
    tooltip: (isActive: boolean) => string;
    error?: (message: string, isActive: boolean) => string;
    success?: (isActive: boolean) => string;
    waiting?: () => string;
};

export const BUTTONS_MESSAGES = {
    recording: {
        tooltip: (isActive) => (isActive ? "Stop Recording" : "Resume Recording"),
        success: (isActive) => `Recording ${isActive ? "resumed" : "paused"}`,
        error: (error, isActive) => {
            if (error.indexOf("not found") > -1) {
                return `Unable to ${
                    isActive ? "pause" : "resume"
                } the recoding. Please try again after the call has started.`;
            }
            return `An error occured while ${isActive ? "pausing" : "resuming"} the recoding - ${error}`;
        },
    },
    hold: {
        tooltip: (isActive) => (isActive ? "Unhold" : "Hold"),
        success: (isActive) => `Call ${isActive ? "on hold" : "resumed"}`,
        error: (error, isActive) => {
            if (error.indexOf("not found") > -1) {
                return `Unable to ${
                    isActive ? "resume the call." : "put the call on hold."
                } Please try again after the call has started.`;
            }
            return `An error occured while ${isActive ? "putting the call on hold" : "resuming the call"} - ${error}`;
        },
    },
    mute: {
        tooltip: (isActive) => (isActive ? "Unmute" : "Mute"),
        success: (isActive) => `Call ${isActive ? "muted" : "unmuted"}`,
        error: (error, isActive) =>
            `An error occured while ${isActive ? "pausing" : "resuming"} the recording - ${error}`,
    },
    transfers: {
        tooltip: () => "Transfer",
    },
    hangUp: {
        tooltip: () => "Hang Up",
        error: (error) => `An error occured while wrapping the call - ${error}`,
    },
    dialpad: {
        tooltip: () => "Dialpad",
    },
    voicemailDrop: {
        tooltip: () => "Voicemail Drop",
        waiting: () => "Unable to drop voicemail. Please try again after the call has started.",
        error: (err: string) => {
            if (err.indexOf("not found") > -1) {
                return `Unable to drop voicemail.`;
            }
            return `Voicemail drop was not successful - ${err}`;
        },
        success: () => "Pre-recorded voicemail drop was successful",
    },
    accept: {
        tooltip: () => "Accept",
    },
    info: {
        tooltip: () => "Info",
    },
} satisfies Record<string, CallBarButtonMessagesFactory>;
