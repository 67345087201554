import { useEffect } from "react";

import { MessageType } from "@regal-voice/shared-types";
import { partialRight } from "lodash";

import eventEmitter from "Services/server-sent-events/EventEmitter";

/**
 * @param namespace MessageType namespace to listen to <dummy param, leveraged by TS to narrow eventName>
 * @param eventName MessageType or array of MessageTypes to listen to
 * @param callback Callback to fire on received event (event name optionally passed in as second param)
 * @param deps Dependency array
 */
export function useServerSentEvent<T extends keyof MessageType, K extends keyof MessageType[T]>(
    namespace: T,
    eventName: K | K[],
    callback: (data: MessageType[T][K], evt: K) => void,
    deps = []
): void {
    const events = Array.from([eventName as string[]]).flat();

    useEffect(() => {
        const funcs = events.map((event): [string, (...args: any) => void] => {
            const func = partialRight(callback, event);
            eventEmitter.on(event, func);
            return [event, func];
        });

        return () => {
            for (const evtFunc of funcs) {
                eventEmitter.off(...evtFunc);
            }
        };
    }, [eventName, callback, ...deps]);
}
