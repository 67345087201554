import { useCallback } from "react";

import { clsx } from "clsx";
import { useSelector } from "react-redux";

import { selectDeviceCallConnectionParams, selectConference } from "Services/state/conferences/Selectors";

import { logger } from "../CallBar.utils";
import { useNetworkQualityStatus } from "../hooks";
import { CallBarLine } from "./CallBarLine";
import { CallTaskInfo } from "./CallTaskInfo";
import { UnstableNetworkBanner } from "./UnstableNetworkBanner/UnstableNetworkBanner";

import styles from "../CallBar.module.scss";

export type CallBarLayoutProps = React.PropsWithChildren<{
    hideElementsOnSmallScreen?: boolean;
}>;

export const CallBarLayout = ({ children, hideElementsOnSmallScreen = false }: CallBarLayoutProps): JSX.Element => {
    const connectionParams = useSelector(selectDeviceCallConnectionParams);
    const { deviceCallSid } = useSelector(selectConference);
    const { showBanner } = useNetworkQualityStatus();
    const onInfoUnavailable = useCallback(() => {
        logger.error("ActivePanel: Task Info could not be rendered", {
            taskSid: connectionParams.taskSid,
            deviceCallSid,
            connectionParams,
        });
    }, [connectionParams, deviceCallSid]);
    const hideElementRules = {
        [styles.hideOnSmallScreen]: hideElementsOnSmallScreen,
    };

    return (
        <>
            {showBanner && <UnstableNetworkBanner />}
            <div className={styles.container} data-testid="callbar">
                <div className={clsx(styles.containerFragment, styles.textContent, hideElementRules)}>
                    <CallTaskInfo onInfoUnavailable={onInfoUnavailable} />
                </div>
                <div
                    className={clsx(styles.containerFragment, styles.buttonsContainer, {
                        [styles.buttonsContainerSmallScreen]: hideElementsOnSmallScreen,
                    })}
                >
                    {children}
                </div>
                <div className={clsx(styles.containerFragment, styles.textContent, hideElementRules, styles.right)}>
                    <CallBarLine />
                </div>
            </div>
        </>
    );
};
