import { useCallback, useContext } from "react";

import { useSelector } from "react-redux";

import { UserContext } from "App/contexts";
import Icon, { IconSizes } from "Components/elements/Icon";
import { useFlags } from "Services/FeatureFlagService";
import { selectActiveConferenceTask } from "Services/state/conferences/Selectors";

import { BUTTONS_MESSAGES } from "../CallBar.consts";
import {
    useMuteCall,
    useAgents,
    useHangUpCall,
    useRecordCall,
    useHoldCall,
    useBarActionsState,
    useVoicemailDrop,
} from "../hooks";
import { CallBarButton } from "./CallBarButton";
import { CallInfoPopover, type CallInfoPopoverButtonRenderer } from "./CallInfoPopover/CallInfoPopover";
import { DialpadPopover, type DialpadPopoverButtonRenderer } from "./DialpadPopover/DialpadPopover";
import { TransfersPopover, type TransfersPopoverButtonRenderer } from "./TransfersPopover";
import { VoicemailDropButton } from "./VoicemailDropButton/VoicemailDropButton";

import styles from "../CallBar.module.scss";

const IS_HANG_UP_ACTIVE = false;

export function ActiveCallBarButtons(): JSX.Element {
    const { brand } = useContext(UserContext);
    const activeConferenceTask = useSelector(selectActiveConferenceTask);
    const [isMuted, toggleMute] = useMuteCall();
    const [isRecording, toggleRecording] = useRecordCall();
    const [onHold, toggleHold] = useHoldCall();
    const { agents } = useAgents();
    const { hangUpCall, hangUpButtonMessages } = useHangUpCall({ activeConferenceTask, agents });
    const { disableActions, disableIndependentActions } = useBarActionsState();
    const { dropVoicemail, campaignId, isDroppingVoicemail, shouldRenderVoicemailDrop, voicemailDropDisabled } =
        useVoicemailDrop({
            activeConferenceTask,
            disabled: disableActions,
        });

    const { enableCallRecordingPause, hasPreRecordedVmDropsAgentView } = useFlags();

    const dialpadButtonRenderer = useCallback<DialpadPopoverButtonRenderer>(
        (open) => (
            <CallBarButton
                id="dialpad"
                active={open}
                icon="dialpad"
                messages={BUTTONS_MESSAGES.dialpad}
                disabled={disableActions}
                popover
            />
        ),
        [disableActions]
    );

    const transfersButtonRenderer = useCallback<TransfersPopoverButtonRenderer>(
        (open) => (
            <CallBarButton
                id="transfers"
                active={open}
                icon="transfer-icon"
                messages={BUTTONS_MESSAGES.transfers}
                disabled={disableActions}
                popover
            />
        ),
        [disableActions]
    );

    const callInfoButtonRenderer = useCallback<CallInfoPopoverButtonRenderer>(
        (open) => (
            <CallBarButton
                id="info"
                active={open}
                icon="info-circle"
                messages={BUTTONS_MESSAGES.info}
                disabled={disableActions}
                popover
            />
        ),
        [disableActions]
    );

    return (
        <ul className={styles.buttonsList}>
            <li key="info" className={styles.showOnSmallScreen}>
                <CallInfoPopover overlayClassName={styles.showOnSmallScreen} renderButton={callInfoButtonRenderer} />
            </li>
            <li key="mute">
                <CallBarButton
                    id="mute"
                    active={isMuted}
                    action={toggleMute}
                    icon="mute-call"
                    messages={BUTTONS_MESSAGES.mute}
                    disabled={disableActions}
                />
            </li>
            <li key="transfers">
                <TransfersPopover activeConferenceTask={activeConferenceTask} renderButton={transfersButtonRenderer} />
            </li>
            {shouldRenderVoicemailDrop && (
                <li key="voicemailDrop">
                    {hasPreRecordedVmDropsAgentView ? (
                        <VoicemailDropButton
                            dropVoicemail={dropVoicemail}
                            voicemailDropDisabled={voicemailDropDisabled}
                            dropActive={isDroppingVoicemail}
                            contactPhone={activeConferenceTask?.attributes?.contactPhone}
                            campaignId={campaignId}
                        />
                    ) : (
                        <CallBarButton
                            id="voicemailDrop"
                            active={isDroppingVoicemail}
                            // we need to get around the boolean argument
                            // original implementation doesn't take a recording id.
                            action={() => dropVoicemail()}
                            icon="voicemail"
                            disabled={voicemailDropDisabled}
                            messages={BUTTONS_MESSAGES.voicemailDrop}
                        />
                    )}
                </li>
            )}
            <li key="hold">
                <CallBarButton
                    id="hold"
                    active={onHold}
                    action={toggleHold}
                    icon="hold-call"
                    messages={BUTTONS_MESSAGES.hold}
                    disabled={disableIndependentActions || disableActions}
                />
            </li>
            {brand?.twilioConfig?.callRecordingEnabled && (
                <li key="recording">
                    <CallBarButton
                        id="recording"
                        active={isRecording}
                        action={toggleRecording}
                        icon={<Icon icon="resume-recording" size={IconSizes.XSMALL} color="red-4" />}
                        activeIcon={<Icon icon="active-recording" size={IconSizes.XSMALL} color="white" />}
                        messages={BUTTONS_MESSAGES.recording}
                        disabled={!enableCallRecordingPause || disableIndependentActions || disableActions}
                    />
                </li>
            )}
            <li key="dialpad">
                <DialpadPopover renderButton={dialpadButtonRenderer} />
            </li>
            <li key="hang-up">
                <CallBarButton
                    id="hang-up"
                    active={IS_HANG_UP_ACTIVE}
                    action={hangUpCall}
                    icon="call-hang-up-cobra"
                    messages={hangUpButtonMessages}
                    className={styles.hangUp}
                />
            </li>
        </ul>
    );
}
