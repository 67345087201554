import { useEffect, useState, memo } from "react";

import { message, Tooltip } from "antd";
import { clsx } from "clsx";

import { RVButton, clsRaisedButton } from "Components/elements/RVButton/RVButton";

export default memo(function CallActionButton({
    callBack,
    className,
    disabled,
    togglerClassName,
    icon,
    iconTogglerFn,
    messages,
    triggered = false,
}: {
    callBack: (state: boolean) => void | Promise<any>;
    className: string;
    disabled?: boolean;
    togglerClassName?: string;
    icon?: string | JSX.Element;
    iconTogglerFn?: (state: boolean) => string | JSX.Element;
    messages: {
        title: (isActive: boolean) => string;
        success?: (isActive: boolean) => string;
        error: (err: string, isActive?: boolean) => string;
    };
    triggered?: boolean;
}): JSX.Element {
    const [isTriggered, setIsTriggered] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    async function execute() {
        try {
            setLoading(true);
            await callBack(isTriggered);
            messages.success?.(isTriggered);
            setIsTriggered(!isTriggered);
        } catch (e: any) {
            message.error(messages.error(e?.message || e, isTriggered));
        } finally {
            setLoading(false);
        }
    }

    function getIcon() {
        if (iconTogglerFn) {
            return iconTogglerFn(isTriggered);
        } else {
            return icon;
        }
    }

    useEffect(() => {
        setIsTriggered(triggered);
    }, [triggered]);

    return (
        <div data-testid="call-action-button" className="flex-row justify-center">
            <Tooltip title={messages.title?.(isTriggered)} placement="top">
                <RVButton
                    icon={getIcon()}
                    shape="circle"
                    size="large"
                    onClick={execute}
                    className={clsx(clsRaisedButton, className, {
                        [togglerClassName as string]: isTriggered && togglerClassName,
                    })}
                    disabled={disabled || loading}
                    loading={loading}
                />
            </Tooltip>
        </div>
    );
});
