import { useSelector } from "react-redux";

import { useConferenceParticipants } from "Pages/agentDesktop/agentInterface/CallPanel/ActiveCall/hooks/useConferenceParticipants";
import { selectActiveConferenceTask, selectConference } from "Services/state/conferences/Selectors";
import { selectActiveAction } from "Services/state/manager-action/Selectors";
import { isPendingInboundConferenceTask } from "Services/Task.service";

import { CallBarLayout, IncomingCallBarButtons, ActiveCallBarButtons } from "./components";
import { useBarControlsVisibility, useWrapListener, useBarActiveCall, useAcceptTask } from "./hooks";

export function CallBar(): JSX.Element {
    const activeConferenceTask = useSelector(selectActiveConferenceTask);
    const {
        deviceCallSid,
        connectionParams: { Params },
    } = useSelector(selectConference);
    const callParamsHasManagerAction = Params && Params.includes("managerAction");
    const activeManagerAction = useSelector(selectActiveAction);
    const visible = useBarControlsVisibility({ activeConferenceTask, deviceCallSid });
    useWrapListener();
    useBarActiveCall({ activeConferenceTask });
    useConferenceParticipants({
        name: activeConferenceTask?.attributes?.name,
        contactPhone: activeConferenceTask?.attributes?.contactPhone,
        regalVoicePhone: activeConferenceTask?.attributes?.regalVoicePhone,
        deviceCallSid,
    });
    const { acceptActiveTask, acceptingCall } = useAcceptTask({ activeConferenceTask, deviceCallSid });
    if (!visible || activeManagerAction || callParamsHasManagerAction) {
        return <></>;
    }

    const isIncomingCall = activeConferenceTask && isPendingInboundConferenceTask(activeConferenceTask);

    return (
        <CallBarLayout hideElementsOnSmallScreen={!isIncomingCall}>
            {isIncomingCall ? (
                <IncomingCallBarButtons
                    activeTask={activeConferenceTask}
                    acceptActiveTask={acceptActiveTask}
                    acceptingCall={acceptingCall}
                />
            ) : (
                <ActiveCallBarButtons />
            )}
        </CallBarLayout>
    );
}
