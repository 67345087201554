import { Typography } from "antd";

import { Row } from "Components/shared/Flexbox";

import styles from "./RVDescriptionSelectOption.module.scss";

export const RVDescriptionSelectOption = ({ name, description }: { name: string; description?: string }) => {
    return (
        <Row className={styles.selectDesc} justifyContent="space-between">
            <Typography.Text className={styles.mainText} ellipsis={{ tooltip: name }}>
                {name}
            </Typography.Text>
            {description && <Typography.Text className={styles.descText}>{description}</Typography.Text>}
        </Row>
    );
};
