import { useQuery } from "@apollo/client";

import { AGENTS_QUERY_HARD_LIMIT, getAgentShared } from "Services/marketing-api/agents/queries";

import type { Agent } from "Types/Agent";

type AgentsResponse = {
    findAllAgents: {
        items: Agent[];
    };
};

export const useAgents = () => {
    const { data, ...rest } = useQuery<AgentsResponse>(getAgentShared, {
        notifyOnNetworkStatusChange: true, // necessary for onCompleted to run after refetch
        fetchPolicy: "no-cache",
        variables: { queryParams: { page: 0, pageSize: AGENTS_QUERY_HARD_LIMIT } },
    });

    const agents = data?.findAllAgents.items || [];

    return { agents, ...rest };
};
