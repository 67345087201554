import { Client as ConversationsClient } from "@twilio/conversations";

import { incrementUnreadSms } from "Services/state/conversations/UnreadConversationsSlice";
import { deserializeConversationMessage } from "Services/state/conversations/utils";
import { reduxStore } from "Services/state/Storage";

import { authenticateConversationsUser } from "./ConversationsApiService";
import { getLogger } from "./LoggingService";
import {
    removeConversation,
    setConversation,
    updateConversation,
    setReservationMessages,
    addConversationMessage,
} from "./state/conversations/ConversationsStateProvider";

import type { Conversation, Message } from "@twilio/conversations";

const logger = getLogger("Twilio Conversations");

// Global map that we cant put in state
export const conversations = new Map();
export let conversationsClient: ConversationsClient;

/* HELPERS */
// we need to keep this functions together
// and make sure we set the conversation first.
// this is a hack to make sure we have the conversation set in time
// since we cant set the conversation object in redux
const setConversationForBind = (conversation: Conversation) => {
    if (!!conversation) {
        conversations.set(conversation?.sid, conversation);
        reduxStore.dispatch(setConversation({ sid: conversation?.sid }));
    }
};
const removeConversationForLeave = (conversation: Conversation) => {
    logger.log("Left conversation", { conversationSid: conversation?.sid });
    conversations.delete(conversation?.sid);
    reduxStore.dispatch(removeConversation({ sid: conversation?.sid }));
};

// If an customer initiated a conversation before the agent joined
// we need to retrieve the messages from the reservation
// and add them to the conversation so the agent has all the context
// once the agent joins the conversation the on messageAdded event will fire
// for all new messages
const handleReservationMessages = async (conversation: Conversation) => {
    try {
        /**
         * @remarks : the getMessages method does not seem to be documented properly
         *  This is how we get the the 5 most recent messages from the reservation
         */
        // get up to 5 messages from the reservation
        const message = await conversation.getMessages(30, 5, "backwards");
        if (message.items.length > 0) {
            // we need to deserialize the message before we can add it to the state
            const cleanItems = message.items.map((m: any) => {
                return deserializeConversationMessage(m);
            });
            reduxStore.dispatch(setReservationMessages({ sid: conversation.sid, messages: cleanItems }));
        }
    } catch (error) {
        logger.error("TwilioConversationsService error getting reservation messages", {
            conversation: conversation.sid,
            error,
        });
    }
};

const handleMessagesAdded = (m: Message) => {
    // we need to deserialize the message before we can add it to the state
    const message = deserializeConversationMessage(m);
    reduxStore.dispatch(addConversationMessage({ sid: m.conversation.sid, message }));
    // currently we only get attributes on outbound messages
    // so this is how we need to do our inbound check
    // @ts-expect-error direction is on attributes
    const direction = message.attributes?.direction ?? "inbound";
    if (direction.toLowerCase() === "inbound") {
        reduxStore.dispatch(incrementUnreadSms({ conversationSid: m.conversation.sid, smsMessageSid: m.sid }));
    }
};

async function getConversationToken(): Promise<string> {
    const { token } = await authenticateConversationsUser();
    return token;
}

/* INITIALIZATION FLOW */
// function useConversationsListeners() {
const initializeConversationsListeners = () => {
    conversationsClient.on("conversationJoined", (conversation: Conversation) => {
        logger.log("Joined conversation", { conversationSid: conversation?.sid });
        conversation?.on("updated", (updateResult: any) => {
            logger.log("Conversation was updated", {
                conversationSid: updateResult.conversation.sid,
                attributes: updateResult.conversation.attributes,
            });

            const { conversation } = updateResult;

            reduxStore.dispatch(updateConversation({ sid: conversation.sid }));
        });

        // all new messages will be added to the conversation from here
        conversation?.on("messageAdded", handleMessagesAdded);
        setConversationForBind(conversation);
        handleReservationMessages(conversation);
    });

    conversationsClient.on("conversationLeft", removeConversationForLeave);
};

const initializeStateListeners = () => {
    conversationsClient.on("connectionStateChanged", async (state) => {
        logger.log(`Connection state changed: ${state}`);
        if (["connected"].includes(state)) {
            logger.log("Reconnected to ConversationClient and Resyncing tasks");
            const fetchedConversations = await conversationsClient.getSubscribedConversations();
            fetchedConversations.items.forEach((conversation: Conversation) => {
                if (conversation.status === "joined") {
                    logger.log("Found a previously joined conversation", { conversationSid: conversation?.sid });
                    setConversationForBind(conversation);
                }
            });
        }
    });
};

const listenToTokenExpiration = () => {
    conversationsClient.on("tokenAboutToExpire", async () => {
        logger.log("Conversations token about to expire");
        const token = await getConversationToken();
        await conversationsClient.updateToken(token);
        logger.log("token renewed");
    });

    conversationsClient.on("tokenExpired", async () => {
        logger.log("Conversations token expired.");
        await initializeConversations();
        logger.log("Conversations library reinitialized!");
    });
};

const listenToErrors = () => {
    conversationsClient.on("initFailed", ({ error }) => {
        logger.error("ConversationsClient initialization failed", { error });
    });
};

export const initializeConversations = async (): Promise<ConversationsClient | undefined> => {
    logger.log("initializeConversations twilio conversations", {
        convo: Boolean(conversationsClient),
    });
    if (["connecting", "connected"].includes(conversationsClient?.connectionState)) {
        logger.log("ConversationsClient skip initialization", {
            connectionState: conversationsClient?.connectionState,
        });
        return;
    }
    try {
        const token = await getConversationToken();
        if (!token) {
            logger.error("Token could not be obtained. Check configuration");
            return;
        }
        conversationsClient?.shutdown();
        conversationsClient = new ConversationsClient(token);
        listenToErrors();
        initializeStateListeners();
        initializeConversationsListeners();
        listenToTokenExpiration();
        return conversationsClient; // we just return to make testing easier
    } catch (error) {
        logger.error("TwilioConversations -> initializeConversations", { error });
    }
};
