import { useCallback } from "react";

import { useSelector } from "react-redux";

import { useServerSentEvent } from "Hooks/useServerSentEvent";
import { useWrapTask } from "Hooks/useWrapTask/useWrapTask";
import { selectTasks } from "Services/state/tasks/Selectors";

import { logger } from "../CallBar.utils";

import type { MessageType } from "@regal-voice/shared-types";

type ListenerFn = (event?: MessageType["twilio"]["call.ended"]) => void;

export const useWrapListener = () => {
    const tasks = useSelector(selectTasks);
    const wrapTask = useWrapTask();
    const wrapTaskListener = useCallback<ListenerFn>(
        (event) => {
            const taskSid = event?.callInfo?.taskSid;

            logger.log("Received call.ended event", { taskSid });
            if (!taskSid) {
                return;
            }

            const [previousCallTask] = Object.values(tasks).filter((task) => task.taskSid == taskSid);

            if (previousCallTask && previousCallTask?.status !== "wrapping") {
                // same...
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                wrapTask(event!.callInfo!.taskSid);
            }
        },
        [tasks, wrapTask]
    );
    useServerSentEvent("twilio", "call.ended", wrapTaskListener);
};
