import { useState, useCallback } from "react";

import {
    Dialpad,
    type DialpadProps,
    type DialpadOnDigitPress,
} from "Pages/agentDesktop/agentInterface/CallPanel/ActiveCall/CallControlsPanel/Dialpad/Dialpad";

import styles from "./DialpadWithPreview.module.scss";

export function DialpadWithPreview({ onDigitPress }: DialpadProps): JSX.Element {
    const [preview, setPreview] = useState("");
    const handleDigitPress = useCallback<DialpadOnDigitPress>(
        (digit) => {
            setPreview((prev) => prev + digit);
            onDigitPress(digit);
        },
        [onDigitPress]
    );

    return (
        <div className={styles.container}>
            <div className={styles.preview}>{preview}</div>
            <Dialpad onDigitPress={handleDigitPress} />
        </div>
    );
}

export type { DialpadOnDigitPress };
