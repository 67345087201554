import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

import { ConnectionStats, EphemeralConnectionStats, TotalConnectionStats } from "Types/ConnectionStats";

import { RootReducerShape } from "../Storage";
import { recomputeConnectionQualityState } from "./utils";

export type AggregateConnectionQualityStatistics = {
    [Key in keyof EphemeralConnectionStats]: {
        min: number;
        max: number;
        average: number;
        sampleCount: number;
    };
};

export type ConnectionQualityStateEntry = TotalConnectionStats &
    AggregateConnectionQualityStatistics & { codecName: string };

export type ConnectionQualityState = {
    [taskSid: string]: ConnectionQualityStateEntry;
};

const initialState: ConnectionQualityState = {};

const ConnectionQualityStateSlice = createSlice({
    name: "ConnectionQualityState",
    initialState,
    reducers: {
        setConnectionQualityStatistics(
            state: ConnectionQualityState,
            action: PayloadAction<{ taskSid: string; statisticsAndSampleCount: ConnectionQualityStateEntry }>
        ) {
            state[action.payload.taskSid] = action.payload.statisticsAndSampleCount;
        },
        removeConnectionQualityStatistics(state: ConnectionQualityState, action: PayloadAction<string>) {
            delete state[action.payload];
        },
    },
});

export const { setConnectionQualityStatistics, removeConnectionQualityStatistics } =
    ConnectionQualityStateSlice.actions;
export default ConnectionQualityStateSlice.reducer;

// SELECTORS
export const selectConnectionQualityStatistics = (state: RootReducerShape) => state.conference.connectionQuality;

// THUNKS
export function updateConnectionQualityStatistics(taskSid: string, newRawStatistics: ConnectionStats) {
    return function thunk(dispatch: Dispatch, getState: () => RootReducerShape): void {
        const currentConnectionQualityData = getState().conference.connectionQuality[taskSid] as
            | ConnectionQualityStateEntry
            | undefined;
        const newConnectionQualityData = recomputeConnectionQualityState(
            newRawStatistics,
            currentConnectionQualityData
        );

        dispatch(
            setConnectionQualityStatistics({
                taskSid,
                statisticsAndSampleCount: newConnectionQualityData,
            })
        );
    };
}
