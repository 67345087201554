import { gql } from "@apollo/client";

export const getRecentTasks = gql`
    query ($queryParams: QueryParams!) {
        recentTasks(queryParams: $queryParams) {
            hasMore
            items {
                sid
                originalTaskSid
                type
                contactPhone
                contactName
                contactExternalId
                details
                createdAt
                updatedAt
                completedAt
                canceledAt
                deletedAt
                profileId
                recordingUrl
                recordingSid
                transcriptionStatus
                attributes
                priority
                campaign {
                    id
                    friendlyId
                    name
                }
                cancelationInfo
                originalTaskQueueName
            }
        }
    }
`;

export const getRecentTasksCampaigns = gql`
    query ($queryParams: QueryParams!) {
        recentTasks(queryParams: $queryParams) {
            items {
                campaign {
                    id
                    friendlyId
                    name
                }
            }
        }
    }
`;

export const getRecentTaskFilters = gql`
    query {
        recentTaskFilters {
            targetAgent
            campaignId
            taskType
            dispositions
        }
        queues {
            sid
            value: friendlyName
            text: friendlyName
        }
    }
`;

export const getQueues = gql`
    query {
        queues {
            sid
            friendlyName
            transferEligible
        }
    }
`;

export const getAgents = gql`
    query {
        agents {
            attributes {
                email
                fullName
            }
        }
    }
`;

export const getReservations = gql`
    query ($workerSid: String!) {
        reservations(workerSid: $workerSid) {
            sid
            reservationStatus
            task {
                sid
                age
                attributes {
                    title
                    taskType
                }
            }
        }
    }
`;

export const getReservationsByEmail = gql`
    query {
        reservationsByEmail {
            reservationSid
            createdAt
            updatedAt
            userSid
            task {
                sid
                attributes
                acceptedAt
                createdAt
                updatedAt
                priority
                taskQueueName
                assignmentStatus
                channel
            }
        }
    }
`;

export const getPendingReservationsByEmail = gql`
    query getPendingReservationsByEmail {
        getPendingReservationsByEmail {
            reservationSid
            task {
                sid
                attributes
            }
        }
    }
`;
