import { useRef, useCallback, useEffect } from "react";

import { useSelector } from "react-redux";

import { selectShowPanel } from "Services/state/conferences/Selectors";

import { logger } from "../CallBar.utils";

import type { RVTask } from "@regal-voice/shared-types";

export type BarControlsVisibilityState = "unset" | "visible" | "hidden";

export type UseBarControlsVisibilityOptions = {
    activeConferenceTask?: RVTask;
    deviceCallSid?: string;
};

export const useBarControlsVisibility = ({
    activeConferenceTask,
    deviceCallSid,
}: UseBarControlsVisibilityOptions = {}): boolean => {
    const visible = useSelector(selectShowPanel);
    const lastVisibility = useRef<BarControlsVisibilityState>("unset");
    const updateVisibility = useCallback(
        (visibility: BarControlsVisibilityState) => {
            if (lastVisibility.current === visibility) {
                return;
            }

            lastVisibility.current = visibility;
            if (visibility === "visible") {
                logger.log("Call controls visible", {
                    taskSid: activeConferenceTask?.taskSid,
                    status: activeConferenceTask?.status,
                    connectionCallSid: deviceCallSid,
                });

                return;
            }

            logger.log("Call controls hidden");
        },
        [activeConferenceTask, deviceCallSid]
    );

    useEffect(() => {
        updateVisibility(visible ? "visible" : "hidden");
        // eslint-disable-next-line react-hooks/exhaustive-deps -- We only care about the `visible` changing.
    }, [visible]);

    return visible;
};
