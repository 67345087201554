import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "Services/state/Storage";

import { setAllContactAttributes } from "./ContactAttributesSlice";

import type { ContactAttribute } from "Types/ContactAttribute";

export const setContactAttributes = createAsyncThunk<void, Array<ContactAttribute>, { state: RootState }>(
    "contactAttributes/setContactAttributes",
    (contactAttributes, { dispatch }) => {
        dispatch(
            setAllContactAttributes(
                contactAttributes.reduce((acc, contactAttribute) => {
                    acc[contactAttribute.id] = contactAttribute;
                    return acc;
                }, {} as Record<string, ContactAttribute>)
            )
        );
    }
);
