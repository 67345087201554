export const KNOWN_PANELS = {
    adSubNav: {
        name: "agent-desktop-sub-nav",
        width: 200,
    },
    adCampaignInfo: {
        name: "agent-desktop-campaign-info",
        width: 368,
    },
} as const;
