import { RecordingType } from "@regal-voice/shared-types";

export const RECORDING_TYPE_OPTIONS: Record<string, RecordingType> = {
    VOICEMAIL: "voicemail_drop",
    IVR: "ivr",
    AGENT_VOICEMAIL: "agent_voicemail",
};

export const RECORDING_TYPE_LABELS = {
    [RECORDING_TYPE_OPTIONS.VOICEMAIL]: "Voicemail",
    [RECORDING_TYPE_OPTIONS.IVR]: "IVR",
    [RECORDING_TYPE_OPTIONS.AGENT_VOICEMAIL]: "Agent Voicemail",
};

export const RECORDING_FORM_KEYS = {
    friendlyName: "friendlyName",
    type: "type",
    file: "file",
    isDefault: "isDefault",
    agent: "agent",
    phoneCallCampaignIds: "phoneCallCampaignIds",
    subBrands: "subBrands",
} as const;

export const DEFAULT_WARNING_MESSAGE =
    "has already been selected as the default voicemail. Selecting this voicemail as default will override your current selection.";

export const RECORDING_LEARN_MORE_LINK =
    "https://support.regal.io/hc/en-us/articles/28750346534811--Draft-Agent-Specific-Voicemail-Drop";

export const RECORDING_COLUMN_KEYS = {
    friendlyName: "friendlyName",
    type: "type",
    listen: "url",
    agent: "agent",
    phoneCallCampaigns: "phoneCallCampaigns",
    subBrands: "subBrands",
    actions: "actions",
    createdInfo: "createdInfo",
    updatedInfo: "updatedInfo",
    isDefault: "isDefault",
} as const;

export const RECORDING_COLUMN_TITLES = {
    [RECORDING_COLUMN_KEYS.friendlyName]: "Recording Name",
    [RECORDING_COLUMN_KEYS.type]: "Type",
    [RECORDING_COLUMN_KEYS.listen]: "Listen",
    [RECORDING_COLUMN_KEYS.agent]: "Agent",
    [RECORDING_COLUMN_KEYS.phoneCallCampaigns]: "Campaign",
    [RECORDING_COLUMN_KEYS.subBrands]: "Sub-Brand",
    [RECORDING_COLUMN_KEYS.actions]: "Actions",
    [RECORDING_COLUMN_KEYS.createdInfo]: "Created",
    [RECORDING_COLUMN_KEYS.updatedInfo]: "Updated",
    [RECORDING_COLUMN_KEYS.isDefault]: "Set Default",
} as const;
